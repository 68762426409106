import { isUserVariable } from "../model/userVariable";
import { isString } from "../util/types";

export function encodeDatasetId(dataset){
  return encodeURIComponent(isString(dataset) ? dataset : dataset.id);
}

export function encodeVariableName(variable) {
  return encodeURIComponent(
    isString(variable)
      ? variable
      : isUserVariable(variable)
        ? `${variable.name}=${variable.expression}`
        : variable.name,
  );
}
