import { createSelector } from "reselect";
import {
  placeGroupToTimeSeries,
} from "../model/timeSeries";

export const datasetsSelector = (state) =>
  state.dataState.datasets || [];
export const predefinedColorBarsSelector = (state) =>
  state.dataState.colorBars;
export const timeSeriesGroupsSelector = (state) =>
  state.dataState.timeSeriesGroups;
export const userPlaceGroupsSelector = (state) =>
  state.dataState.userPlaceGroups;
export const userServersSelector = (state) =>
  state.dataState.userServers || [];
export const expressionCapabilitiesSelector = (state) =>
  state.dataState.expressionCapabilities;
export const statisticsLoadingSelector = (state) =>
  state.dataState.statistics.loading;
export const statisticsRecordsSelector = (state) =>
  state.dataState.statistics.records;

export const placeGroupsSelector = createSelector(
  datasetsSelector,
  userPlaceGroupsSelector,
  (datasets, userPlaceGroups) => {
    const placeGroups= {};
    const datasetPlaceGroups = [];
    datasets.forEach((dataset) => {
      if (dataset.placeGroups) {
        dataset.placeGroups.forEach((placeGroup) => {
          if (!placeGroups[placeGroup.id]) {
            placeGroups[placeGroup.id] = placeGroup;
            datasetPlaceGroups.push(placeGroup);
          }
        });
      }
    });
    return [...datasetPlaceGroups, ...userPlaceGroups];
  },
);

export const placeGroupTimeSeriesSelector = createSelector(
  placeGroupsSelector,
  (placeGroups) => {
    const placeGroupTimeSeries= [];
    placeGroups.forEach((placeGroup) => {
      const timeSeries = placeGroupToTimeSeries(placeGroup);
      if (timeSeries !== null) {
        placeGroupTimeSeries.push(timeSeries);
      }
    });
    return placeGroupTimeSeries;
  },
);
