
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

import { COLOR_BAR_ITEM_GAP } from "./constants";
import { makeStyles } from "../../util/styles";

const styles = makeStyles({
  colorBarGroupTitle: (theme) => ({
    marginTop: theme.spacing(2 * COLOR_BAR_ITEM_GAP),
    fontSize: "small",
    color: theme.palette.text.secondary,
  }),
});



export default function ColorBarGroupHeader({
  title,
  description,
}) {
  return (
    <Tooltip arrow title={description} placement="left">
      <Box sx={styles.colorBarGroupTitle}>{title}</Box>
    </Tooltip>
  );
}
