
import JSZip from "jszip";
import { saveAs } from "file-saver";

import * as api from "../api";
import i18n from "../i18n";
import { getDatasetUserVariables } from "../model/dataset";
import {
  findPlaceInPlaceGroups,
} from "../model/place";
import { getUserPlacesFromCsv } from "../model/user-place/csv";
import { getUserPlacesFromGeoJson } from "../model/user-place/geojson";
import { getUserPlacesFromWkt } from "../model/user-place/wkt";
import {
  timeSeriesGroupsToTable,
} from "../model/timeSeries";
import {
  mapProjectionSelector,
  selectedDatasetSelector,
  selectedDatasetTimeDimensionSelector,
  selectedVariableSelector,
  selectedPlaceGroupPlacesSelector,
  selectedPlaceGroupsSelector,
  selectedPlaceIdSelector,
  selectedPlaceSelector,
  selectedServerSelector,
  selectedTimeChunkSizeSelector,
  userPlacesFormatNameSelector,
  userPlacesFormatOptionsCsvSelector,
  userPlacesFormatOptionsGeoJsonSelector,
  userPlacesFormatOptionsWktSelector,
  selectedDatasetTimeLabelSelector,
  selectedPlaceInfoSelector,
} from "../selectors/controlSelectors";
import {
  datasetsSelector,
  placeGroupsSelector,
  userPlaceGroupsSelector,
} from "../selectors/dataSelectors";
import { loadUserVariables, storeUserVariables } from "../states/userSettings";
import { postMessage } from "./messageLogActions";
import { renameUserPlaceInLayer, restyleUserPlaceInLayer } from "./mapActions";
import {
  addActivity,
  openDialog,
  removeActivity,
  selectDataset,
  selectPlace,
  setSidebarOpen,
  setSidebarPanelId,
} from "./controlActions";

////////////////////////////////////////////////////////////////////////////////

export const UPDATE_SERVER_INFO = "UPDATE_SERVER_INFO";

export function updateServerInfo() {
  return (
    dispatch,
    getState,
  ) => {
    return;
    const apiServer = selectedServerSelector(getState());

    dispatch(addActivity(UPDATE_SERVER_INFO, i18n.get("Connecting to server")));

    api
      .getServerInfo(apiServer.url)
      .then((serverInfo) => {
        dispatch(_updateServerInfo(serverInfo));
      })
      .catch((error) => {
        dispatch(postMessage("error", error));
      })
      // 'then' because Microsoft Edge does not understand method finally
      .then(() => {
        dispatch(removeActivity(UPDATE_SERVER_INFO));
      });
  };
}

export function _updateServerInfo(serverInfo){
  return { type: UPDATE_SERVER_INFO, serverInfo };
}

////////////////////////////////////////////////////////////////////////////////

export const UPDATE_RESOURCES = "UPDATE_RESOURCES";

export function updateResources() {
  return (
    dispatch,
    getState,
  ) => {
    const apiServer = selectedServerSelector(getState());
    dispatch(addActivity(UPDATE_RESOURCES, i18n.get("Updating resources")));
    api
      .updateResources(apiServer.url, getState().userAuthState.accessToken)
      .then((ok) => {
        if (ok) {
          window.location.reload();
        }
      })
      .finally(() => dispatch(removeActivity(UPDATE_RESOURCES)));
  };
}

////////////////////////////////////////////////////////////////////////////////

export const UPDATE_DATASETS = "UPDATE_DATASETS";

export function updateDatasets() {
  return (dispatch, getState) => {
    const apiServer = selectedServerSelector(getState());

    dispatch(addActivity(UPDATE_DATASETS, i18n.get("Loading data")));

    api
      .getDatasets(apiServer.url, getState().userAuthState.accessToken)
      .then((datasets) => {
        // Add user variables from local storage
        const userVariables = loadUserVariables();
        datasets = datasets.map((ds) => ({
          ...ds,
          variables: [...ds.variables, ...(userVariables[ds.id] || [])],
        }));
        // Dispatch updated dataset
        dispatch(_updateDatasets(datasets));
        // Adjust selection state
        if (datasets.length > 0) {
          const selectedDatasetId =
            getState().controlState.selectedDatasetId || datasets[0].id;
          dispatch(
            selectDataset(
              selectedDatasetId,
              datasets,
              true,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch(postMessage("error", error));
        dispatch(_updateDatasets([]));
      })
      // 'then' because Microsoft Edge does not understand method finally
      .then(() => {
        dispatch(removeActivity(UPDATE_DATASETS));
      });
  };
}

export function _updateDatasets(datasets){
  return { type: UPDATE_DATASETS, datasets };
}

////////////////////////////////////////////////////////////////////////////////

export function updateDatasetUserVariables(
  datasetId,
  variables,
) {
  return (dispatch, getState) => {
    dispatch(_updateDatasetUserVariables(datasetId, variables));
    const userVariables = {};
    getState().dataState.datasets.forEach((dataset) => {
      const [_, variables] = getDatasetUserVariables(dataset);
      if (variables.length >= 0) {
        userVariables[dataset.id] = variables;
      }
    });
    storeUserVariables(userVariables);
  };
}

export const UPDATE_DATASET_USER_VARIABLES = "UPDATE_DATASET_USER_VARIABLES";

export function _updateDatasetUserVariables(
  datasetId,
  userVariables,
){
  return { type: UPDATE_DATASET_USER_VARIABLES, datasetId, userVariables };
}

////////////////////////////////////////////////////////////////////////////////

export const UPDATE_DATASET_PLACE_GROUP = "UPDATE_DATASET_PLACE_GROUP";

export function updateDatasetPlaceGroup(
  datasetId,
  placeGroup,
){
  return { type: UPDATE_DATASET_PLACE_GROUP, datasetId, placeGroup };
}

////////////////////////////////////////////////////////////////////////////////

export const ADD_DRAWN_USER_PLACE = "ADD_DRAWN_USER_PLACE";

export function addDrawnUserPlace(
  placeGroupTitle,
  id,
  properties,
  geometry,
  selected,
) {
  return (dispatch, getState) => {
    dispatch(
      _addDrawnUserPlace(placeGroupTitle, id, properties, geometry, selected),
    );
    if (
      getState().controlState.autoShowTimeSeries &&
      getState().controlState.selectedPlaceId === id
    ) {
      dispatch(addTimeSeries());
    }
  };
}

export function _addDrawnUserPlace(
  placeGroupTitle,
  id,
  properties,
  geometry,
  selected,
){
  return {
    type: ADD_DRAWN_USER_PLACE,
    placeGroupTitle,
    id,
    properties,
    geometry,
    selected,
  };
}

////////////////////////////////////////////////////////////////////////////////

export const ADD_IMPORTED_USER_PLACE_GROUPS = "ADD_IMPORTED_USER_PLACES"

export function addImportedUserPlaces(
  placeGroups,
  mapProjection,
  selected,
){
  return {
    type: ADD_IMPORTED_USER_PLACE_GROUPS,
    placeGroups,
    mapProjection,
    selected,
  };
}

////////////////////////////////////////////////////////////////////////////////

export function importUserPlacesFromText(text) {
  return (dispatch, getState) => {
    const formatName = userPlacesFormatNameSelector(getState());
    let placeGroups;
    try {
      if (formatName === "csv") {
        const options = userPlacesFormatOptionsCsvSelector(getState());
        placeGroups = getUserPlacesFromCsv(text, options);
      } else if (formatName === "geojson") {
        const options = userPlacesFormatOptionsGeoJsonSelector(getState());
        placeGroups = getUserPlacesFromGeoJson(text, options);
      } else if (formatName === "wkt") {
        const options = userPlacesFormatOptionsWktSelector(getState());
        placeGroups = getUserPlacesFromWkt(text, options);
      } else {
        placeGroups = [];
      }
    } catch (error) {
      dispatch(postMessage("error", error));
      dispatch(openDialog("addUserPlacesFromText"));
      placeGroups = [];
    }
    if (placeGroups.length > 0) {
      dispatch(
        addImportedUserPlaces(
          placeGroups,
          mapProjectionSelector(getState()),
          true,
        ),
      );
      // dispatch(selectPlaceGroups(placeGroups.map(pg => pg.id)) as any);
      if (placeGroups.length === 1 && placeGroups[0].features.length === 1) {
        const place = placeGroups[0].features[0];
        dispatch(
          selectPlace(
            place.id,
            selectedPlaceGroupPlacesSelector(getState()),
            true,
          ),
        );
        if (getState().controlState.autoShowTimeSeries) {
          dispatch(addTimeSeries());
        }
      }
      let numPlaces = 0;
      placeGroups.forEach((placeGroup) => {
        numPlaces += placeGroup.features ? placeGroup.features.length : 0;
      });
      dispatch(
        postMessage(
          "info",
          i18n.get(
            `Imported ${numPlaces} place(s) in ${placeGroups.length} groups(s), 1 selected`,
          ),
        ),
      );
    } else {
      dispatch(postMessage("warning", i18n.get("No places imported")));
    }
  };
}

////////////////////////////////////////////////////////////////////////////////

export const RENAME_USER_PLACE_GROUP = "RENAME_USER_PLACE_GROUP";

export function renameUserPlaceGroup(
  placeGroupId,
  newName,
){
  return { type: RENAME_USER_PLACE_GROUP, placeGroupId, newName };
}

////////////////////////////////////////////////////////////////////////////////

export const RENAME_USER_PLACE = "RENAME_USER_PLACE";

export function renameUserPlace(
  placeGroupId,
  placeId,
  newName,
) {
  return (dispatch) => {
    dispatch(_renameUserPlace(placeGroupId, placeId, newName));
    renameUserPlaceInLayer(placeGroupId, placeId, newName);
  };
}

export function _renameUserPlace(
  placeGroupId,
  placeId,
  newName,
){
  return { type: RENAME_USER_PLACE, placeGroupId, placeId, newName };
}

////////////////////////////////////////////////////////////////////////////////

export const RESTYLE_USER_PLACE = "RESTYLE_USER_PLACE";


export function restyleUserPlace(
  placeGroupId,
  placeId,
  placeStyle,
) {
  return (dispatch) => {
    dispatch(_restyleUserPlace(placeGroupId, placeId, placeStyle));
    restyleUserPlaceInLayer(placeGroupId, placeId, placeStyle);
  };
}

export function _restyleUserPlace(
  placeGroupId,
  placeId,
  placeStyle,
){
  return { type: RESTYLE_USER_PLACE, placeGroupId, placeId, placeStyle };
}

////////////////////////////////////////////////////////////////////////////////

export const REMOVE_USER_PLACE = "REMOVE_USER_PLACE";

export function removeUserPlace(
  placeGroupId,
  placeId,
  places,
){
  return { type: REMOVE_USER_PLACE, placeGroupId, placeId, places };
}

////////////////////////////////////////////////////////////////////////////////

export const REMOVE_USER_PLACE_GROUP = "REMOVE_USER_PLACE_GROUP";

export function removeUserPlaceGroup(
  placeGroupId,
){
  return { type: REMOVE_USER_PLACE_GROUP, placeGroupId };
}

////////////////////////////////////////////////////////////////////////////////

export function addStatistics() {
  return (
    dispatch,
    getState,
  ) => {
    const apiServer = selectedServerSelector(getState());

    const selectedDataset = selectedDatasetSelector(getState());
    const selectedVariable = selectedVariableSelector(getState());
    const selectedPlaceInfo = selectedPlaceInfoSelector(getState());
    const selectedTimeLabel = selectedDatasetTimeLabelSelector(getState());
    const sidebarOpen = getState().controlState.sidebarOpen;
    const sidebarPanelId = getState().controlState.sidebarPanelId;

    if (
      !(
        selectedDataset &&
        selectedVariable &&
        selectedPlaceInfo &&
        selectedTimeLabel
      )
    ) {
      return;
    }

    if (sidebarPanelId !== "stats") {
      dispatch(setSidebarPanelId("stats"));
    }
    if (!sidebarOpen) {
      dispatch(setSidebarOpen(true));
    }
    dispatch(_addStatistics(null));
    api
      .getStatistics(
        apiServer.url,
        selectedDataset,
        selectedVariable,
        selectedPlaceInfo,
        selectedTimeLabel,
        getState().userAuthState.accessToken,
      )
      .then((stats) => dispatch(_addStatistics(stats)))
      .catch((error) => {
        dispatch(postMessage("error", error));
      });
  };
}

export const ADD_STATISTICS = "ADD_STATISTICS";

export function _addStatistics(
  statistics,
){
  return { type: ADD_STATISTICS, statistics };
}

////////////////////////////////////////////////////////////////////////////////

export const REMOVE_STATISTICS = "REMOVE_STATISTICS";


export function removeStatistics(index){
  return { type: REMOVE_STATISTICS, index };
}

////////////////////////////////////////////////////////////////////////////////

export function addTimeSeries() {
  return (
    dispatch,
    getState,
  ) => {
    const apiServer = selectedServerSelector(getState());

    const selectedDataset = selectedDatasetSelector(getState());
    const selectedDatasetTimeDim =
      selectedDatasetTimeDimensionSelector(getState());
    const selectedVariable = selectedVariableSelector(getState());
    const selectedPlaceId = selectedPlaceIdSelector(getState());
    const selectedPlace = selectedPlaceSelector(getState());
    const timeSeriesUpdateMode = getState().controlState.timeSeriesUpdateMode;
    const useMedian = getState().controlState.timeSeriesUseMedian;
    const includeStdev = getState().controlState.timeSeriesIncludeStdev;
    let timeChunkSize = selectedTimeChunkSizeSelector(getState());
    const sidebarOpen = getState().controlState.sidebarOpen;
    const sidebarPanelId = getState().controlState.sidebarPanelId;

    const placeGroups = placeGroupsSelector(getState());

    if (
      selectedDataset &&
      selectedVariable &&
      selectedPlaceId &&
      selectedDatasetTimeDim
    ) {
      if (sidebarPanelId !== "timeSeries") {
        dispatch(setSidebarPanelId("timeSeries"));
      }
      if (!sidebarOpen) {
        dispatch(setSidebarOpen(true));
      }

      const timeLabels = selectedDatasetTimeDim.labels;
      const numTimeLabels = timeLabels.length;

      timeChunkSize = timeChunkSize > 0 ? timeChunkSize : numTimeLabels;

      let endTimeIndex = numTimeLabels - 1;
      let startTimeIndex = endTimeIndex - timeChunkSize + 1;

      const getTimeSeriesChunk = () => {
        const startDateLabel =
          startTimeIndex >= 0 ? timeLabels[startTimeIndex] : null;
        const endDateLabel = timeLabels[endTimeIndex];
        return api.getTimeSeriesForGeometry(
          apiServer.url,
          selectedDataset,
          selectedVariable,
          selectedPlace.id,
          selectedPlace.geometry,
          startDateLabel,
          endDateLabel,
          useMedian,
          includeStdev,
          getState().userAuthState.accessToken,
        );
      };

      const successAction = (timeSeries) => {
        if (
          timeSeries !== null &&
          isValidPlace(placeGroups, selectedPlace.id)
        ) {
          const hasMore = startTimeIndex > 0;
          const dataProgress = hasMore
            ? (numTimeLabels - startTimeIndex) / numTimeLabels
            : 1.0;
          dispatch(
            updateTimeSeries(
              { ...timeSeries, dataProgress },
              timeSeriesUpdateMode,
              endTimeIndex === numTimeLabels - 1 ? "new" : "append",
            ),
          );
          if (hasMore && isValidPlace(placeGroups, selectedPlace.id)) {
            // TODO (forman): Exit loop if current time-series is no longer alive.
            //      We currently keep this loop busy although this time-series
            //      may have been removed already!
            //      For this, introduce time-series ID.
            startTimeIndex -= timeChunkSize;
            endTimeIndex -= timeChunkSize;
            getTimeSeriesChunk().then(successAction);
          }
        } else {
          dispatch(postMessage("info", "No data found here"));
        }
      };

      getTimeSeriesChunk()
        .then(successAction)
        .catch((error) => {
          dispatch(postMessage("error", error));
        });
    }
  };
}

function isValidPlace(placeGroups, placeId) {
  return findPlaceInPlaceGroups(placeGroups, placeId) !== null;
}

////////////////////////////////////////////////////////////////////////////////

export const UPDATE_TIME_SERIES = "UPDATE_TIME_SERIES";

export function updateTimeSeries(
  timeSeries,
  updateMode,
  dataMode,
) {
  return { type: UPDATE_TIME_SERIES, timeSeries, updateMode, dataMode };
}

////////////////////////////////////////////////////////////////////////////////

export const ADD_PLACE_GROUP_TIME_SERIES = "ADD_PLACE_GROUP_TIME_SERIES";

export function addPlaceGroupTimeSeries(
  timeSeriesGroupId,
  timeSeries,
){
  return { type: ADD_PLACE_GROUP_TIME_SERIES, timeSeriesGroupId, timeSeries };
}

////////////////////////////////////////////////////////////////////////////////

export const REMOVE_TIME_SERIES = "REMOVE_TIME_SERIES";


export function removeTimeSeries(
  groupId,
  index,
){
  return { type: REMOVE_TIME_SERIES, groupId, index };
}

////////////////////////////////////////////////////////////////////////////////

export const REMOVE_TIME_SERIES_GROUP = "REMOVE_TIME_SERIES_GROUP";


export function removeTimeSeriesGroup(id){
  return { type: REMOVE_TIME_SERIES_GROUP, id };
}

////////////////////////////////////////////////////////////////////////////////

export const REMOVE_ALL_TIME_SERIES = "REMOVE_ALL_TIME_SERIES";


export function removeAllTimeSeries(){
  return { type: REMOVE_ALL_TIME_SERIES };
}

////////////////////////////////////////////////////////////////////////////////

export const CONFIGURE_SERVERS = "CONFIGURE_SERVERS";

export function configureServers(
  servers,
  selectedServerId,
) {
  return (dispatch, getState) => {
    if (getState().controlState.selectedServerId !== selectedServerId) {
      dispatch(removeAllTimeSeries());
      dispatch(_configureServers(servers, selectedServerId));
      dispatch(syncWithServer());
    } else if (getState().dataState.userServers !== servers) {
      dispatch(_configureServers(servers, selectedServerId));
    }
  };
}

export function _configureServers(
  servers,
  selectedServerId,
){
  return { type: CONFIGURE_SERVERS, servers, selectedServerId };
}

////////////////////////////////////////////////////////////////////////////////

export function syncWithServer() {
  return (dispatch) => {
    dispatch(updateServerInfo());
    dispatch(updateDatasets() );
    dispatch(updateColorBars());
  };
}

////////////////////////////////////////////////////////////////////////////////

export const UPDATE_EXPRESSION_CAPABILITIES = "UPDATE_EXPRESSION_CAPABILITIES";


export function updateExpressionCapabilities() {
  return (
    dispatch,
    getState,
  ) => {
    const apiServer = selectedServerSelector(getState());

    api
      .getExpressionCapabilities(apiServer.url)
      .then((expressionCapabilities) => {
        dispatch(_updateExpressionCapabilities(expressionCapabilities));
      })
      .catch((error) => {
        // dispatch(postMessage("error", error));
      });
  };
}

export function _updateExpressionCapabilities(
  expressionCapabilities,
){
  return {
    type: UPDATE_EXPRESSION_CAPABILITIES,
    expressionCapabilities: expressionCapabilities,
  };
}

////////////////////////////////////////////////////////////////////////////////

export const UPDATE_COLOR_BARS = "UPDATE_COLOR_BARS";


export function updateColorBars() {
  return (
    dispatch,
    getState,
  ) => {
    const apiServer = selectedServerSelector(getState());

    api
      .getColorBars(apiServer.url)
      .then((colorBars) => {
        dispatch(_updateColorBars(colorBars));
      })
      .catch((error) => {
        dispatch(postMessage("error", error));
      });
  };
}

export function _updateColorBars(colorBars){
  return { type: UPDATE_COLOR_BARS, colorBars };
}

////////////////////////////////////////////////////////////////////////////////

export const UPDATE_VARIABLE_COLOR_BAR = "UPDATE_VARIABLE_COLOR_BAR";

export function updateVariableColorBar(
  colorBarName,
  colorBarMinMax,
  colorBarNorm,
  opacity,
) {
  return (
    dispatch,
    getState,
  ) => {
    const selectedDatasetId = getState().controlState.selectedDatasetId;
    const selectedVariableName = getState().controlState.selectedVariableName;
    if (selectedDatasetId && selectedVariableName) {
      dispatch(
        _updateVariableColorBar(
          selectedDatasetId,
          selectedVariableName,
          colorBarName,
          colorBarMinMax,
          colorBarNorm,
          opacity,
        ),
      );
    }
  };
}

export function updateVariable2ColorBar(
  colorBarName,
  colorBarMinMax,
  colorBarNorm,
  opacity,
) {
  return (
    dispatch,
    getState,
  ) => {
    const selectedDatasetId = getState().controlState.selectedDatasetId;
    const selectedVariableName = getState().controlState.selectedVariable2Name;
    if (selectedDatasetId && selectedVariableName) {
      dispatch(
        _updateVariableColorBar(
          selectedDatasetId,
          selectedVariableName,
          colorBarName,
          colorBarMinMax,
          colorBarNorm,
          opacity,
        ),
      );
    }
  };
}

export function _updateVariableColorBar(
  datasetId,
  variableName,
  colorBarName,
  colorBarMinMax,
  colorBarNorm,
  opacity,
){
  if (colorBarNorm === "log") {
    // Adjust range in case of log norm: Make sure xcube server can use
    // matplotlib.colors.LogNorm(vmin, vmax) without errors
    let [vMin, vMax] = colorBarMinMax;
    if (vMin <= 0) {
      vMin = 1e-3;
    }
    if (vMax <= vMin) {
      vMax = 1;
    }
    colorBarMinMax = [vMin, vMax];
  }
  return {
    type: UPDATE_VARIABLE_COLOR_BAR,
    datasetId,
    variableName,
    colorBarName,
    colorBarMinMax,
    colorBarNorm,
    opacity,
  };
}

////////////////////////////////////////////////////////////////////////////////

export const UPDATE_VARIABLE_VOLUME = "UPDATE_VARIABLE_VOLUME";



export function updateVariableVolume(
  datasetId,
  variableName,
  variableColorBar,
  volumeRenderMode,
  volumeIsoThreshold,
){
  return {
    type: UPDATE_VARIABLE_VOLUME,
    datasetId,
    variableName,
    variableColorBar,
    volumeRenderMode,
    volumeIsoThreshold,
  };
}

////////////////////////////////////////////////////////////////////////////////

export function exportData() {
  return (
    _dispatch,
    getState,
  ) => {
    const {
      exportTimeSeries,
      exportTimeSeriesSeparator,
      exportPlaces,
      exportPlacesAsCollection,
      exportZipArchive,
      exportFileName,
    } = getState().controlState;

    let placeGroups = [];

    if (exportTimeSeries) {
      // Time series may reference any place, so collect all known place groups.
      placeGroups = [];
      const datasets = datasetsSelector(getState());
      datasets.forEach((dataset) => {
        if (dataset.placeGroups) {
          placeGroups = placeGroups.concat(dataset.placeGroups);
        }
      });
      placeGroups = [...placeGroups, ...userPlaceGroupsSelector(getState())];
    } else if (exportPlaces) {
      // Just export all visible places.
      placeGroups = selectedPlaceGroupsSelector(getState());
    }

    _exportData(getState().dataState.timeSeriesGroups, placeGroups, {
      includeTimeSeries: exportTimeSeries,
      includePlaces: exportPlaces,
      separator: exportTimeSeriesSeparator,
      placesAsCollection: exportPlacesAsCollection,
      zip: exportZipArchive,
      fileName: exportFileName,
    });
  };
}

class Exporter {
  write(path, content) {
    throw new Error('Method not implemented.');
  }

  close() {
    throw new Error('Method not implemented.');
  }
}

// Class ZipExporter extends Exporter
class ZipExporter extends Exporter {
  constructor(fileName) {
    super();
    this.fileName = fileName;
    this.zipArchive = new JSZip();
  }

  write(path, content) {
    this.zipArchive.file(path, content);
  }

  close() {
    this.zipArchive
      .generateAsync({ type: 'blob' })
      .then((content) => saveAs(content, this.fileName));
  }
}

class FileExporter extends Exporter {
  write(path, content) {
    const blob = new Blob([content], { type: "text/plain;charset=utf-8" });
    saveAs(blob, path);
  }

  close() {}
}


function _exportData(
  timeSeriesGroups,
  placeGroups,
  options,
) {
  const { includeTimeSeries, includePlaces, placesAsCollection, zip } = options;

  let { separator, fileName } = options;

  separator = separator || "TAB";
  if (separator.toUpperCase() === "TAB") {
    separator = "\t";
  }

  fileName = fileName || "export";

  if (!includeTimeSeries && !includePlaces) {
    return;
  }

  let exporter;
  if (zip) {
    exporter = new ZipExporter(`${fileName}.zip`);
  } else {
    exporter = new FileExporter();
  }

  let placesToExport;

  if (includeTimeSeries) {
    const { colNames, dataRows, referencedPlaces } = timeSeriesGroupsToTable(
      timeSeriesGroups,
      placeGroups,
    );
    const validTypes= {
      number: true,
      string: true,
    };
    const csvHeaderRow = colNames.join(separator);
    const csvDataRows = dataRows.map((row) =>
      row
        .map((value) => (validTypes[typeof value] ? value + "" : ""))
        .join(separator),
    );
    const csvText = [csvHeaderRow].concat(csvDataRows).join("\n");
    exporter.write(`${fileName}.txt`, csvText);
    placesToExport = referencedPlaces;
  } else {
    placesToExport = {};
    placeGroups.forEach((placeGroup) => {
      if (placeGroup.features) {
        placeGroup.features.forEach((place) => {
          placesToExport[place.id] = place;
        });
      }
    });
  }

  if (includePlaces) {
    if (placesAsCollection) {
      const collection = {
        type: "FeatureCollection",
        features: Object.keys(placesToExport).map(
          (placeId) => placesToExport[placeId],
        ),
      };
      exporter.write(
        `${fileName}.geojson`,
        JSON.stringify(collection, null, 2),
      );
    } else {
      Object.keys(placesToExport).forEach((placeId) => {
        exporter.write(
          `${placeId}.geojson`,
          JSON.stringify(placesToExport[placeId], null, 2),
        );
      });
    }
  }

  exporter.close();
}

/*
function _downloadTimeSeriesGeoJSON(timeSeriesGroups: TimeSeriesGroup[],
                                    placeGroups: PlaceGroup[],
                                    format: 'GeoJSON' | 'CSV',
                                    fileName: string = 'time-series',
                                    multiFile: boolean = true,
                                    zipArchive: boolean = true) {
    const featureCollection = timeSeriesGroupsToGeoJSON(timeSeriesGroups);

    if (format === 'GeoJSON') {
        if (zipArchive) {
            const zip = new JSZip();
            if (multiFile) {
                zip.file(`${fileName}.geojson`,
                         JSON.stringify(featureCollection, null, 2));
            } else {
                for (let feature of featureCollection.features) {
                    zip.file(`${feature.id}.geojson`,
                             JSON.stringify(feature, null, 2));
                }
            }
            zip.generateAsync({type: "blob"})
               .then((content) => saveAs(content, `${fileName}.zip`));
        } else {
            if (multiFile) {
                throw new Error('Cannot download multi-file exports');
            }
            const blob = new Blob([JSON.stringify(featureCollection, null, 2)],
                                  {type: "text/plain;charset=utf-8"});
            saveAs(blob, `${fileName}.geojson`);
        }
    } else {
        // TODO (forman): implement CSV export
        throw new Error(`Download as ${format} is not yet implemented`);
    }

}
*/

///////////////////////////////////////////////////////////////////////////////
