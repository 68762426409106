import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { styled, makeStyles } from '@mui/styles';
import Format from "../../Util/format"
import clsx from 'clsx';
import { Tooltip } from '@mui/material';
import { convertUnit } from '../../Util/convertUnit';
import plural from "../../Util/pluralize";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 13,
  borderRadius: 7,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'white',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 7,
  },
}));

const ColorSegment = styled(Box)(({ theme, color, width, borderRadius }) => ({
  position: 'absolute',
  height: '100%',
  backgroundColor: color,
  width: width,
  top: "0",
  borderRadius: borderRadius,
}));

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    
    '& > .MuiCardContent-root': {
      padding: '0',
      marginTop: 16
    }
  },
  label: {
    fontSize: 12,
    fontWeight: 400,
  },
  labelExtra: {
    color: '#6B6B6B',
    marginBottom: 6
  },
  value: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '24px'
  },
  progressContainer: {
    position: 'relative',
  },
  bar: {
    width: 3,
    height: '100%',
    backgroundColor: '#000',
    position: 'absolute',
    top: 0,
    transform: 'translateX(-1px)'
  },
  progressBar: {
    marginTop: 12,
    marginBottom: 12,
    '&.MuiLinearProgress-colorPrimary': {
      backgroundColor: theme.palette.lavenderMist,
    }
  },
  inlineBlock: {
    display: 'inline-block',
  }
}));

export const EventTimeLineCard = ({ unit, index, payoutPct, dataProvider, trigger, exit, actual, comparator }) => {
  const classes = useStyles();
  
  const maxValue = exit || 1;
  const triggerPercent = exit
    ? Math.min((trigger * 100) / exit, 100)
    : trigger > actual ? Math.min((trigger * 100) / (trigger * 1.2), 100) : Math.min((trigger * 100) / (actual), 100)

  const exitPercent = Math.min(Math.min(exit * 100 / maxValue, 100))
  const actualWidth = `${exit
    ? Math.min((actual * 100) / maxValue, 100)
    : trigger > actual ? Math.min((actual * 100) / trigger, 100): 100}%`

  const checkColor = exit ? Boolean(((trigger * 100) / exit) < (actual * 100) / maxValue ) : Boolean(((trigger * 100) / trigger) < (actual * 100) / trigger )

  const triggerWidth = `${triggerPercent}%`;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Tooltip title="The threshold value of an index breaching which the claim payouts will be made">
          <Typography className={clsx(classes.label, classes.inlineBlock)}>
            Trigger: {comparator === "GT" ? ">" : "<"} {trigger}{" "}
            {plural(convertUnit(unit), trigger)}
          </Typography>
        </Tooltip>
        <Box className={classes.progressContainer}>
          <BorderLinearProgress
            variant="determinate"
            value={
              exit
                ? Math.min((actual * 100) / maxValue, 100)
                : trigger > actual ? Math.min((actual * 100) / trigger, 100) : 100
            }
            className={classes.progressBar}
            sx={{
              [`& .${linearProgressClasses.bar}`]: {
                backgroundColor:
                  comparator === "GT"
                    ? actual > trigger
                      ? "#F44336"
                      : "#31D158"
                    : actual < trigger
                    ? "#F44336"
                    : "#31D158",
              }
            }}
          />
          {checkColor ? <ColorSegment color={`${comparator === 'GT' ? "#31D158" : "#F44336"} `} width={trigger ? triggerWidth : "0%"} borderRadius="7px 0px 0px 7px" />:""}
          {checkColor ? <ColorSegment
            color={`${comparator === 'GT' ? "#F44336" : "#31D158"} `}
            width={`calc(100% - ${triggerWidth-actualWidth})`}
            borderRadius="0px 7px 7px 0px"
            sx={{
              right: 0,
              zIndex: 0,
            }}
          />:""}
          {trigger ? (
            <Box
              className={classes.bar}
              sx={{ left: `min(${triggerPercent}%, calc(100% - 3px))` }}
            />
          ) : (
            ""
          )}
          {exit ? (
            <Box className={classes.bar} sx={{ left: `min(${exitPercent}%, calc(100% - 3px))` }} />
          ) : (
            ""
          )}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Tooltip title="The upper-bound when the payout will be capped.">
            <Typography className={classes.label}>
              Exit: {exit} {plural(convertUnit(unit), exit)}
            </Typography>
          </Tooltip>
          <Tooltip title="Last calculated index value">
            <Typography className={classes.label}>
              Actual: {actual} {plural(convertUnit(unit), actual)}
            </Typography>
          </Tooltip>
        </Box>
        <Stack mt={2} justifyContent="space-between" spacing={2} useFlexGap direction="row">
          <Stack>
            <Tooltip title="The trigger label specifies the tracked risks based on the risk type such as Excess of rainfall or deficit rainfall">
              <Typography className={clsx(classes.label, classes.labelExtra)}>Index</Typography>
            </Tooltip>
            <Typography className={classes.value}>{index}</Typography>
          </Stack>
          <Stack flexShrink={0}>
            <Tooltip title="Calculated payout based on coverage definition in percentage">
              <Typography className={clsx(classes.label, classes.labelExtra)}>
                Payout Percent
              </Typography>
            </Tooltip>
            <Typography className={classes.value} textAlign="right">
              {Format.percentageWrapFormat(payoutPct ?? 0)}
            </Typography>
          </Stack>
        </Stack>
        <Stack mt={2} justifyContent="space-between" spacing={2} useFlexGap direction="row">
          <Stack>
            <Typography className={clsx(classes.label, classes.labelExtra)}>
              Data Provider
            </Typography>
            <Typography className={classes.value}>{dataProvider}</Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
