import { useQuery } from "@tanstack/react-query";
import Ajax, { GetToken } from "../../Util/ajax";

const QUERY_KEY = "MONITORING_DATA_SOURCE";

export const useMonitoringDataCatalog = ({ dataSourceId, dataSetId, monitoringTargetId }) => {
  return useQuery({
    queryKey: [QUERY_KEY, dataSourceId, dataSetId, monitoringTargetId],
    queryFn: async () => {
      const token = await GetToken();
      const url = new URL(
        `${window.appConfig.apiUrl}/internal/data-catalog/data-providers/${dataSourceId}/data-sets/${dataSetId}/monitoring-targets/${monitoringTargetId}`,
      );

      const response = await Ajax.getData(url.href, token);

      return response;
    },
    refetchOnWindowFocus: false,
    enabled: !!dataSourceId || !!dataSetId || !!monitoringTargetId,
  });
};
