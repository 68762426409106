import { default as OlStyle } from "ol/style/Style";
import { default as OlFillStyle } from "ol/style/Fill";
import { default as OlStrokeStyle } from "ol/style/Stroke";
import { default as OlCircleStyle } from "ol/style/Circle";
import { default as OlRegularShape } from "ol/style/RegularShape";
import { default as OlPoint } from "ol/geom/Point";
import rgba from "color-rgba";

export function setFeatureStyle(
  feature,
  color,
  fillOpacity,
  pointSymbol= "circle",
) {
  if (feature.getGeometry() instanceof OlPoint) {
    feature.setStyle(
      createPointGeometryStyle(7, color, "white", 2, pointSymbol),
    );
  } else {
    fillOpacity = typeof fillOpacity === "number" ? fillOpacity : 0.25;
    let fillColorRgba = rgba(color);
    if (Array.isArray(fillColorRgba)) {
      fillColorRgba = [
        fillColorRgba[0],
        fillColorRgba[1],
        fillColorRgba[2],
        fillOpacity,
      ];
    } else {
      fillColorRgba = [255, 255, 255, fillOpacity];
    }
    feature.setStyle(createGeometryStyle(fillColorRgba, color, 2));
  }
}

export function createPointGeometryStyle(
  radius,
  fillColor,
  strokeColor,
  strokeWidth,
  pointSymbol = "circle",
){
  return new OlStyle({
    image: createImageStyle(
      radius,
      fillColor,
      strokeColor,
      strokeWidth,
      pointSymbol,
    ),
  });
}

function createImageStyle(
  radius,
  fillColor,
  strokeColor,
  strokeWidth,
  pointSymbol,
) {
  const fill = new OlFillStyle({
    color: fillColor,
  });
  const stroke = new OlStrokeStyle({
    color: strokeColor,
    width: strokeWidth,
  });
  switch (pointSymbol) {
    case "square":
      return new OlRegularShape({
        fill,
        stroke,
        radius,
        points: 4,
        angle: Math.PI / 4,
        rotation: 0.0,
      });
    case "diamond":
      return new OlRegularShape({
        fill,
        stroke,
        radius,
        points: 4,
        angle: Math.PI / 4,
        rotation: Math.PI / 4,
      });
    default:
      return new OlCircleStyle({
        fill,
        stroke,
        radius,
      });
  }
}

export function createGeometryStyle(
  fillColor,
  strokeColor,
  strokeWidth,
){
  const fill = new OlFillStyle({
    color: fillColor,
  });
  const stroke = new OlStrokeStyle({
    color: strokeColor,
    width: strokeWidth,
  });
  return new OlStyle({ fill, stroke });
}
