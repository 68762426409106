import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const EventsDetailShareModal = (props) => {
  const { open, onClose, value, error, isGeneratingLink } = props;

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiDialog-paper": {
        [theme.breakpoints.up("sm")]: {
          minWidth: 420,
          padding: 30,
        },
      },
    },
    title: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    closeIcon: {
      width: 25,
      height: 25,
      marginRight: 20,
      cursor: "pointer",
    },
    textbox: {
      width: "68%",
      borderRadius: 8,
    },
    action: {
      display: "flex",
      gap: 30,
      alignItems: "center",
    },
    btn: {
      borderRadius: 8,
      marginRight: 20,
      height: 56,
    },
  }));

  const classes = useStyles();

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const handleCopyLink = () => {
    navigator.clipboard.writeText(value).then(
      () => {
        setSnackbarMessage("Link copied to clipboard!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      },
      () => {
        setSnackbarMessage("Failed to copy link. Please copy manually.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    );
  };

  useEffect(() => {
    if(error){
      setSnackbarMessage("Failed to render link");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  },[error])

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Dialog open={open} onClose={onClose} className={classes.root}>
      <Box className={classes.title}>
        <DialogTitle id="alert-dialog-title">Share Parametric Event</DialogTitle>
        <CloseIcon onClick={onClose} className={classes.closeIcon} />
      </Box>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography gutterBottom>
            This link provides public access to weather data and parametric event information. By
            sharing it, you're allowing others to view the data without any login or authentication
            required. Please ensure that you only share with trusted individuals if sensitive
            actions rely on this data
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogTitle id="alert-dialog-title">Copy File link to share</DialogTitle>
      <DialogActions className={classes.action}>
        <TextField
          className={classes.textbox}
          placeholder={isGeneratingLink ? "Generating link..." : "www.riskwolf.com"}
          value={value}
          disabled
        />
        <Button
          color="primary"
          size="large"
          variant="contained"
          className={classes.btn}
          onClick={handleCopyLink}
        >
          Copy Link
        </Button>
      </DialogActions>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Dialog>
  );
};

export default EventsDetailShareModal;
