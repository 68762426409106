import React, { useEffect, useMemo, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Toolbar from "@mui/material/Toolbar";
import { Box, Tooltip } from "@mui/material";
import { MonitoringMap } from "./MonitoringMap";
import { EventDetailsPanel } from "../EventDetails/EventDetailsPanel";
import { useMonitoringBoundingBox } from "../../hooks/monitoring/useMonitoringBoundingBox";
import { useMonitoringVariables } from "../../hooks/monitoring/useMonitoringVariables";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useLocation } from "react-router-dom";
import AirIcon from "@mui/icons-material/Air";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import UmbrellaIcon from "@mui/icons-material/Umbrella";
import ThermostatAutoIcon from "@mui/icons-material/ThermostatAuto";
import GrainIcon from "@mui/icons-material/Grain";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f7f8fc",
  },
  main: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  mapContainer: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
    boxShadow: "0px 3px 12px 0px #0000001F",
    position: "relative",
  },
  selectDatasetBox: {
    position: "absolute",
    top: 16,
    right: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 6,
    zIndex: 10,
    cursor: "pointer",
  },
  selected: {
    color: theme.palette.primary.main,
  },
}));

const BOUNDING_BOX = {
  topLeftLat: 90,
  topLeftLon: -180,
  bottomRightLat: -90,
  bottomRightLon: 180,
};

const getIcon = (type) => {
  switch (type) {
    case "Air":
      return <AirIcon />;
    case "WbSunny":
      return <WbSunnyIcon />;
    case "Umbrella":
      return <UmbrellaIcon />;
    case "ThermostatAuto":
      return <ThermostatAutoIcon />;
    case "Grain":
      return <GrainIcon />
    default:
      return null;
  }
};

const Monitoring = () => {
  const classes = useStyles();
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [zoom, setZoom] = useState(12);
  const [bounds, setBounds] = useState();
  const [variablesSelected, setVariablesSelected] = useState("");
  const { data: variables, isLoading: variablesLoading } = useMonitoringVariables();
  const boundingBoxProps = useMemo(() => {
    return {
      ...BOUNDING_BOX,
      variableId: variablesSelected,
    };
  }, [variablesSelected]);
  const { data: regions, isLoading } = useMonitoringBoundingBox(boundingBoxProps);
  const [panelWidth, setPanelWidth] = useState(0);
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  useEffect(() => {
    if (!variables || !variables?.length) {
      return;
    }

    const param = searchParams.get("variablesSelected");
    const isExist = variables.find((item) => item.id === param);

    if (isExist) {
      setVariablesSelected(param);
    } else {
      setVariablesSelected(variables[0].id);
    }
  }, [variables, searchParams]);

  return (
    <Box className={classes.root}>
      <Toolbar />
      <Box className={classes.main}>
        <Box
          className={classes.mapContainer}
          sx={{
            borderTopRightRadius: selectedCoordinates ? 12 : 0,
            borderBottomRightRadius: selectedCoordinates ? 12 : 0,
          }}
        >
          <MonitoringMap
            regions={regions}
            isLoading={isLoading}
            selectedCoordinates={selectedCoordinates}
            setSelectedCoordinates={setSelectedCoordinates}
            bounds={bounds}
            setBounds={setBounds}
            zoom={zoom}
            setZoom={setZoom}
            panelWidth={panelWidth}
          />
          {!isLoading && (
            <EventDetailsPanel
              coordinates={selectedCoordinates}
              variableId={variablesSelected}
              zoom={zoom}
              bounds={bounds}
              setPanelWidth={setPanelWidth}
              variablesSelected={variablesSelected}
            />
          )}
          {variablesLoading ? null : (
            <Box className={classes.selectDatasetBox}>
              <ToggleButtonGroup
                value={variablesSelected}
                exclusive
                onChange={(_, value) => setVariablesSelected(value)}
              >
                {variables?.map((variable) => (
                  <Tooltip title={variable.name}>
                    <ToggleButton
                      value={variable.id}
                      className={clsx(variable.id === variablesSelected && classes.selected)}
                    >
                      {getIcon(variable.materialIcon)}
                    </ToggleButton>
                  </Tooltip>
                ))}
              </ToggleButtonGroup>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Monitoring;
