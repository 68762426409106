import { connect } from "react-redux";
import _MessageLog from "../components/MessageLog";
import { hideMessage } from "../actions/messageLogActions";

const mapStateToProps = (state) => {
  const newEntries = state.messageLogState.newEntries;
  return {
    locale: state.controlState.locale,
    message: newEntries.length > 0 ? newEntries[0] : null,
  };
};

const mapDispatchToProps = {
  hideMessage,
};

const MessageLog = connect(mapStateToProps, mapDispatchToProps)(_MessageLog);
export default MessageLog;
