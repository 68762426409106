import { connect } from "react-redux";
import {
  baseMapLayerSelector,
  overlayLayerSelector,
  imageSmoothingSelector,
  mapProjectionSelector,
  selectedDatasetBoundaryLayerSelector,
  selectedDatasetPlaceGroupLayersSelector,
  selectedDatasetRgbLayerSelector,
  selectedDataset2RgbLayerSelector,
  selectedDatasetVariableLayerSelector,
  selectedDatasetVariable2LayerSelector,
  selectedPlaceGroupPlacesSelector,
  userPlaceGroupsVisibilitySelector,
  showUserPlacesLayerSelector,
  selectedPlaceInfoSelector,
} from "../selectors/controlSelectors";
import {
  addDrawnUserPlace,
  importUserPlacesFromText,
} from "../actions/dataActions";
import _Viewer from "../components/Viewer";
import { userPlaceGroupsSelector } from "../selectors/dataSelectors";
import { selectPlace, setSidebarOpen, setSidebarPanelId} from "../actions/controlActions";
import ColorBarLegend from "./ColorBarLegend";
import ColorBarLegend2 from "./ColorBarLegend2";
import MapSplitter from "./MapSplitter";
import MapPointInfoBox from "./MapPointInfoBox";
import MapControlActions from "./MapControlActions";

const mapStateToProps = (state, ownProps) => {
  return {
    mapId: "map",
    locale: state.controlState.locale,
    variableLayer: selectedDatasetVariableLayerSelector(state),
    variable2Layer: selectedDatasetVariable2LayerSelector(state),
    rgbLayer: selectedDatasetRgbLayerSelector(state),
    rgb2Layer: selectedDataset2RgbLayerSelector(state),
    datasetBoundaryLayer: selectedDatasetBoundaryLayerSelector(state),
    placeGroupLayers: selectedDatasetPlaceGroupLayersSelector(state),
    colorBarLegend: <ColorBarLegend />,
    colorBarLegend2: <ColorBarLegend2 />,
    mapSplitter: <MapSplitter />,
    mapPointInfoBox: <MapPointInfoBox />,
    mapControlActions: <MapControlActions />,
    userDrawnPlaceGroupName: state.controlState.userDrawnPlaceGroupName,
    userPlaceGroups: userPlaceGroupsSelector(state),
    userPlaceGroupsVisibility: userPlaceGroupsVisibilitySelector(state),
    showUserPlaces: showUserPlacesLayerSelector(state),
    mapInteraction: state.controlState.mapInteraction,
    mapProjection: mapProjectionSelector(state),
    selectedPlaceId: state.controlState.selectedPlaceId,
    places: selectedPlaceGroupPlacesSelector(state),
    baseMapLayer: baseMapLayerSelector(state),
    overlayLayer: overlayLayerSelector(state),
    imageSmoothing: imageSmoothingSelector(state),
    variableSplitPos: state.controlState.variableSplitPos,
    onMapRef: ownProps.onMapRef,
    selectedPlaceInfo: selectedPlaceInfoSelector(state),
    sidebarOpen: state.controlState.sidebarOpen,
  };
};

const mapDispatchToProps = {
  addDrawnUserPlace,
  importUserPlacesFromText,
  selectPlace,
  setSidebarOpen,
  setSidebarPanelId
};

const Viewer = connect(mapStateToProps, mapDispatchToProps)(_Viewer);
export default Viewer;
