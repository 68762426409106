import { default as OlVectorLayer } from "ol/layer/Vector";

import MapComponent from "../MapComponent";
import { processLayerProperties } from "./common";

export class Vector extends MapComponent{
  addMapObject(map) {
    const layer = new OlVectorLayer(this.props);
    layer.set("id", this.props.id);
    map.getLayers().push(layer);
    return layer;
  }

  updateMapObject(
    _map,
    layer,
    prevProps,
  ) {
    // TODO: Code duplication in ./Tile.tsx
    if (this.props.source !== prevProps.source && this.props.source) {
      layer.setSource(this.props.source);
    }
    processLayerProperties(layer, prevProps, this.props);
    return layer;
  }

  removeMapObject(map, layer){
    map.getLayers().remove(layer);
  }
}
