import { useMutation } from "@tanstack/react-query";
import Ajax, { GetToken } from "../../Util/ajax";

const MUTATION_KEY = "EVENT_DETAILS_LINK_MUTATION";

export const useEventDetaislink = () => {
  return useMutation({
    mutationKey: [MUTATION_KEY],
    mutationFn: async ({ monitoringId }) => {
      if (!monitoringId) {
        return null;
      }

      const token = await GetToken();
      const url = new URL(
        `${window.appConfig.apiUrl}/internal/parametric-events/${monitoringId}/permalink`,
      );

      const response = await Ajax.postData(url.href, {}, token);

      return response;
    },
  });
};
