import { useQuery } from "@tanstack/react-query";
import Ajax, { GetToken } from "../../Util/ajax";

const QUERY_KEY = `POLICIES_QUERY`;

export const usePolicies = ({ page, size }) => {
  return useQuery({
    queryKey: [QUERY_KEY, page, size],
    queryFn: async () => {
      const token = await GetToken();
      const url = new URL(`${window.appConfig.apiUrl}/internal/policies`);
      url.searchParams.set("page", page);
      url.searchParams.set("size", size);

      const response = await Ajax.getData(url.href, token);

      return response;
    },
    refetchOnWindowFocus: false,
  });
};
