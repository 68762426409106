function _getBaseUrl() {
  const url = new URL(window.location.href);
  const pathComponents = url.pathname.split("/");
  const numPathComponents = pathComponents.length;
  if (numPathComponents > 0) {
    const lastComponent = pathComponents[numPathComponents - 1];
    if (lastComponent === "index.html") {
      return new URL(
        pathComponents.slice(0, numPathComponents - 1).join("/"),
        window.location.origin,
      );
    } else {
      return new URL(url.pathname, window.location.origin);
    }
  }
  return new URL(window.location.origin);
}

const baseUrl = _getBaseUrl();

export default baseUrl;

console.log("baseUrl = ", baseUrl.href);
