
import {
  callJsonApi,
  makeRequestInit,
  makeRequestUrl,
} from "../api/callApi";
import { encodeDatasetId, encodeVariableName } from "../model/encode";

export function getPointValue(
  apiServerUrl,
  dataset,
  variable,
  lon,
  lat,
  time,
  accessToken,
){
  const query = [
    ["lon", lon.toString()],
    ["lat", lat.toString()],
  ];
  if (time) {
    query.push(["time", time]);
  }
  const url = makeRequestUrl(
    `${apiServerUrl}/statistics/${encodeDatasetId(dataset)}/${encodeVariableName(variable)}`,
    query,
  );
  return callJsonApi(url, makeRequestInit(accessToken), (result) =>
    result.result ? result.result : {},
  );
}
