import CameraAltIcon from "@mui/icons-material/CameraAlt";

import i18n from "../i18n";
// import { exportElement } from "../util/export";
import ToolButton from "../components/ToolButton";

export default function SnapshotButton({
  elementRef,
  postMessage,
}) {
  const handleExportSuccess = () => {
    postMessage("success", i18n.get("Snapshot copied to clipboard"));
  };

  const handleExportError = (error) => {
    const message = "Error copying snapshot to clipboard";
    console.error(message + ":", error);
    postMessage("error", i18n.get(message));
  };

  const handleButtonClick = () => {
    if (elementRef.current) {
      // exportElement(elementRef.current, {
      //   format: "png",
      //   width: 2000,
      //   handleSuccess: handleExportSuccess,
      //   handleError: handleExportError,
      // });
    } else {
      handleExportError(new Error("missing element reference"));
    }
  };

  return (
    <ToolButton
      tooltipText={i18n.get("Copy snapshot of chart to clipboard")}
      onClick={handleButtonClick}
      icon={<CameraAltIcon fontSize="inherit" />}
    />
  );
}
