import React, { useRef } from "react";

import { isNumber } from "../util/types";
import Splitter from "./Splitter";

// noinspection JSUnusedLocalSymbols
const styles = {
  hor: {
    display: "flex",
    flexFlow: "row nowrap",
    flex: "auto", // same as "flex: 1 1 auto;"
  },
  ver: {
    // width: "100%",
    height: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    flex: "auto", // same as "flex: 1 1 auto;"
  },
  childHor: {
    flex: "none",
  },
  childVer: {
    flex: "none",
  },
};

/**
 * A simple SplitPane component which must have exactly two child elements.
 */
export default function SplitPane({
  dir,
  splitPosition,
  setSplitPosition,
  children,
  style,
  child1Style,
  child2Style,
}) {
  const child1Ref = useRef(null);

  if (!children || !Array.isArray(children) || children.length !== 2) {
    return null;
  }

  const childStyle = dir === "hor" ? styles.childHor : styles.childVer;

  const child1SizeStyle =
    dir === "hor" ? { width: splitPosition } : { height: splitPosition };

  const handleSplitChange = (delta) => {
    if (child1Ref.current && isNumber(child1Ref.current.clientWidth)) {
      setSplitPosition(child1Ref.current.clientWidth + delta);
    }
  };

  return (
    <div
      id="SplitPane"
      style={{ ...style, ...(dir === "hor" ? styles.hor : styles.ver) }}
    >
      <div
        ref={child1Ref}
        id="SplitPane-Child-1"
        style={{ ...childStyle, ...child1Style, ...child1SizeStyle }}
      >
        {children[0]}
      </div>
      <Splitter dir={dir} onChange={handleSplitChange} />
      <div id="SplitPane-Child-2" style={{ ...childStyle, ...child2Style }}>
        {children[1]}
      </div>
    </div>
  );
}
