

import ColorBarGroupHeader from "./ColorBarGroupHeader";
import ColorBarItem from "./ColorBarItem";

export default function ColorBarGroupComponent({
  colorBarGroup,
  selectedColorBarName,
  onSelectColorBar,
  images,
}) {
  return (
    <>
      <ColorBarGroupHeader
        title={colorBarGroup.title}
        description={colorBarGroup.description}
      />
      {colorBarGroup.names.map((name) => (
        <ColorBarItem
          key={name}
          title={name}
          imageData={images[name]}
          selected={name === selectedColorBarName}
          onSelect={() => onSelectColorBar(name)}
        />
      ))}
    </>
  );
}
