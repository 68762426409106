import { assertArrayNotEmpty, assertDefinedAndNotNull } from "../util/assert";
import { isString } from "../util/types";

export function findDataset(
  datasets,
  selectedDatasetId,
){
  return (
    (selectedDatasetId &&
      datasets.find((dataset) => dataset.id === selectedDatasetId)) ||
    null
  );
}

export function findDatasetVariable(
  dataset,
  variableName,
){
  return (
    (variableName &&
      dataset.variables.find((variable) => variable.name === variableName)) ||
    null
  );
}

export function getDatasetUserVariablesIndex(dataset) {
  // vIndex is the first index that is a user variable.
  return dataset.variables.findIndex((v) => isString(v.expression));
}

export function getDatasetUserVariables(
  dataset,
){
  // vIndex is the first index that is a user variable.
  const vIndex = getDatasetUserVariablesIndex(dataset);
  // All variables starting at vIndex are user variables.
  if (vIndex >= 0) {
    return [
      dataset.variables.slice(0, vIndex),
      dataset.variables.slice(vIndex) ,
    ];
  } else {
    return [dataset.variables, []];
  }
}

export function getDatasetTimeDimension(
  dataset,
) {
  assertDefinedAndNotNull(dataset, "dataset");
  assertArrayNotEmpty(dataset.dimensions, "dataset.dimensions");
  const dimension = dataset.dimensions.find(
    (dimension) => dimension.name === "time",
  );
  if (!dimension) {
    return null;
  }
  assertArrayNotEmpty(dimension.coordinates, "timeDimension.coordinates");
  assertArrayNotEmpty(
    (dimension ).labels,
    "timeDimension.labels",
  );
  return dimension ;
}

export function getDatasetTimeRange(dataset){
  const timeDimension = getDatasetTimeDimension(dataset);
  if (!timeDimension) {
    return null;
  }
  const coordinates = timeDimension.coordinates;
  return [coordinates[0], coordinates[coordinates.length - 1]];
}
