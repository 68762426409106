import { useQuery } from "@tanstack/react-query";
import Ajax, { GetToken } from "../../Util/ajax";

const QUERY_KEY = "BUSINESS_PARTNER_ROLES";

export const useBusinessPartnerRoles = ({ enabled = true } = {}) => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      const token = await GetToken();
      const URL = `${window.appConfig.apiUrl}/internal/business-partners/roles`;
      const response = await Ajax.getData(URL, token);

      return response;
    },
    refetchOnWindowFocus: false,
    enabled,
  });
};
