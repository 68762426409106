import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import i18n from "../../i18n";
import { makeStyles } from "../../util/styles";

const styles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 6,
    gap: 2,
  },
});


export default function NoTimeSeriesChart({
  canAddTimeSeries,
  addTimeSeries,
}) {
  return (
    <Box sx={styles.container}>
      <Button disabled={!canAddTimeSeries} onClick={addTimeSeries}>
        {i18n.get("Add Time-Series")}
      </Button>
      <Typography fontSize="smaller">
        {i18n.get(
          "No time-series have been obtained yet. Select a variable and a place first.",
        )}
      </Typography>
    </Box>
  );
}
