const UNIT_TABLE = {
  deg_c: "°C",
  degc: "°C",
};

export const convertUnit = (unit) => {
  if (typeof unit === 'string') {
    return UNIT_TABLE[unit.toLowerCase()] ?? unit;
  }
  return unit;
};
