import React from "react";
import { connect } from "react-redux";
import { Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Config } from "../config";
import ControlBar from "./ControlBar";
import Workspace from "./Workspace";
import LayerControlPanel from "./LayerControlPanel";

const mapStateToProps = (state) => {
  return {
    locale: state.controlState.locale,
    hasConsent: state.controlState.privacyNoticeAccepted,
    compact: Config.instance.branding.compact,
  };
};

const mapDispatchToProps = {};

const StyledMain = styled("main")(({ theme }) => ({
  padding: 0,
  width: "100%",
  height: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  [theme.breakpoints.up("md")]: {
    overflow: "hidden",
  },
}));

const AppPane = ({ hasConsent, compact }) => {
  return (
    <StyledMain>
      {!compact && <Toolbar variant="dense" />}
      {hasConsent && (
        <>
          <ControlBar />
          <Workspace />
          <LayerControlPanel />
        </>
      )}
    </StyledMain>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppPane);
