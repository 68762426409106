

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const BUTTON_STYLE = {
  // Same sizes as for OpenLayers
  width: "1.375em",
  height: "1.375em",
};

const SELECTED_BUTTON_STYLE= {
  ...BUTTON_STYLE,
  backgroundColor: "rgba(0,80,180,0.9)",
};

const TOOLTIP_COMPONENT_PROPS = {
  tooltip: {
    // Makes tooltip style  similar to OpenLayers
    sx: {
      backgroundColor: "#4A4A4A",
      border: "1px solid white",
      borderRadius: 0,
    },
  },
};

export default function MapButton({
  icon,
  tooltipTitle,
  onClick,
  selected,
  onSelect,
}) {
  const handleClick = (event) => {
    if (onSelect) {
      onSelect(event, !selected);
    }
    if (onClick) {
      onClick(event);
    }
  };

  if (tooltipTitle) {
    icon = (
      <Tooltip title={tooltipTitle} componentsProps={TOOLTIP_COMPONENT_PROPS}>
        {icon}
      </Tooltip>
    );
  }

  return (
    <IconButton
      onClick={handleClick}
      style={selected ? SELECTED_BUTTON_STYLE : BUTTON_STYLE}
    >
      {icon}
    </IconButton>
  );
}
