import { useQuery } from "@tanstack/react-query";
import Ajax, { GetToken } from "../../Util/ajax";

const QUERY_KEY = "QUOTE_DASHBOARD_QUERY";

export const useQuoteDashboard = () => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      const token = await GetToken();
      const URL = `${window.appConfig.apiUrl}/internal/quotes/dashboard`;
      const response = await Ajax.getData(URL, token);

      return response;
    },
    refetchOnWindowFocus: false,
  });
};
