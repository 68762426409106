import * as React from "react";
import Checkbox from "@mui/material/Checkbox";

export default function CheckBoxAllSellectPlaces({
  isSelectAll,
  setIsSelectAll
}) {
  const handleChange = (event) => {
    setIsSelectAll(event.target.checked);
  };

  return (
    <Checkbox checked={isSelectAll} onChange={handleChange} />
  );
}
