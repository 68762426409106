

import { fromExtent } from "ol/geom/Polygon";
import { getCenter } from "ol/extent";

import { GEOGRAPHIC_CRS } from "../model/proj";
import { MAP_OBJECTS } from "../states/controlState";
import { setFeatureStyle } from "../components/ol/style";

// noinspection JSUnusedLocalSymbols
export function renameUserPlaceInLayer(
  placeGroupId,
  _placeId,
  _newName,
) {
  if (MAP_OBJECTS[placeGroupId]) {
    // const userLayer = MAP_OBJECTS[placeGroupId] as OlVectorLayer;
    // const source = userLayer.getSource();
    // TODO (forman): update feature source in user layer to reflect newName.
    //  Note, this is not yet an issue, because we still don't show user places labels
    //  in the viewer.
  }
}

export function restyleUserPlaceInLayer(
  placeGroupId,
  placeId,
  placeStyle,
) {
  if (MAP_OBJECTS[placeGroupId]) {
    const userLayer = MAP_OBJECTS[
      placeGroupId
    ] ;
    const source = userLayer.getSource();
    const feature = source?.getFeatureById(placeId);
    if (feature) {
      // console.log("selected feature:", feature, placeStyle);
      setFeatureStyle(feature, placeStyle.color, placeStyle.opacity);
    }
  }
}

export function locateInMap(
  mapId,
  location,
  shouldZoom,
) {
  if (MAP_OBJECTS[mapId]) {
    const map = MAP_OBJECTS[mapId];
    const projection = map.getView().getProjection();
    const _geometry = Array.isArray(location) ? fromExtent(location) : location;
    const geometry = _geometry.transform(
      GEOGRAPHIC_CRS,
      projection,
    ) ;
    if (geometry.getType() === "Point") {
      // Points don't zoom. Just reset map center.
      // Not ideal, but better than zooming in too deep (see #54)
      map.getView().setCenter(geometry.getFirstCoordinate());
    } else if (!shouldZoom) {
      map.getView().setCenter(getCenter(geometry.getExtent()));
    } else {
      map.getView().fit(geometry, { size: map.getSize() });
    }
  }
}
