import { default as OlDrawInteraction } from "ol/interaction/Draw";

import MapComponent from "../MapComponent";

export class Draw extends MapComponent{
  addMapObject(map){
    const draw = new OlDrawInteraction(this.getOptions());
    const active = !!this.props.active;
    draw.setActive(active);
    map.addInteraction(draw);
    if (active) {
      this.listen(draw, this.props);
    }
    return draw;
  }

  updateMapObject(
    _map,
    draw,
    prevProps,
  ){
    draw.setProperties(this.getOptions());
    const active = !!this.props.active;
    draw.setActive(active);
    this.unlisten(draw, prevProps);
    if (active) {
      this.listen(draw, this.props);
    }
    return draw;
  }

  removeMapObject(map, draw){
    this.unlisten(draw, this.props);
    map.removeInteraction(draw);
  }

  getOptions(){
    const options = super.getOptions();
    delete options["layerId"];
    delete options["active"];
    delete options["onDrawStart"];
    delete options["onDrawEnd"];
    const layerId = this.props.layerId;
    if (layerId && !options.source) {
      const vectorLayer = this.getMapObject(layerId);
      if (vectorLayer) {
        options["source"] = (
          vectorLayer
        ).getSource();
      }
    }
    return options;
  }

 listen(draw, props) {
    const { onDrawStart, onDrawEnd } = props;
    if (onDrawStart) {
      draw.on("drawstart", onDrawStart);
    }
    if (onDrawEnd) {
      draw.on("drawend", onDrawEnd);
    }
  }

 unlisten(draw, props) {
    const { onDrawStart, onDrawEnd } = props;
    if (onDrawStart) {
      draw.un("drawstart", onDrawStart);
    }
    if (onDrawEnd) {
      draw.un("drawend", onDrawEnd);
    }
  }
}
