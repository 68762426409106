import { useForm, FormProvider } from "react-hook-form";
import {
  Box,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Toolbar from "@mui/material/Toolbar";
import { useHistory } from "react-router-dom";
import ToolboxPartnersNew from "../Toolbox/ToolboxPartnersNew";
import { ButtonRound } from "../Buttons/Buttons";
import Ajax, { GetToken } from "../../Util/ajax";
import { useBusinessPartnerRoles } from "../../hooks/businessPartner/useBusinessPartnerRoles";
import { getLabel } from "../../Util/getLabel";
import { useTaxStatuses } from "../../hooks/taxStatuses/useTaxStatues";
import { useState } from "react";
import { GeocodingAutocomplete } from "../GeocodingAutocomplete/GeocodingAutocomplete";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(6),
  },
  formGroup: {
    "& + *": {
      marginTop: theme.spacing(5),
    },
  },
  main: {
    borderRadius: 12,
    boxShadow: "0px 3px 12px 0px #0000001F",
    backgroundColor: "white",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
  },
  addManually: {
    textTransform: "capitalize",
    color: theme.palette.success.main,
  },
}));

const URI = `${window.appConfig.apiUrl}/internal/business-partners`;

const PartnerNew = () => {
  const { data: roles = [] } = useBusinessPartnerRoles();
  const { data: taxStatuses = [] } = useTaxStatuses();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const form = useForm({
    defaultValues: {
      name: "",
      contactName: "",
      email: "",
      houseNumber: "",
      street: "",
      postcode: "",
      city: "",
      district: "",
      county: "",
      state: "",
      stateCode: "",
      country: "",
      countryCode: "",
      phone: "",
      taxStatus: "",
      role: "",
    },
  });

  const [
    name,
    contactName,
    email,
    houseNumber,
    street,
    postcode,
    city,
    district,
    county,
    state,
    stateCode,
    country,
    countryCode,
    phone,
    taxStatus,
    role,
  ] = form.watch([
    "name",
    "contactName",
    "email",
    "houseNumber",
    "street",
    "postcode",
    "city",
    "district",
    "county",
    "state",
    "stateCode",
    "country",
    "countryCode",
    "phone",
    "taxStatus",
    "role",
  ]);

  const handleSubmit = () => {
    const payload = {
      "@type": "NewBusinessPartner",
      name,
      role,
      contactName,
      contactEmailAddress: email,
      contactPhoneNumber: phone,
      taxStatus: taxStatus,
      address: {
        "@type": "NewAddress",
        houseNumber: houseNumber,
        street: street,
        postcode: postcode,
        city: city,
        district: district,
        county: county,
        state: state,
        stateCode: stateCode,
        country: country,
        countryCode: countryCode,
      },
    };
    GetToken().then((token) => {
      setIsLoading(true);
      Ajax.postData(URI, payload, token)
        .then(() => {
          history.push("/clients");
        })
        .catch((error) => {
          console.log("create partner failed");
        })
        .finally(() => setIsLoading(false));
    });
  };

  const handleSelectPlace = (value) => {
    if (!value) {
      [
        "houseNumber",
        "street",
        "postcode",
        "city",
        "district",
        "county",
        "state",
        "stateCode",
        "country",
        "countryCode",
      ].forEach((key) => form.setValue(key, null));
    }

    const properties = value?.properties;

    Object.entries({
      houseNumber: properties?.housenumber ?? null,
      street: properties?.street ?? null,
      postcode: properties?.postcode ?? null,
      city: properties?.city ?? null,
      district: properties?.district ?? null,
      county: properties?.county ?? null,
      state: properties?.state ?? null,
      stateCode: properties?.state_code ?? null,
      country: properties?.country ?? null,
      countryCode: properties?.country_code ?? null,
    }).forEach(([key, val]) => form.setValue(key, val));
  };

  const editDetailsStep = (
    <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item sm={12} md={6}>
        <TextField {...form.register("name")} label="Name" fullWidth required />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextField {...form.register("contactName")} label="Contact name" fullWidth required />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextField {...form.register("email")} label="Email" type="email" fullWidth required />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextField {...form.register("phone")} label="Phone Number" fullWidth required />
      </Grid>
      <Grid item sm={12} md={6}>
        <FormControl fullWidth>
          <InputLabel id="taxStatus">Tax status</InputLabel>
          <Select
            {...form.register("taxStatus")}
            labelId="taxStatus"
            label="Tax status"
            variant="outlined"
          >
            {taxStatuses.map((taxStatus) => (
              <MenuItem key={taxStatus.id} value={taxStatus.id}>
                {taxStatus.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item sm={12} md={6}>
        <FormControl fullWidth>
          <InputLabel id="roleLabel">Role</InputLabel>
          <Select {...form.register("role")} labelId="roleLabel" label="Role" variant="outlined">
            {roles.map((role) => (
              <MenuItem key={role} value={role}>
                {getLabel(role)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item sm={12}>
        <Typography>Address</Typography>
      </Grid>
      <Grid item sm={12} md={6}>
        <GeocodingAutocomplete
          label="Find address"
          placeholder="Find address"
          onChange={handleSelectPlace}
          countryCode={null}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextField
          InputLabelProps={{ shrink: !!houseNumber }}
          {...form.register("houseNumber")}
          label="House number"
          fullWidth
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextField
          InputLabelProps={{ shrink: !!street }}
          {...form.register("street")}
          label="Street"
          fullWidth
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextField
          InputLabelProps={{ shrink: !!postcode }}
          {...form.register("postcode")}
          label="Post code"
          fullWidth
          required
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextField
          InputLabelProps={{ shrink: !!city }}
          {...form.register("city")}
          label="City"
          fullWidth
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextField
          InputLabelProps={{ shrink: !!district }}
          {...form.register("district")}
          label="District"
          fullWidth
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextField
          InputLabelProps={{ shrink: !!county }}
          {...form.register("county")}
          label="County"
          fullWidth
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextField
          InputLabelProps={{ shrink: !!state }}
          defaultValue=""
          {...form.register("state")}
          label="State"
          fullWidth
          required
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextField
          InputLabelProps={{ shrink: !!stateCode }}
          {...form.register("stateCode")}
          label="State code"
          fullWidth
          required
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextField
          InputLabelProps={{ shrink: !!country }}
          {...form.register("country")}
          label="Country"
          fullWidth
          required
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextField
          InputLabelProps={{ shrink: !!countryCode }}
          {...form.register("countryCode")}
          label="Country code"
          fullWidth
          required
        />
      </Grid>
    </Grid>
  );

  return (
    <Box className={classes.root}>
      <Toolbar />
      <ToolboxPartnersNew />
      <Grid container sx={{ py: 4, px: 2.5 }}>
        <Box className={classes.main}>
          <FormProvider {...form}>
            <form noValidate autoComplete="off">
              {editDetailsStep}
              <Grid container justifyContent="flex-end" sx={{ mt: 4 }}>
                <Grid item>
                  <ButtonRound
                    className={classes.button}
                    onClick={handleSubmit}
                    disabled={isLoading}
                  >
                    Submit
                  </ButtonRound>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Grid>
    </Box>
  );
};

export default PartnerNew;
