import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import makeStyles from '@mui/styles/makeStyles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBack from '@mui/icons-material/ArrowBack';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useEventDetaislink } from "../../hooks/eventDetails/useEventDetailsLink";
import ShareIcon from '@mui/icons-material/Share';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';

const useStyles = makeStyles((theme) => ({
  toolbox: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    border: '1px solid #EFEFEF',
    position: 'sticky',
    top: 56,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: theme.zIndex.appBar,
    backgroundColor: '#f7f8fc',
    [theme.breakpoints.up('md')]: {
      top: 64,
    }
  },
  title: {
    marginRight: theme.spacing(2),
    fontSize: 18,
    fontWeight: 700,
    marginLeft: theme.spacing(2)
  },
  badgeContent: {
    width: 16,
    height: 16,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    marginRight: 6,
    marginBottom: 6,
  },
  badge: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: '50%',
    width: 40,
    height: 40,
  },
  btnBookmark: {
    borderRadius: 8,
    width: 140,
    height: 44,
  },
  btnShare: {
    borderRadius: 8,
    width: 118,
    height: 44,
  },
  icon:{
    marginRight: 10
  }
}));

export const EventDetailsToolbox = ({ recipients = [], coverageId, setEventDetailsShareModal, monitoringId , setEventDetailsShareLink, setError, setIsGenerating}) => {
  const classes = useStyles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { mutateAsync: getEventDetaislink, isLoading } = useEventDetaislink();

  const backTo = searchParams.get("backTo");
  const coordinates = searchParams.get("coordinates");
  const bounds = searchParams.get("bounds");
  const tab = searchParams.get("tab");
  const variablesSelected = searchParams.get("variablesSelected");


  const getLink = React.useCallback(
    async ({ monitoringId }) => {
      try {
        const link = await getEventDetaislink({ monitoringId });
        return link;
      } catch (err) {
        setError(true);
        return null;
      }
    },
    [getEventDetaislink, setError]
  );

  setIsGenerating(isLoading);

  const handleOpenEventDetailsShareModal = async () => {
    setEventDetailsShareModal(true);
    const link = await getLink({
      monitoringId,
    });
    const url = new URL(`${window.appConfig.apiUrl}/m/${link.permalink}`);
    setEventDetailsShareLink(url);
  };

  const monitoringPath =
    coordinates && bounds
      ? `/monitoring?coordinates=${coordinates}&bounds=${bounds}&variablesSelected=${variablesSelected}`
      : "/monitoring";

  const backPath = backTo ? backTo + (tab ? `?tab=${tab}` : "") : monitoringPath;

  return (
    <Box className={classes.toolbox}>
      <Button sx={{ display: "flex", alignItems: "center" }} component={RouterLink} to={backPath}>
        <ArrowBack />
        <Typography className={classes.title} variant="h6">
          Monitoring
        </Typography>
      </Button>
      <Stack direction="row" gap={2.5}>
        {coverageId && (
          <Button
            variant="outlined"
            className={classes.btn}
            size="large"
            component={RouterLink}
            to={`/coverages/coverage/${coverageId}?backTo=${location.pathname}`}
          >
            Coverage Details
          </Button>
        )}
        {/* {coverageId && (
          <Button
            color="primary"
            variant="contained"
            className={classes.btnBookmark}
            size="large"
            disableElevation
          >
            <BookmarkAddIcon className={classes.icon}/>
            BOOKMARK
          </Button>
        )} */}
         {coverageId && (
          <Button
            color="primary"
            variant="contained"
            onClick={handleOpenEventDetailsShareModal}
            className={classes.btnShare}
            size="large"
            disableElevation
          >
            <ShareIcon className={classes.icon}/>
            Share
          </Button>
        )}
        {recipients.map((recipient, index) => (
          <Badge
            key={`recipient-${index}`}
            className={classes.badge}
            sx={{
              borderColor:
                recipient.status === "Approved"
                  ? "#004DA8"
                  : recipient.status === "Canceled"
                  ? "#D437B1"
                  : "#7B61FF",
            }}
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <Box
                className={classes.badgeContent}
                sx={{
                  backgroundColor:
                    recipient.status === "Approved"
                      ? "#5ACD00"
                      : recipient.status === "Canceled"
                      ? "#BB0000"
                      : "#FFD600",
                }}
              >
                {recipient.status === "Approved" && <CheckIcon fontSize="12px" />}
                {recipient.status === "Canceled" && <CloseIcon fontSize="12px" />}
                {recipient.status === "Pending" && <MoreHorizIcon fontSize="12px" />}
              </Box>
            }
          >
            <Avatar alt="Travis Howard" />
          </Badge>
        ))}
      </Stack>
    </Box>
  );
}
