import { useQuery } from "@tanstack/react-query";
import Ajax, { GetToken } from "../../Util/ajax";

const QUERY_KEY = "MONITORING_POINT";

export const useMonitoringPoint = (props) => {
  return useQuery({
    queryKey: [QUERY_KEY, props],
    queryFn: async () => {
      const { lng, lat, variableId } = props;

      if (!lng || !lat || !variableId) {
        return null;
      }

      const token = await GetToken();
      const url = new URL(`${window.appConfig.apiUrl}/internal/monitoring/point`);

      url.searchParams.set("longitude", lng);
      url.searchParams.set("latitude", lat);
      if(variableId) {
        url.searchParams.set("variableId", variableId);
      }

      const response = await Ajax.getData(url.href, token);

      return response;
    },
    refetchOnWindowFocus: false,
    enabled: !!props,
  });
};
