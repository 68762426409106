import { Typography, Box, Skeleton, Stack, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 4,
    backgroundColor: '#F6F9FD',
    padding: theme.spacing(2.5),
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
  },
  name: {
    fontSize: 18,
    fontWeight: 700,
    marginTop: theme.spacing(2.5),
  },
  description: {
    fontSize: 14,
    fontWeight: 400,
    marginTop: theme.spacing(2.5),
  },
  fieldLabel: {
    fontSize: 12,
    fontWeight: 400,
  },
  fieldValue: {
    fontSize: 16,
    fontWeight: 400,
    marginTop: theme.spacing(1.25),
  },
  createBtn: {
    fontWeight: 600,
    borderRadius: 8,
  },
}));

export const DataSetCard = (props) => {
  const { card, isLoading } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {isLoading ? (
        <Skeleton />
      ) : (
        <Typography className={classes.title} variant="h6">
          {props?.card?.category ?? '---'}
        </Typography>
      )}
      {isLoading ? (
        <Skeleton />
      ) : (
        <Typography className={classes.name} variant="h5">
          {props?.card?.name ?? '---'}
        </Typography>
      )}
      {isLoading ? (
        <Skeleton />
      ) : (
        <Typography className={classes.description} variant="body1">
          {props?.card?.description ?? '---'}
        </Typography>
      )}
      {isLoading ? (
        <Skeleton />
      ) : (
        <Stack sx={{ mt: 2.5 }} spacing={2}>
          {card && (
            <Button
              component={RouterLink}
              className={classes.createBtn}
              variant="contained"
              size="large"
              to={`coverages/new?riskType=${card?.riskType?.id}&dataProviderId=${card?.dataProvider?.id}&dataSetId=${card?.id}`}
            >
              Create coverage
            </Button>
          )}
        </Stack>
      )}
    </Box>
  );
};
