
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import i18n from "../../i18n";
import {
  USER_COLOR_BAR_CODE_EXAMPLE,
} from "../../model/userColorBar";
import DoneCancel from "../../components/DoneCancel";
import ColorBarItem from "./ColorBarItem";
import ColorMapTypeEditor from "./ColorMapTypeEditor";

export default function UserColorBarEditor({
  userColorBar,
  updateUserColorBar,
  selected,
  onSelect,
  onDone,
  onCancel,
}) {
  const handleCodeChange = (event) => {
    updateUserColorBar({ ...userColorBar, code: event.currentTarget.value });
  };

  const handleTypeChange = (colorMapType) => {
    updateUserColorBar({
      ...userColorBar,
      type: colorMapType,
    });
  };

  return (
    <Box>
      <ColorBarItem
        imageData={userColorBar.imageData}
        title={userColorBar.errorMessage}
        selected={selected}
        onSelect={onSelect}
      />
      <ColorMapTypeEditor
        colorMapType={userColorBar.type}
        setColorMapType={handleTypeChange}
      />
      <TextField
        label="Color mapping"
        placeholder={USER_COLOR_BAR_CODE_EXAMPLE}
        multiline
        fullWidth
        size="small"
        minRows={3}
        sx={{ marginTop: 1, fontFamily: "monospace" }}
        value={userColorBar.code}
        onChange={handleCodeChange}
        color={userColorBar.errorMessage ? "error" : "primary"}
        inputProps={{ style: { fontFamily: "monospace", fontSize: 12 } }}
      />
      <DoneCancel
        onDone={onDone}
        onCancel={onCancel}
        doneDisabled={!!userColorBar.errorMessage}
        size="small"
        helpUrl={i18n.get("docs/color-mappings.en.md")}
      />
    </Box>
  );
}
