import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const QUERY_KEY = "EVENT_EXTERNAL_QUERY";

export const useEventExternal = (props) => {
  return useQuery({
    queryKey: [QUERY_KEY, props],
    queryFn: async () => {
      if (!props) {
        return null;
      }

      const { monitoringId } = props;

      const url = new URL(
        `${window.appConfig.apiUrl}/internal/permalinks/${monitoringId}`,
      );

      const response = await axios.get(url.href);

      return response.data;
    },
  });
};