import React, { useEffect, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { EventTimeLine } from './EventTimeLine';
import { useMonitoringPoint } from "../../hooks/monitoring/useMonitoringPoint";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    width: '50%',
    transition: 'all 500ms ease-in-out',
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: 20
  },
  header: {
    minHeight: 70,
    height: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#EEEEEE',
    padding: `0 ${theme.spacing(2.5)}`
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  formControl: {
    width: 195,
    borderRadius: 8,
    height: 40,
  },
}));

export const EventDetailsPanel = ({
  coordinates,
  variableId,
  zoom,
  bounds,
  setPanelWidth,
  variablesSelected,
}) => {
  const classes = useStyles();
  const { data: events } = useMonitoringPoint({ ...coordinates, variableId });
  const panelRef = useRef(null);

  useEffect(() => {
    const resize = () => {
      if (!panelRef.current) {
        return;
      }

      setPanelWidth(panelRef.current.offsetWidth);
    };
    resize();
    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [setPanelWidth]);

  return (
    <Box ref={panelRef} className={classes.root} sx={{ mr: !!coordinates ? 0 : "-50%" }}>
      <Box className={classes.header}>
        <Typography className={classes.title}>{events?.length ?? 0} Active Coverages</Typography>
      </Box>
      <Box sx={{ overflowY: "auto", maxHeight: `calc(100vh - 134px)` }}>
        <EventTimeLine
          events={events ?? []}
          coordinates={coordinates}
          zoom={zoom}
          bounds={bounds}
          variablesSelected={variablesSelected}
        />
      </Box>
    </Box>
  );
};
