import { defaultMemoize } from "reselect";
import { default as OlGeoJSONFormat } from "ol/format/GeoJSON";

import { getUserPlaceColorName } from "../config";
import { newId } from "../util/id";
import { isString } from "../util/types";

export const USER_ID_PREFIX = "user-";

// The ID of the user place group that hold all drawn places.
// This place group will always exist.
export const USER_DRAWN_PLACE_GROUP_ID = USER_ID_PREFIX + "drawing";


export function newUserPlaceGroup(title, places){
  return {
    type: "FeatureCollection",
    features: places,
    id: newId(USER_ID_PREFIX),
    title,
  };
}

export function newUserPlace(
  geometry,
  properties,
) {
  return {
    type: "Feature",
    geometry: new OlGeoJSONFormat().writeGeometryObject(geometry),
    properties: properties,
    id: newId(USER_ID_PREFIX),
  };
}

export const DEFAULT_LABEL_PROPERTY_NAMES = mkCases([
  "label",
  "title",
  "name",
  "id",
]);
export const DEFAULT_DESCRIPTION_PROPERTY_NAMES = mkCases([
  "description",
  "desc",
  "abstract",
  "comment",
]);
export const DEFAULT_COLOR_PROPERTY_NAMES = mkCases(["color"]);
export const DEFAULT_OPACITY_PROPERTY_NAMES = mkCases(["opacity"]);
export const DEFAULT_IMAGE_PROPERTY_NAMES = mkCases([
  "image",
  "img",
  "picture",
  "pic",
]);

export function computePlaceInfo(
  placeGroup,
  place,
){
  const infoObj = {};
  updatePlaceInfo(
    infoObj,
    placeGroup,
    place,
    "label",
    place.id + "",
    DEFAULT_LABEL_PROPERTY_NAMES,
  );
  updatePlaceInfo(
    infoObj,
    placeGroup,
    place,
    "color",
    getUserPlaceColorName(getPlaceHash(place)),
    DEFAULT_COLOR_PROPERTY_NAMES,
  );
  updatePlaceInfo(
    infoObj,
    placeGroup,
    place,
    "opacity",
    0.2,
    DEFAULT_OPACITY_PROPERTY_NAMES,
  );
  updatePlaceInfo(
    infoObj,
    placeGroup,
    place,
    "image",
    null,
    DEFAULT_IMAGE_PROPERTY_NAMES,
  );
  updatePlaceInfo(
    infoObj,
    placeGroup,
    place,
    "description",
    null,
    DEFAULT_DESCRIPTION_PROPERTY_NAMES,
  );
  return { placeGroup, place, ...infoObj };
}

export const getPlaceInfo = defaultMemoize(computePlaceInfo);

function updatePlaceInfo(
  infoObj,
  placeGroup,
  place,
  propertyName,
  defaultValue,
  defaultPropertyNames,
) {
  let propertyValue;
  const mappedPropertyValue =
    placeGroup.propertyMapping && placeGroup.propertyMapping[propertyName];
  if (mappedPropertyValue) {
    if (mappedPropertyValue.includes("${")) {
      infoObj[propertyName] = getInterpolatedPropertyValue(
        place,
        mappedPropertyValue,
      );
      return;
    }
    if (
      defaultPropertyNames.length > 0 &&
      defaultPropertyNames[0] !== mappedPropertyValue
    ) {
      defaultPropertyNames = [mappedPropertyValue, ...defaultPropertyNames];
    }
  }
  if (place.properties) {
    propertyValue = getPropertyValue(place.properties, defaultPropertyNames);
  }
  if (propertyValue === undefined) {
    propertyValue = getPropertyValue(place, defaultPropertyNames);
  }
  infoObj[propertyName] = propertyValue || defaultValue;
}

function getInterpolatedPropertyValue(place, propertyValue) {
  let interpolatedValue = propertyValue;
  if (place.properties) {
    for (const name of Object.getOwnPropertyNames(place.properties)) {
      if (!interpolatedValue.includes("${")) {
        break;
      }
      const placeHolder = "${" + name + "}";
      if (interpolatedValue.includes(placeHolder)) {
        interpolatedValue = interpolatedValue.replace(
          placeHolder,
          `${place.properties[name]}`,
        );
      }
    }
  }
  return interpolatedValue;
}

function getPropertyValue(
  container,
  propertyNames,
) {
  let value;
  for (const propertyName of propertyNames) {
    if (propertyName in container) {
      return container[propertyName];
    }
  }
  // noinspection JSUnusedAssignment
  return value;
}

function mkCases(names) {
  let nameCases= [];
  for (const name of names) {
    nameCases = nameCases.concat(
      name.toLowerCase(),
      name.toUpperCase(),
      name[0].toUpperCase() + name.substring(1).toLowerCase(),
    );
  }
  return nameCases;
}

export function forEachPlace(
  placeGroups,
  callback,
) {
  placeGroups.forEach((placeGroup) => {
    if (isValidPlaceGroup(placeGroup)) {
      placeGroup.features.forEach((place) => {
        callback(placeGroup, place);
      });
    }
  });
}


export function findPlaceInfo(
  placeGroups,
  placeIdOrPredicate,
){
  const predicate = isString(placeIdOrPredicate)
    ? (_placeGroup, place) => place.id === placeIdOrPredicate
    : placeIdOrPredicate;
  for (const placeGroup of placeGroups) {
    if (isValidPlaceGroup(placeGroup)) {
      const place = placeGroup.features.find((place) =>
        predicate(placeGroup, place),
      );
      if (place) {
        return getPlaceInfo(placeGroup, place);
      }
    }
  }
  return null;
}

function getPlaceHash(place){
  const id = place.id + "";
  let hash = 0,
    i,
    c;
  if (id.length === 0) {
    return hash;
  }
  for (i = 0; i < id.length; i++) {
    c = id.charCodeAt(i);
    hash = (hash << 5) - hash + c;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function isValidPlaceGroup(placeGroup){
  return !!placeGroup.features;
}

export function findPlaceInPlaceGroup(
  placeGroup,
  placeId,
) {
  if (!placeId || !isValidPlaceGroup(placeGroup)) {
    return null;
  }
  const place = placeGroup.features.find((place) => place.id === placeId);
  if (place) {
    return place ;
  }
  const subPlaceGroups = placeGroup.placeGroups;
  if (subPlaceGroups) {
    for (const parentPlaceId in subPlaceGroups) {
      const place = findPlaceInPlaceGroup(
        subPlaceGroups[parentPlaceId],
        placeId,
      );
      if (place) {
        return place;
      }
    }
  }
  return null;
}

export function findPlaceInPlaceGroups(
  placeGroups,
  placeId,
){
  if (placeId) {
    for (const placeGroup of placeGroups) {
      const place = findPlaceInPlaceGroup(placeGroup, placeId);
      if (place !== null) {
        return place;
      }
    }
  }
  return null;
}
