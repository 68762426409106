import { useQuery } from "@tanstack/react-query";
import Ajax, { GetToken } from "../../Util/ajax";

const QUERY_KEY = "QUERY_FEATURES";

export const useFeatures = (props) => {
  return useQuery({
    queryKey: [QUERY_KEY, props],
    queryFn: async () => {
      if (!props) {
        return null;
      }

      const { topLeftLat, topLeftLon, bottomRightLat, bottomRightLon } = props;

      const token = await GetToken();
      const url = new URL(`${window.appConfig.apiUrl}/internal/features`);

      url.searchParams.set("topLeftLat", topLeftLat);
      url.searchParams.set("topLeftLon", topLeftLon);
      url.searchParams.set("bottomRightLat", bottomRightLat);
      url.searchParams.set("bottomRightLon", bottomRightLon);

      const response = await Ajax.getData(url.href, token);

      return response;
    },
    enabled: !!props,
    refetchOnWindowFocus: false,
  });
};
