import Check from "@mui/icons-material/Check";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

export default function SelectableMenuItem({
  title,
  subtitle,
  disabled,
  dense,
  selected,
  secondaryIcon,
  onClick,
}) {
  return selected ? (
    <MenuItem onClick={onClick} disabled={disabled} dense={dense}>
      <ListItemIcon>
        <Check />
      </ListItemIcon>
      <ListItemText primary={title} secondary={subtitle} />
      {secondaryIcon}
    </MenuItem>
  ) : (
    <MenuItem onClick={onClick} disabled={disabled} dense={dense}>
      <ListItemText inset primary={title} secondary={subtitle} />
      {secondaryIcon}
    </MenuItem>
  );
}
