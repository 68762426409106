
import {
  callJsonApi,
  makeRequestInit,
  makeRequestUrl,
} from "./callApi";
import { encodeDatasetId, encodeVariableName } from "../model/encode";

export function getTimeSeriesForGeometry(
  apiServerUrl,
  dataset,
  variable,
  placeId,
  geometry,
  startDate,
  endDate,
  useMedian,
  includeStdev,
  accessToken,
){
  let valueDataKey;
  let errorDataKey= null;
  const query= [];
  if (useMedian) {
    query.push(["aggMethods", "median"]);
    valueDataKey = "median";
  } else if (includeStdev) {
    query.push(["aggMethods", "mean,std"]);
    valueDataKey = "mean";
    errorDataKey = "std";
  } else {
    query.push(["aggMethods", "mean"]);
    valueDataKey = "mean";
  }
  if (startDate) {
    query.push(["startDate", startDate]);
  }
  if (endDate) {
    query.push(["endDate", endDate]);
  }
  const url = makeRequestUrl(
    `${apiServerUrl}/timeseries/${encodeDatasetId(dataset)}/${encodeVariableName(variable)}`,
    query,
  );

  const init = {
    ...makeRequestInit(accessToken),
    method: "post",
    body: JSON.stringify(geometry),
  };

  const convertTimeSeriesResult = (
    result_,
  ) => {
    const rawPoints = result_.result;
    if (!rawPoints || rawPoints.length === 0) {
      return null;
    }
    const points = rawPoints.map((rawPoint) => {
      return { ...rawPoint, time: new Date(rawPoint.time).getTime() };
    });
    const source = {
      datasetId: dataset.id,
      datasetTitle: dataset.title,
      variableName: variable.name,
      variableUnits: variable.units || undefined,
      placeId,
      geometry,
      valueDataKey,
      errorDataKey,
    };
    return { source, data: points };
  };

  return callJsonApi(url, init, convertTimeSeriesResult);
}
