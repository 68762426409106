import { useQuery } from "@tanstack/react-query";
import Ajax, { GetToken } from "../../Util/ajax";

export const BUSINESS_PARTNER_BY_ID_QUERY_KEY = "BUSINESS_PARTNER_BY_ID";

export const useBusinessPartnerById = (id, { enabled = true } = {}) => {
  if (!id) {
    throw new Error("Missing id for useBusinessPartnerById");
  }

  return useQuery({
    queryKey: [BUSINESS_PARTNER_BY_ID_QUERY_KEY, id],
    queryFn: async () => {
      const token = await GetToken();
      const URL = `${window.appConfig.apiUrl}/internal/business-partners/${id}`;
      const response = await Ajax.getData(URL, token);

      return response;
    },
    refetchOnWindowFocus: false,
    enabled,
  });
};
