

import * as React from "react";
import TextField from "@mui/material/TextField";

import i18n from "../../i18n";

// noinspection JSUnusedLocalSymbols
const defaults = {
  parse: (text) => {
    return text;
  },
  format: (value) => {
    return typeof value === "string" ? value : `${value}`;
  },
  validate: (_value)=> {
    return true;
  },
};


function OptionsTextField() {
  return (props) => {
    const {
      options,
      updateOptions,
      optionKey,
      label,
      style,
      className,
      disabled,
      parse,
      format,
      validate,
    } = props;
    const value= options[optionKey];
    const handleChange = (e) => {
      const text = e.target.value;
      const value = (parse || defaults.parse)(text);
      updateOptions({ [optionKey]: value } );
    };
    return (
      <TextField
        label={i18n.get(label)}
        value={(format || defaults.format)(value)}
        error={!(validate || defaults.validate)(value)}
        onChange={handleChange}
        style={style}
        className={className}
        disabled={disabled}
        size="small"
        variant="standard"
      />
    );
  };
}

export default OptionsTextField;
