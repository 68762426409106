
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import i18n from "../../i18n";
import UserLayersPanel from "./UserLayersPanel";

const UserLayersDialog= ({
  dialogId,
  open,
  closeDialog,
  settings,
  updateSettings,
}) => {
  const [tabIndex, setTabIndex] = React.useState(
    dialogId === "userBaseMaps" ? 0 : 1,
  );

  if (!open) {
    return null;
  }

  const baseMaps = settings.userBaseMaps;
  const setBaseMaps = (userBaseMaps) => {
    updateSettings({ userBaseMaps });
  };

  const overlays = settings.userOverlays;
  const setOverlays = (userOverlays) => {
    updateSettings({ userOverlays });
  };

  const selectedBaseMapId = settings.selectedBaseMapId;
  const setSelectedBaseMapId = (selectedBaseMapId) => {
    updateSettings({ selectedBaseMapId });
  };

  const selectedOverlayId = settings.selectedOverlayId;
  const setSelectedOverlayId = (selectedOverlayId) => {
    updateSettings({ selectedOverlayId });
  };

  function handleCloseDialog() {
    closeDialog(dialogId);
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={"sm"}
      onClose={handleCloseDialog}
      scroll="body"
    >
      <DialogTitle>{i18n.get("User Layers")}</DialogTitle>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabIndex} onChange={(_, index) => setTabIndex(index)}>
            <Tab label="Base Maps" />
            <Tab label="Overlays" />
          </Tabs>
        </Box>
        {tabIndex === 0 && (
          <UserLayersPanel
            key="baseMaps"
            userLayers={baseMaps}
            setUserLayers={setBaseMaps}
            selectedId={selectedBaseMapId}
            setSelectedId={setSelectedBaseMapId}
          />
        )}
        {tabIndex === 1 && (
          <UserLayersPanel
            key="overlays"
            userLayers={overlays}
            setUserLayers={setOverlays}
            selectedId={selectedOverlayId}
            setSelectedId={setSelectedOverlayId}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UserLayersDialog;
