import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import makeStyles from '@mui/styles/makeStyles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

import getSymbolFromCurrency from 'currency-symbol-map';
import Format from '../../../../Util/format';
import dayjs from 'dayjs';
import formatMissingValue from "../../../../Util/formatMissingValue";

const QuoteNewCustomizationStack = (props) => {
  const {
    id,
    payoutValue,
    numberOfInsured,
    sumInsuredIndividual,
    sumInsured,
    onNameChange,
    onSumInsuredIndividualChange,
    onPremiumInsuredNumberChange,
    coverage
  } = props;

  const { getValues } = useFormContext();
  const [wasEditedName, setWasEditedName] = useState(false);
  const [wasEditedSumInsured, setWasEditedSumInsured] = useState(false);
  
  const currency = useWatch({
    name: 'currency',
    exact: true,
  });

  const insuredUnit = getValues('insuredUnit');
  
  const useStyles = makeStyles((theme) => ({
    stack: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
    },
    row: {
      margin: theme.spacing(-1),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: 60,
    },
    spread: {
      padding: theme.spacing(1),
    },
    spreadAside: {
      padding: "17px 40px 17px 16px",
      maxWidth: 206,
    },
    coverageTitle: {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: "25px",
      color: "#252733",
    },
    coverageDetails: {
      fontSize: 18,
      fontStyle: "italic",
      fontWeight: 400,
      marginTop: 5,
      lineHeight: "25px",
      color: "#252733",
    },
    coveragePeriod: {
      fontSize: 16,
      fontWeight: 400,
      whiteSpace: "nowrap",
      lineHeight: "21px",
      marginTop: 8
    },
  }));

  const classes = useStyles();

  return (
    <Box className={classes.stack}>
      <Box mb={4} gap={3} display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="body1" className={classes.coverageTitle}>
            {coverage.indexDefinition.output.name}
          </Typography>
          <Typography variant="body1" className={classes.coverageDetails}>
            {coverage.label}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1" component="p" className={classes.coverageDetails}>
            {formatMissingValue(
              parseInt(coverage.trigger * 100) / 100 +
                " " +
                coverage.triggerUnit +
                " pays " +
                parseInt(coverage.minPayout.value * 100) / 100 +
                " %" +
                (coverage.payoutPerUnit?.amount
                  ? ", then " +
                    parseInt(coverage.payoutPerUnit?.amount * 100) / 100 +
                    "% per " +
                    coverage.triggerUnit
                  : ""),
            )}
          </Typography>
          <Typography component="p" className={classes.coveragePeriod}>
            {dayjs(coverage.start).format(`DD-MMM'YY`)} to {dayjs(coverage.end).format(`DD-MMM'YY`)}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.row}>
        <Box className={classes.spread}>
          <Typography>Name</Typography>
        </Box>
        <Box className={classes.spreadAside}>
          <OutlinedInput
            onChange={(event) => {
              onNameChange(event, { id: id });
              setWasEditedName(true);
            }}
            value={coverage.indexDefinition.output.name}
            placeholder="i.e. Landline Bangkok"
            id="name"
            name="name"
            variant="outlined"
            autoFocus={true}
            error={coverage.indexDefinition.output.name.length < 3 && wasEditedName}
          />
        </Box>
      </Box>
      <Box className={classes.row}>
        <Box className={classes.spread}>
          <Typography>Insured Extent</Typography>
        </Box>
        <Box className={classes.spreadAside}>
          <OutlinedInput
            onChange={(event) => {
              setWasEditedSumInsured(true);
              onPremiumInsuredNumberChange(event, {
                id: id,
              });
            }}
            placeholder="i.e. 10,000"
            id="number-of-insured"
            name="numberOfInsured"
            variant="outlined"
            autoFocus={true}
            error={
              isNaN(numberOfInsured) && numberOfInsured <= 0 && wasEditedSumInsured ? true : false
            }
            value={numberOfInsured}
            endAdornment={
              <InputAdornment position="end">{insuredUnit}</InputAdornment>
            }
          />
        </Box>
      </Box>
      <Box className={classes.row}>
        <Box className={classes.spread}>
          <Typography>Sum Insured per {insuredUnit}</Typography>
        </Box>
        <Box className={classes.spreadAside}>
          <OutlinedInput
            onChange={(event) => {
              onSumInsuredIndividualChange(event, {
                id: id,
              });
            }}
            value={sumInsuredIndividual}
            placeholder="i.e. 200$"
            id="sum-insured-individual"
            name="sumInsuredIndividual"
            variant="outlined"
            autoFocus={true}
            endAdornment={
              <InputAdornment position="end">{getSymbolFromCurrency(currency)}</InputAdornment>
            }
            error={false}
          />
        </Box>
      </Box>
      <Box className={classes.row}>
        <Box className={classes.spread}>
          <Typography>Payout</Typography>
        </Box>
        <Box className={classes.spread}>
          <Typography>
            ({payoutValue}%){' '}
            {Format.currency(
              numberOfInsured * sumInsuredIndividual * (payoutValue * 0.01),
              currency,
            )}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.row}>
        <Box className={classes.spread}>
          <Typography>Sum Insured per coverage</Typography>
        </Box>
        <Box className={classes.spread}>
          <Typography>{Format.currency(sumInsured, currency)}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default QuoteNewCustomizationStack;
