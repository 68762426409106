import React, { useState } from 'react';
import Policies from './Policies';
import { usePolicies, usePolicySummary } from '../../hooks/policy';

const PoliciesContainer = () => {
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 15,
  });
  const { data: policies, isLoading: policiesLoading } = usePolicies({
    page: pagination.page,
    size: pagination.rowsPerPage,
  });
  const { data: policySummary, isLoading: policySummaryLoading } = usePolicySummary();

  const handlePageChange = (_, value) => {
    setPagination((prevState) => ({ ...prevState, page: value }));
  };

  const loaded = !policiesLoading && !policySummaryLoading;

  return (
    <Policies
      loaded={loaded}
      policies={policies?.content ?? []}
      policySummary={policySummary}
      page={pagination.page}
      rowsPerPage={pagination.rowsPerPage}
      totalElements={policies?.totalElements ?? 0}
      totalPages={policies?.totalPages ?? 0}
      onChangePage={handlePageChange}
    />
  );
};


export default PoliciesContainer;
