import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import formatMissingValue from '../../Util/formatMissingValue';
import { ButtonNav } from '../Buttons/Buttons';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link as RouterLink, useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 12,
    boxShadow: '0px 3px 12px 0px #0000001F',
    padding: theme.spacing(2.5),
    backgroundColor: 'white',
    flex: 1,
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
  },
  tableContainer: {
    maxWidth: '100vw',
    overflowX: 'auto',
    '& .MuiButtonBase-root': {
      padding: 0,
    },
  },
  tableHead: {
    backgroundColor: theme.palette.stripe,
    textTransform: 'uppercase',
  },
}));

export const PoliciesReportsTable = ({ policies }) => {
  const classes = useStyles();
  const columns = ["Policy Holder", "Beneficiary", "Payout", "Insured Extend", "Sum Insured", ""];
  const location = useLocation();

  const headerCells = columns.map((col, index) => {
    return (
      <TableCell className={classes.tableHeadCell} key={index} colSpan={index === columns.length - 1 ? 2 : 1}>
        { col }
      </TableCell>
    );
  });

  const tableCells = (policies ?? []).map((row) => (
    <TableRow key={row.id}>
      <TableCell component="td" scope="row">
        {formatMissingValue(row.policyHolderName)}
      </TableCell>
      <TableCell component="td" scope="row">
        {formatMissingValue(row.beneficiaryName)}
      </TableCell>
      <TableCell component="td" scope="row">
        {row.calculatedPayout
          ? `${row.calculatedPayout.currency} ${Number(
              row.calculatedPayout.amount.toFixed(2),
            ).toLocaleString()}`
          : formatMissingValue()}
      </TableCell>
      <TableCell component="td" scope="row">
        {formatMissingValue(row.quantity)}
      </TableCell>
      <TableCell component="td" scope="row">
        {row.coverageTotalSumInsured
          ? `${row.coverageTotalSumInsured.currency} ${Number(
              row.coverageTotalSumInsured.amount.toFixed(2),
            ).toLocaleString()}`
          : formatMissingValue()}
      </TableCell>
      <TableCell component="td" scope="row">
        <ButtonNav
          to={`/policies/policy/${row.policyId}?backTo=${location.pathname}`}
          component={RouterLink}
        >
          <ArrowForwardIcon className={classes.arrowIcon} />
        </ButtonNav>
      </TableCell>
    </TableRow>
  ));

  const table = (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table aria-label="Coverages table" size="medium">
          <TableHead className={ classes.tableHead }>
            <TableRow>
              { headerCells }
            </TableRow>
          </TableHead>
          <TableBody>
            { tableCells }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>Policies Reports</Typography>
      <Box sx={{mt: 1}}>
        {table}
      </Box>
    </Box>
  );
}

