import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 4,
    border: '1px solid #00000026',
    overflow: 'hidden',
    height: '100%',
  },
  iconContainer: {
    height: 222,
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1.5),
  },
  icon: {
    fontSize: 80,
  },
  content: {
    padding: theme.spacing(2.5),
    background: '#F6F9FD',
    flex: 1,
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: '#1E1E1E',
  },
  description: {
    fontSize: 14,
    fontWeight: 400,
    color: '#000000DE',
  },
  btnWrapper: {
    minHeight: 50,
    flex: 1,
    alignItems: 'flex-end',
  },
  btn: {
    padding: 0,
    fontSize: 12,
    fontWeight: 700,
    color: '#1A1A21',
  },
}));

export const ProgramCard = (props) => {
  const classes = useStyles();
  
  const {
    id,
    Icon,
    Name,
    Description,
    InsuredValue,
    Country,
    Currency,
    SumInsuredBasis,
    InsuredUnit,
    DefaultSIperUnit,
  } = props;
  
  return (
    <Grid xs={2} sm={4} md={6} lg={4}>
      <Box className={classes.root}>
        <Stack className={classes.iconContainer}>
          <i className={clsx(clsx('material-icons', Icon), classes.icon)}>{Icon}</i>
        </Stack>
        <Stack className={classes.content}>
          <Typography className={classes.title}>{Name ?? '---'}</Typography>
          <Typography className={classes.description} sx={{mt: 1.25}}>{Description ?? '---'}</Typography>
          <Grid container rowSpacing={{ xs: 1 }} sx={{mt: 1}}>
            <Grid sm={12} md={6}>
              <Typography className={classes.description}>{InsuredValue ?? '---'}</Typography>
            </Grid>
            <Grid sm={12} md={6}>
              <Typography className={classes.description}>{Country ?? '---'}</Typography>
            </Grid>
            <Grid sm={12} md={6}>
              <Typography className={classes.description}>{Currency ?? '---'}</Typography>
            </Grid>
            <Grid sm={12} md={6}>
              <Typography className={classes.description}>{SumInsuredBasis ?? '---'}</Typography>
            </Grid>
            <Grid sm={12} md={6}>
              <Typography className={classes.description}>{InsuredUnit ?? '---'}</Typography>
            </Grid>
            <Grid sm={12} md={6}>
              <Typography className={classes.description}>{DefaultSIperUnit ?? '---'}</Typography>
            </Grid>
          </Grid>
          <Stack direction="row" gap={5} className={classes.btnWrapper}>
            <Button component={RouterLink} to={`/programs/${id}`} className={classes.btn}>View details</Button>
            <Button component={RouterLink} to={`/quotations/new?program=${id}`} className={classes.btn}>Start quote</Button>
          </Stack>
        </Stack>
      </Box>
    </Grid>
  );
};
