import { default as OlView } from "ol/View";
import { default as OlVectorLayer } from "ol/layer/Vector";
import {
  transform as olTransform,
} from "ol/proj";

import  MapComponent from "./MapComponent";

export class View extends MapComponent {
  addMapObject(map){
    return this.updateView(map);
  }

  removeMapObject(_map, _object){}

  updateMapObject(map, _object){
    return this.updateView(map);
  }

  updateView(map){
    const newProjection = this.props.projection;
    let oldProjection = map.getView().getProjection();
    if (typeof newProjection === "string") {
      if (oldProjection) {
        oldProjection = oldProjection.getCode();
      }
    }
    if (newProjection && newProjection !== oldProjection) {
      const oldView = map.getView();
      const newView = new OlView({
        ...this.props,
        center: olTransform(
          oldView.getCenter() || [0, 0],
          oldProjection,
          newProjection,
        ),
        minZoom: oldView.getMinZoom(),
        zoom: oldView.getZoom(),
      });
      map.getLayers().forEach((layer) => {
        if (layer instanceof OlVectorLayer) {
          layer.getSource().forEachFeature((feature) => {
            feature.getGeometry()?.transform(oldProjection, newProjection);
          });
        }
      });
      map.setView(newView);
    } else {
      map.getView().setProperties(this.props);
    }
    return map.getView();
  }
}
