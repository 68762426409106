import { connect } from "react-redux";
import _TimeSlider from "../components/TimeSlider";
import { selectTime, selectTimeRange } from "../actions/controlActions";
import { selectedDatasetTimeDimensionSelector } from "../selectors/controlSelectors";

const mapStateToProps = (state) => {
  return {
    locale: state.controlState.locale,

    hasTimeDimension: !!selectedDatasetTimeDimensionSelector(state),
    selectedTime: state.controlState.selectedTime,
    selectedTimeRange: state.controlState.selectedTimeRange,
  };
};

const mapDispatchToProps = {
  selectTime,
  selectTimeRange,
};

const TimeSlider = connect(mapStateToProps, mapDispatchToProps)(_TimeSlider);
export default TimeSlider;
