import React, { useState } from "react";
import Partner from "./Partner";
import {
  useBusinessPartnerById,
} from "../../hooks/businessPartner";
import { useParams } from "react-router-dom";

const PartnerContainer = () => {
  const { id } = useParams();
  const { data = {}, isLoading } = useBusinessPartnerById(id);
  const [isEdit, setIsEdit] = useState(false);

  const partner = {
    name: data.name,
    role: data.role,
    contactName: data.contactName,
    email: data.contactEmailAddress,
    phone: data.contactPhoneNumber,
    address: data.address,
    taxStatus: data.taxStatus,
  };

  const handleEdit = () => {
    setIsEdit((prevState) => !prevState);
  };

  return (
    <Partner
      id={id}
      isLoading={isLoading}
      partner={partner}
      edit={isEdit}
      onEdit={handleEdit}
      setIsEdit={setIsEdit}
    />
  );
};

export default PartnerContainer;
