import { updateDatasets } from "./dataActions";

export const UPDATE_ACCESS_TOKEN = "UPDATE_ACCESS_TOKEN";


export function updateAccessToken(accessToken) {
  return (dispatch, getState) => {
    const prevAccessToken = getState().userAuthState.accessToken;
    if (prevAccessToken !== accessToken) {
      dispatch(_updateAccessToken(accessToken));
      if (accessToken === null || prevAccessToken === null) {
        dispatch(updateDatasets());
      }
    }
  };
}

function _updateAccessToken(accessToken){
  return { type: UPDATE_ACCESS_TOKEN, accessToken };
}

////////////////////////////////////////////////////////////////////////////////
