

import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { FormControlLabel } from "@mui/material";


const RadioSetting= ({
  propertyName,
  settings,
  updateSettings,
  options,
  disabled,
}) => {
  const handleChange = (
    _event,
    value,
  ) => {
    updateSettings({ ...settings, [propertyName]: value });
  };
  return (
    <RadioGroup row value={settings[propertyName]} onChange={handleChange}>
      {options.map(([label, value]) => (
        <FormControlLabel
          key={label}
          control={<Radio />}
          value={value}
          label={label}
          disabled={disabled}
        />
      ))}
    </RadioGroup>
  );
};

export default RadioSetting;
