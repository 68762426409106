

import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

import { UNIT } from "../../model/timeSeries";
import {
  utcTimeToIsoDateString,
  utcTimeToIsoDateTimeString,
} from "../../util/time";
import { makeStyles } from "../../util/styles";

const HOR_MARGIN = 5;

// noinspection JSUnusedLocalSymbols
const styles = makeStyles({
  container: (theme) => ({
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(HOR_MARGIN),
    marginRight: theme.spacing(HOR_MARGIN),
    width: `calc(100% - ${theme.spacing(3 * (HOR_MARGIN + 1))})`,
    height: "5em",
    display: "flex",
    alignItems: "flex-end",
  }),
});

export default function TimeRangeSlider({
  dataTimeRange,
  selectedTimeRange,
  selectTimeRange,
}) {
  const [selectedTimeRange_, setSelectedTimeRange_] =
    useState(selectedTimeRange);

  useEffect(() => {
    setSelectedTimeRange_(selectedTimeRange);
  }, [selectedTimeRange]);

  const handleChange = (_event, value) => {
    if (Array.isArray(value)) {
      setSelectedTimeRange_([value[0], value[1]]);
    }
  };

  const handleChangeCommitted = (
    _event,
    value,
  ) => {
    if (selectTimeRange && Array.isArray(value)) {
      selectTimeRange([value[0], value[1]]);
    }
  };

  function valueLabelFormat(value) {
    return utcTimeToIsoDateTimeString(value);
  }

  const dataTimeRangeValid = Array.isArray(dataTimeRange);
  if (!dataTimeRangeValid) {
    dataTimeRange = [Date.now() - 2 * UNIT.years, Date.now()];
  }

  const marks = [
    {
      value: dataTimeRange[0],
      label: utcTimeToIsoDateString(dataTimeRange[0]),
    },
    {
      value: dataTimeRange[1],
      label: utcTimeToIsoDateString(dataTimeRange[1]),
    },
  ];

  return (
    <Box sx={styles.container}>
      <Slider
        disabled={!dataTimeRangeValid}
        min={dataTimeRange[0]}
        max={dataTimeRange[1]}
        value={selectedTimeRange_}
        marks={marks}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        size="small"
        valueLabelDisplay="on"
        valueLabelFormat={valueLabelFormat}
      />
    </Box>
  );
}
