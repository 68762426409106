import abbreviate from "number-abbreviate";
import { formatIndianNumber } from "./formatIndianNumber";

export const formatNumber = (num, currency = "") => {
  if (currency.toUpperCase() === "INR") {
    return formatIndianNumber(Number(num));
  }

  return abbreviate(Number(num), 2);
};
