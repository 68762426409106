

import React from "react";
import { AuthProvider } from "react-oidc-context";
import { Config } from "../config";
import baseUrl from "../util/baseurl";
import ErrorBoundary from "./ErrorBoundary";


const AuthWrapper= ({
  children,
}) => {
  const authClient = Config.instance.authClient;
  if (!authClient) {
    return <>{children}</>;
  }

  const handleSigninCallback = (_user) => {
    console.info("handleSigninCallback:", _user);
    window.history.replaceState({}, document.title, window.location.pathname);
  };

  const handleRemoveUser = ()=> {
    console.info("handleRemoveUser");
    // go home after logout
    window.location.pathname = "/";
  };

  const redirectUri = baseUrl.href;

  return (
    <ErrorBoundary>
      <AuthProvider
        {...authClient}
        loadUserInfo={true}
        scope="openid email profile"
        automaticSilentRenew={true}
        redirect_uri={redirectUri}
        post_logout_redirect_uri={redirectUri}
        popup_post_logout_redirect_uri={redirectUri}
        onSigninCallback={handleSigninCallback}
        onRemoveUser={handleRemoveUser}
      >
        {children}
      </AuthProvider>
    </ErrorBoundary>
  );
};

export default AuthWrapper;
