import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const QUERY_KEY = "GEOAPIFY_REVERSE_GEOCODING_QUERY";

export const useReverseGeocoding = (props) => {
  return useQuery({
    queryKey: [QUERY_KEY, props],
    queryFn: async () => {
      if (!props.lat || !props.lon) {
        return null;
      }

      const url = new URL(`https://api.geoapify.com/v1/geocode/reverse`);

      url.searchParams.set("lat", props.lat);
      url.searchParams.set("lon", props.lon);
      url.searchParams.set("apiKey", window.appConfig.geoapify.api.key);
      const response = await axios.get(url.href);

      return response.data;
    },
    enabled: !!props,
  });
};
