import Box from "@mui/material/Box";

import ColorBarStyleEditor from "./ColorBarStyleEditor";
import ColorBarSelect from "./ColorBarSelect";
import { COLOR_BAR_ITEM_GAP, COLOR_BAR_BOX_MARGIN } from "./constants";
import { makeStyles } from "../../util/styles";

const styles = makeStyles({
  colorBarBox: (theme) => ({
    marginTop: theme.spacing(COLOR_BAR_BOX_MARGIN - 2 * COLOR_BAR_ITEM_GAP),
    marginLeft: theme.spacing(COLOR_BAR_BOX_MARGIN),
    marginRight: theme.spacing(COLOR_BAR_BOX_MARGIN),
    marginBottom: theme.spacing(COLOR_BAR_BOX_MARGIN),
  }),
});


export default function ColorBarColorEditor(props) {
  const {
    colorBars,
    userColorBars,
    addUserColorBar,
    removeUserColorBar,
    updateUserColorBar,
    updateUserColorBars,
    ...baseProps
  } = props;
  return (
    <Box sx={styles.colorBarBox}>
      <ColorBarStyleEditor {...baseProps} />
      <ColorBarSelect
        {...baseProps}
        colorBars={colorBars}
        userColorBars={userColorBars}
        addUserColorBar={addUserColorBar}
        removeUserColorBar={removeUserColorBar}
        updateUserColorBar={updateUserColorBar}
        updateUserColorBars={updateUserColorBars}
      />
    </Box>
  );
}
