import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Provider } from "react-redux";
import * as Redux from "redux";
import * as ReduxLogger from "redux-logger";
import thunk from "redux-thunk";
import {
  changeLocale,
  SET_VARIABLE_SPLIT_POS,
  updateUserColorBarsImageData,
} from "./actions/controlActions";
import { syncWithServer } from "./actions/dataActions";
import { appReducer } from "./reducers/appReducer";
import XCUBE from "./connected/App";
import { Config } from "./config"; 

const useStyles = makeStyles((theme) => ({
  mapContainer:{
    overflow: "hidden",
    marginTop:"15px",
    width:"100%",
  }
}));

const XCube = () => {
  const classes = useStyles();
  const [store, setStore] = useState(null);

  useEffect(() => {
    const initializeApp = async () => {
      try {
        await Config.load(); 


        const actionFilter = (_getState, action) =>
          action.type !== SET_VARIABLE_SPLIT_POS;

        const logger = ReduxLogger.createLogger({
          collapsed: true,
          diff: false,
          predicate: actionFilter,
        });
        const middlewares = Redux.applyMiddleware(thunk, logger );
        const initialStore = Redux.createStore(appReducer, middlewares);

        initialStore.dispatch(changeLocale(initialStore.getState().controlState.locale));
        initialStore.dispatch(updateUserColorBarsImageData() );
        if (initialStore.getState().controlState.privacyNoticeAccepted) {
          initialStore.dispatch(syncWithServer());
        }

        setStore(initialStore);
      } catch (error) {
        console.error("Failed to load configuration:", error);
      }
    };

    initializeApp();
  }, []);
  if (!store) {
    return <div>Loading...</div>;
  }

  return (
    <Provider store={store}>
      <div className={classes.mapContainer}>
        <XCUBE />
      </div>
    </Provider>
  );
};

export default XCube;
