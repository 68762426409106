
import { connect } from "react-redux";

import _LoadingDialog from "../components/LoadingDialog";
import { activityMessagesSelector } from "../selectors/controlSelectors";

const mapStateToProps = (state) => {
  return {
    locale: state.controlState.locale,
    messages: activityMessagesSelector(state),
  };
};

const mapDispatchToProps = {};

const LoadingDialog = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_LoadingDialog);
export default LoadingDialog;
