

function getTimezoneOffset(date){
  return date.getTimezoneOffset() * 60000;
}

export function localToUtcTime(local){
  return local.getTime() - getTimezoneOffset(local);
}

export function utcTimeToLocal(utcTime){
  const dateTime = new Date(utcTime);
  return new Date(dateTime.getTime() + getTimezoneOffset(dateTime));
}

export function utcTimeToIsoDateString(utcTime) {
  return new Date(utcTime).toISOString().substring(0, 10);
}

export function utcTimeToIsoDateTimeString(utcTime) {
  return isoDateTimeStringToLabel(new Date(utcTime).toISOString());
}

export function isoDateTimeStringToLabel(utcDateTimeString) {
  return utcDateTimeString.substring(0, 19).replace("T", " ");
}
