import { useQuery } from "@tanstack/react-query";
import Ajax, { GetToken } from "../../Util/ajax";

const QUERY_KEY = "MONITORING_VARIABLES";

export const useMonitoringVariables = () => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      const token = await GetToken();
      const url = new URL(`${window.appConfig.apiUrl}/internal/monitoring/variables`);
      const response = await Ajax.getData(url.href, token);

      return response;
    },
    refetchOnWindowFocus: false,
  });
};
