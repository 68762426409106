

import Box from "@mui/material/Box";

import { makeStyles } from "../../util/styles";
import useMapPointInfo from "./useMapPointInfo";
import MapPointInfoContent from "./MapPointInfoContent";

const styles = makeStyles({
  container: {
    position: "absolute",
    zIndex: 1000,
    backgroundColor: "#000000A0",
    color: "#fff",
    border: "1px solid #FFFFFF50",
    borderRadius: "4px",
    transform: "translateX(3%)",
    pointerEvents: "none",
  },
});

export default function MapPointInfoBox({
  enabled,
  serverUrl,
  dataset1,
  variable1,
  dataset2,
  variable2,
  time,
}) {
  const mapPointInfo = useMapPointInfo(
    enabled,
    serverUrl,
    dataset1,
    variable1,
    dataset2,
    variable2,
    time,
  );

  if (!mapPointInfo) {
    return null;
  }

  const { pixelX, pixelY } = mapPointInfo.location;

  return (
    <Box sx={{ ...styles.container, left: pixelX, top: pixelY }}>
      <MapPointInfoContent {...mapPointInfo} />
    </Box>
  );
}
