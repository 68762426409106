

import { Config } from "../config";
import { loadUserServers } from "./userSettings";

export function newDataState(){
  const extraUserServers = loadUserServers();
  const userServers = [{ ...Config.instance.server }];
  extraUserServers.forEach((extraUserServer) => {
    if (
      !userServers.find((userServer) => userServer.id === extraUserServer.id)
    ) {
      userServers.push(extraUserServer);
    }
  });
  return {
    serverInfo: null,
    expressionCapabilities: null,
    datasets: [],
    colorBars: null,
    statistics: { loading: false, records: [] },
    timeSeriesGroups: [],
    userPlaceGroups: [],
    userServers,
  };
}
