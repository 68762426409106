import { useQuery } from "@tanstack/react-query";
import Ajax, { GetToken } from "../../Util/ajax";

const QUERY_KEY = `POLICY_REPORT`;

export const usePolicyReport = (id) => {
  return useQuery({
    queryKey: [QUERY_KEY, id],
    queryFn: async () => {
      const token = await GetToken();
      const URL = `${window.appConfig.apiUrl}/internal/policies/${id}/report`;
      const response = await Ajax.getData(URL, token);

      return response;
    },
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
};
