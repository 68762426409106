const PHRASE_STYLE = "color:green;font-weight:bold;";
const LOCALE_STYLE = "color:blue;font-weight:bold;";

export class LanguageDictionary {
  _languages;
  _content;
  _locale;

  constructor(json) {
    const locales = Object.getOwnPropertyNames(json.languages);
    if (locales.findIndex((locale) => locale === "en") < 0) {
      throw new Error(
        `Internal error: locale "en" must be included in supported languages`,
      );
    }

    const content = {};
    json.dictionary.forEach((entry, index) => {
      locales.forEach((locale) => {
        if (!entry[locale]) {
          throw new Error(
            `Internal error: invalid entry at index ${index} in "./resources/lang.json":` +
              ` missing translation for locale: "${locale}": ${entry}`,
          );
        }
      });
      const phraseKey = getPhraseKey(entry["en"]);
      if (content[phraseKey]) {
        console.warn(`Translation already defined for "${entry["en"]}".`);
      }
      content[phraseKey] = entry;
    });

    this._languages = json.languages;
    this._content = content;
    this._locale = "en";
  }

  get languages(){
    return this._languages;
  }

  get locale() {
    return this._locale;
  }

  set locale(value) {
    const locales = Object.getOwnPropertyNames(this._languages);
    if (locales.findIndex((locale) => locale === value) < 0) {
      const baseValue = value.split("-")[0];
      if (locales.findIndex((locale) => locale === baseValue) < 0) {
        console.error(
          `No translations found for locale "${value}", staying with "${this._locale}".`,
        );
        return;
      } else {
        console.warn(
          `No translations found for locale "${value}", falling back to "${baseValue}".`,
        );
        value = baseValue;
      }
    }

    this._locale = value;
  }

  get(phrase, values){
    const key = getPhraseKey(phrase);
    const entry = this._content[key];
    let translatedPhrase;
    if (!entry) {
      console.debug(`missing translation for phrase %c${phrase}`, PHRASE_STYLE);
      translatedPhrase = phrase;
    } else {
      translatedPhrase = entry[this._locale];
      if (!translatedPhrase) {
        console.debug(
          `missing translation of phrase %c${phrase}`,
          PHRASE_STYLE,
          ` for locale %c${this._locale}`,
          LOCALE_STYLE,
        );
        translatedPhrase = phrase;
      }
    }
    if (values) {
      Object.keys(values).forEach((name) => {
        translatedPhrase = translatedPhrase.replace(
          "${" + name + "}",
          `${values[name]}`,
        );
      });
    }
    return translatedPhrase;
  }
}

export const getCurrentLocale = () => {
  let locale;
  if (navigator.languages && navigator.languages.length > 0) {
    locale = navigator.languages[0];
  } else {
    locale = (navigator.language ||
      (navigator).userLanguage ||
      (navigator).browserLanguage ||
      "en");
  }
  return locale.split("-")[0];
};

const getPhraseKey = (phrase) => phrase.toLowerCase();
