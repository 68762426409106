import { useEffect, useRef } from "react";

import i18n from "../../i18n";
import { renderColorBar } from "../../model/colorBar";
import Tooltip from "@mui/material/Tooltip";
import {
  COLOR_BAR_ITEM_HEIGHT,
  COLOR_BAR_ITEM_WIDTH,
} from "./constants";

const styles = {
  nominal: { cursor: "pointer" },
  error: { cursor: "pointer", border: "0.5px solid red" },
};


export default function ColorBarCanvas({
  colorBar,
  opacity,
  width,
  height,
  onClick,
}) {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas !== null) {
      renderColorBar(colorBar, opacity, canvas);
    }
  }, [colorBar, opacity]);

  const { baseName, imageData } = colorBar;
  const tooltipTitle = imageData
    ? baseName
    : i18n.get("Unknown color bar") + `: ${baseName}`;

  return (
    <Tooltip title={tooltipTitle}>
      <canvas
        ref={canvasRef}
        width={width || COLOR_BAR_ITEM_WIDTH}
        height={height || COLOR_BAR_ITEM_HEIGHT + 4}
        onClick={onClick}
        style={!imageData ? styles.error : styles.nominal}
      />
    </Tooltip>
  );
}
