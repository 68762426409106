import React from "react";
import Switch from "@mui/material/Switch";

const ToggleSetting= ({
  propertyName,
  settings,
  updateSettings,
  disabled,
}) => {
  return (
    <Switch
      checked={!!settings[propertyName]}
      onChange={() =>
        updateSettings({ ...settings, [propertyName]: !settings[propertyName] })
      }
      disabled={disabled}
    />
  );
};

export default ToggleSetting;
