export const getLabel = (text) => {
  if (!text) {
    return "";
  }

  return text
    .toLowerCase()
    .split("_")
    .map((item) => item[0].toUpperCase() + item.slice(1))
    .join(" ");
};
