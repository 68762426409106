
import { useState } from "react";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import i18n from "../i18n";
import EditableSelect from "./EditableSelect";
import PlaceStyleEditor from "../components/PlaceStyleEditor";

// noinspection JSUnusedLocalSymbols
const styles = {
  select: {
    minWidth: "5em",
  },
};

export default function PlaceSelect({
  selectPlace,
  placeLabels,
  selectedPlaceId,
  selectedPlaceGroupIds,
  selectedPlaceInfo,
  renameUserPlace,
  restyleUserPlace,
  removeUserPlace,
  places,
  locateSelectedPlace,
}) {
  const [editMode, setEditMode] = useState(false);
  const [styleAnchorEl, setStyleAnchorEl] = useState(null);

  places = places || [];
  placeLabels = placeLabels || [];
  selectedPlaceId = selectedPlaceId || "";
  selectedPlaceGroupIds = selectedPlaceGroupIds || [];

  const selectedPlaceGroupId =
    selectedPlaceGroupIds.length === 1 ? selectedPlaceGroupIds[0] : null;

  const placeIndex = places.findIndex((p) => p.id === selectedPlaceId);
  const placeName = placeIndex >= 0 ? placeLabels[placeIndex] : "";

  const setPlaceName = (placeName) => {
    renameUserPlace(selectedPlaceGroupId, selectedPlaceId, placeName);
  };

  const updatePlaceStyle = (placeStyle) => {
    restyleUserPlace(selectedPlaceGroupId, selectedPlaceId, placeStyle);
  };

  const handlePlaceChange = (event) => {
    selectPlace(event.target.value || null, places, true);
  };

  const select = (
    <Select
      variant="standard"
      value={selectedPlaceId}
      onChange={handlePlaceChange}
      input={<Input name="place" id="place-select" />}
      displayEmpty
      name="place"
      sx={styles.select}
      disabled={places.length === 0}
    >
      {places.map((place, i) => (
        <MenuItem
          key={place.id}
          value={place.id}
          selected={place.id === selectedPlaceId}
        >
          {placeLabels[i]}
        </MenuItem>
      ))}
    </Select>
  );

  return (
    <>
      <EditableSelect
        itemValue={placeName}
        setItemValue={setPlaceName}
        validateItemValue={(v) => v.trim().length > 0}
        editMode={editMode}
        setEditMode={setEditMode}
        labelText={i18n.get("Address")}
        select={select}
      />
      {selectedPlaceInfo && (
        <PlaceStyleEditor
          anchorEl={styleAnchorEl}
          setAnchorEl={setStyleAnchorEl}
          isPoint={selectedPlaceInfo.place.geometry.type === "Point"}
          placeStyle={selectedPlaceInfo}
          updatePlaceStyle={updatePlaceStyle}
        />
      )}
    </>
  );
}
