import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import DashboardCard from "./DashboardCard";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    lineHeight: 1.36,
  },
  cardValue: {
    fontWeight: 700,
    fontSize: 48,
  },
  cardTitle: {
    fontWeight: 700,
    fontSize: 20,
  },
  amountContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  amount: {
    fontSize: 18,
    fontWeight: 700,
    color: "inherit",
  },
  green: {
    color: "#31D158",
  },
  red: {
    color: "#F44336",
  },
}));

const DashboardOverviewCards = ({
  createdQuotesCount,
  acceptedQuotesPerc,
  policiesCount,
  quotesCount,
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        <DashboardCard>
          <Typography className={classes.cardValue}>
            {quotesCount?.toLocaleString("en-US")}
          </Typography>
          <Typography className={classes.cardTitle}>Quotes count</Typography>
        </DashboardCard>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <DashboardCard>
          <Typography className={classes.cardValue}>
            {acceptedQuotesPerc?.toLocaleString("en-US")}
            <Typography component="span" className={classes.amount}>
              %
            </Typography>
          </Typography>
          <Typography className={classes.cardTitle}>Accepted quotes</Typography>
        </DashboardCard>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <DashboardCard>
          <Typography className={classes.cardValue}>
            {createdQuotesCount?.toLocaleString("en-US")}
          </Typography>
          <Typography className={classes.cardTitle}>Quotes completed</Typography>
        </DashboardCard>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <DashboardCard>
          <Typography className={classes.cardValue}>
            {policiesCount?.toLocaleString("en-US")}
          </Typography>
          <Typography className={classes.cardTitle}>Policies count</Typography>
        </DashboardCard>
      </Grid>
    </Grid>
  );
};

export default DashboardOverviewCards;
