import { styled } from "@mui/system";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import { green, amber } from "@mui/material/colors";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const StyledSpan = styled("span")(() => ({
  display: "flex",
  alignItems: "center",
}));

const styles = {
  close: {
    p: 0.5,
  },
  success: (theme) => ({
    color: theme.palette.error.contrastText,
    backgroundColor: green[600],
  }),
  error: (theme) => ({
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.dark,
  }),
  info: (theme) => ({
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.primary.dark,
  }),
  warning: (theme) => ({
    color: theme.palette.error.contrastText,
    backgroundColor: amber[700],
  }),
  icon: {
    fontSize: 20,
  },
  iconVariant: (theme) => ({
    opacity: 0.9,
    marginRight: theme.spacing(1),
    fontSize: 20,
  }),
  message: {
    display: "flex",
    alignItems: "center",
  },
};


const SNACKBAR_ANCHOR_ORIGIN = {
  vertical: "bottom",
  horizontal: "center",
};

export default function MessageLog({
  className,
  message,
  hideMessage,
}) {
  const handleClose = () => {
    hideMessage(message.id);
  };

  if (!message) {
    return null;
  }

  const MessageIcon = variantIcon[message.type];

  return (
    <Snackbar
      key={message.type + ":" + message.text}
      open={true}
      anchorOrigin={SNACKBAR_ANCHOR_ORIGIN}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <SnackbarContent
        sx={styles[message.type]}
        className={className}
        aria-describedby="client-snackbar"
        message={
          <StyledSpan id="client-snackbar">
            <MessageIcon sx={styles.iconVariant} />
            {message.text}
          </StyledSpan>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            sx={styles.close}
            onClick={handleClose}
            size="large"
          >
            <CloseIcon sx={styles.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}
