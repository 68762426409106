import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Unstable_Grid2';
import clsx from 'clsx';
import Ajax from '../../Util/ajax';
import mockData from '../Quotes/QuoteNew/QuoteNewProgram/rw-programs-demo.json';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    backgroundColor: '#f7f8fc',
    flex: 1,
    padding: theme.spacing(2.5),
  },
  toolbox: {
    padding: `${theme.spacing(2.5)} ${theme.spacing(2)}`,
    border: '1px solid #EFEFEF',
    position: 'sticky',
    alignItems: 'center',
    top: 56,
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.appBar,
    [theme.breakpoints.up('md')]: {
      top: 64,
    },
  },
  title: {
    marginRight: theme.spacing(2),
    fontSize: 18,
    fontWeight: 700,
  },
  card: {
    borderRadius: 15,
    boxShadow: '0px 6px 16px 0px #7575751A',
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    backgroundColor: 'white',
    flex: 1,
  },
  btn: {
    marginLeft: 'auto',
    borderRadius: 8,
  },
  label: {
    fontSize: 18,
    fontWeight: 600,
    color: '#1E1E1E',
  },
  value: {
    fontSize: 18,
    fontWeight: 400,
    color: '#000000DE',
    marginTop: theme.spacing(1.25),
  },
  icon: {
    fontSize: 80,
  },
}));

const ProgramDetails = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [program, setProgram] = useState({});

  useEffect(() => {
    const getProgramData = () => {
      Ajax.getData(`${window.appConfig.apiUrl}/mock/programs/${id}`)
        .then((data) => {
          setProgram(data ?? {});
        })
        .catch((error) => {
          console.log('error', error);
          setProgram(mockData.find((item) => item.id === Number(id)) ?? {});
        });
    };

    getProgramData();
  }, [id]);

  const {
    Icon,
    Name,
    Description,
    InsuredValue,
    Country,
    Currency,
    InsuredUnit,
    SumInsuredBasis,
    DefaultSIperUnit,
  } = program ?? {};

  return (
    <Box className={classes.root}>
      <Toolbar />
      <Stack direction="row" gap={2} className={classes.toolbox}>
        <IconButton component={RouterLink} to="/programs">
          <ArrowBackIcon sx={{ color: '#1A1A21' }} />
        </IconButton>
        <Typography className={classes.title} variant="h6">
          Program Details
        </Typography>
        {/*<Button variant="contained" className={classes.btn} size="large">get quote</Button>*/}
      </Stack>
      <Box className={classes.main}>
        <Box className={classes.card}>
          <Grid container rowSpacing={{ xs: 1, sm: 2, md: 4 }} sx={{ my: 0 }}>
            <Grid sm={12}>
              <i className={clsx(clsx('material-icons', Icon), classes.icon)}>{Icon}</i>
            </Grid>
            <Grid sm={12} md={6}>
              <Typography className={classes.label}>Program Name</Typography>
              <Typography className={classes.value}>{Name ?? '---'}</Typography>
            </Grid>
            <Grid sm={12} md={6}>
              <Typography className={classes.label}>Industry</Typography>
              <Typography className={classes.value}>{InsuredValue ?? '---'}</Typography>
            </Grid>
            <Grid sm={12} md={6}>
              <Typography className={classes.label}>Currency</Typography>
              <Typography className={classes.value}>{Currency ?? '---'}</Typography>
            </Grid>
            <Grid sm={12} md={6}>
              <Typography className={classes.label}>Insured Unit</Typography>
              <Typography className={classes.value}>{InsuredUnit ?? '---'}</Typography>
            </Grid>
            <Grid sm={12} md={6}>
              <Typography className={classes.label}>Country, Region</Typography>
              <Typography className={classes.value}>{Country ?? '---'}</Typography>
            </Grid>
            <Grid sm={12} md={6}>
              <Typography className={classes.label}>Sum Insured Basis</Typography>
              <Typography className={classes.value}>{SumInsuredBasis ?? '---'}</Typography>
            </Grid>
            <Grid sm={12}>
              <Typography className={classes.label}>Default SI per Unit</Typography>
              <Typography className={classes.value}>{DefaultSIperUnit ?? '---'}</Typography>
            </Grid>
            <Grid sm={12}>
              <Typography className={classes.label}>Program Description</Typography>
              <Typography className={classes.value}>{Description ?? '---'}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default ProgramDetails;
