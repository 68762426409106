import Menu from "@mui/material/Menu";

import i18n from "../../i18n";
import SelectableMenuItem from "../../components/SelectableMenuItem";
import { exprPartKeys, exprPartLabels} from "./utils";

export default function ExprPartFilterMenu({
  anchorEl,
  exprPartTypes,
  setExprPartTypes,
  onClose,
}) {
  const handleExprPartTypeSelected = (key) => {
    setExprPartTypes({ ...exprPartTypes, [key]: !exprPartTypes[key] });
  };
  return (
    <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={onClose}>
      {exprPartKeys.map((key) => (
        <SelectableMenuItem
          key={key}
          selected={exprPartTypes[key]}
          title={i18n.get(exprPartLabels[key])}
          onClick={() => handleExprPartTypeSelected(key)}
          dense
        />
      ))}
    </Menu>
  );
}
