import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { ProgramCard } from './ProgramCard';
import Ajax from '../../Util/ajax';
import mockData from '../Quotes/QuoteNew/QuoteNewProgram/rw-programs-demo.json';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    backgroundColor: '#f7f8fc',
    flex: 1,
    padding: `${theme.spacing(3.5)} ${theme.spacing(3)}`,
  },
  toolbox: {
    padding: `${theme.spacing(2.5)} ${theme.spacing(2)}`,
    border: '1px solid #EFEFEF',
    position: 'sticky',
    top: 56,
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.appBar,
    [theme.breakpoints.up('md')]: {
      top: 64,
    },
  },
  title: {
    marginRight: theme.spacing(2),
    fontSize: 18,
    fontWeight: 700,
  },
  card: {
    borderRadius: 15,
    boxShadow: '0px 6px 16px 0px #7575751A',
    padding: theme.spacing(1),
    backgroundColor: 'white',
    flex: 1,
  },
}));

const Programs = () => {
  const classes = useStyles();
  const [programsData, setProgramsData] = useState([]);

  const getProgramsData = () => {
    Ajax.getData(`${window.appConfig.apiUrl}/mock/programs`)
      .then((data) => {
        setProgramsData(data ?? mockData);
      })
      .catch((error) => {
        console.log('error', error);
        setProgramsData(mockData);
      });
  };

  useEffect(() => {
    getProgramsData();
  }, []);

  return (
    <Box className={classes.root}>
      <Toolbar />
      <Box className={classes.toolbox}>
        <Typography className={classes.title} variant="h6">
          Programs
        </Typography>
      </Box>
      <Box className={classes.main}>
        <Grid
          container
          className={classes.card}
          rowSpacing={{ xs: 2, md: 2.5 }}
          columnSpacing={{ xs: 2, md: 2.75 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {programsData.map((card) => (
            <ProgramCard key={`program-card-${card.id}`} {...card} />
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Programs;
