

import { useRef, useState } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";

import useFetchText from "../hooks/useFetchText";
import MarkdownPopover from "../components/MarkdownPopover";

export default function HelpButton({ size, helpUrl }) {
  const [helpAnchorEl, setHelpAnchorEl] = useState(
    null,
  );
  const helpButtonRef = useRef(null);
  const helpText = useFetchText(helpUrl);

  const handleHelpOpen = () => {
    setHelpAnchorEl(helpButtonRef.current);
  };

  const handleHelpClose = () => {
    setHelpAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleHelpOpen} size={size} ref={helpButtonRef}>
        <HelpOutlineIcon fontSize="inherit" />
      </IconButton>
      <MarkdownPopover
        anchorEl={helpAnchorEl}
        open={!!helpAnchorEl}
        onClose={handleHelpClose}
        markdownText={helpText}
      />
    </>
  );
}
