import { connect } from "react-redux";
import _TimeSelect from "../components/TimeSelect";
import { selectTime } from "../actions/controlActions";
import { selectedDatasetTimeDimensionSelector } from "../selectors/controlSelectors";

const mapStateToProps = (state) => {
  return {
    locale: state.controlState.locale,

    hasTimeDimension: !!selectedDatasetTimeDimensionSelector(state),
    selectedTime: state.controlState.selectedTime,
    selectedTimeRange: state.controlState.selectedTimeRange,
  };
};

const mapDispatchToProps = {
  selectTime,
};

const TimeSelect = connect(mapStateToProps, mapDispatchToProps)(_TimeSelect);
export default TimeSelect;
