

import { connect } from "react-redux";
import _TimeSeriesPanel from "../components/TimeSeriesPanel";
import {
  removeTimeSeries,
  removeTimeSeriesGroup,
  addPlaceGroupTimeSeries,
  addTimeSeries,
} from "../actions/dataActions";
import {
  selectPlace,
  selectTime,
  selectTimeRange,
} from "../actions/controlActions";
import { postMessage } from "../actions/messageLogActions";
import {
  canAddTimeSeriesSelector,
  selectedDatasetTimeRangeSelector,
  selectedPlaceGroupPlacesSelector,
  timeSeriesPlaceInfosSelector,
} from "../selectors/controlSelectors";
import { placeGroupTimeSeriesSelector } from "../selectors/dataSelectors";

const mapStateToProps = (state) => {
  return {
    locale: state.controlState.locale,
    timeSeriesGroups: state.dataState.timeSeriesGroups,
    selectedTime: state.controlState.selectedTime,
    selectedTimeRange: state.controlState.selectedTimeRange,
    dataTimeRange: selectedDatasetTimeRangeSelector(state),
    chartTypeDefault: state.controlState.timeSeriesChartTypeDefault,
    includeStdev: state.controlState.timeSeriesIncludeStdev,
    placeInfos: timeSeriesPlaceInfosSelector(state),
    places: selectedPlaceGroupPlacesSelector(state),
    placeGroupTimeSeries: placeGroupTimeSeriesSelector(state),
    canAddTimeSeries: canAddTimeSeriesSelector(state),
  };
};

const mapDispatchToProps = {
  selectTime,
  selectTimeRange,
  removeTimeSeries,
  removeTimeSeriesGroup,
  selectPlace,
  addPlaceGroupTimeSeries,
  addTimeSeries,
  postMessage,
};

const TimeSeriesPanel = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_TimeSeriesPanel);
export default TimeSeriesPanel;
