import { useQuery } from "@tanstack/react-query";
import Ajax, { GetToken } from "../../Util/ajax";

const QUERY_KEY = "MONITORING_EVENT_DETAIL";

export const useMonitoringEventDetail = ({ id, page, size }, enabled = false) => {
  return useQuery({
    queryKey: [QUERY_KEY, id],
    queryFn: async () => {
      if (!id) {
        return null;
      }

      const token = await GetToken();
      const url = new URL(`${window.appConfig.apiUrl}/internal/monitoring/${id}/detail`);
      url.searchParams.set('page', page);
      url.searchParams.set('size', size);
      const response = await Ajax.getData(url.href, token);

      return response;
    },
    refetchOnWindowFocus: false,
    enabled: enabled || !!id,
  });
};
