import CompareIcon from "@mui/icons-material/Compare";
import LayersIcon from "@mui/icons-material/Layers";
import MessageIcon from "@mui/icons-material/Message";
import ClearAllIcon from '@mui/icons-material/ClearAll';

import i18n from "../i18n";
import MapButtonGroup from "../components/Viewer/MapButtonGroup";
import MapButton from "../components/Viewer/MapButton";

const GROUP_STYLE = {
  left: "0.5em",
  top: 65,
};

export default function MapControlActions({
  layerMenuOpen,
  setLayerMenuOpen,

  variableCompareMode,
  setVariableCompareMode,

  mapPointInfoBoxEnabled,
  setMapPointInfoBoxEnabled,

  topBarEnabled,
  setTopbarEnabled,
}) {
  return (
    <MapButtonGroup style={GROUP_STYLE}>
      <MapButton
        icon={<LayersIcon fontSize={"small"} />}
        tooltipTitle={i18n.get("Show or hide layers panel")}
        selected={layerMenuOpen}
        onSelect={(_e, selected) => void setLayerMenuOpen(selected)}
      />
      <MapButton
        icon={<CompareIcon fontSize={"small"} />}
        tooltipTitle={i18n.get("Turn layer split mode on or off")}
        selected={variableCompareMode}
        onSelect={(_e, selected) => void setVariableCompareMode(selected)}
      />
      <MapButton
        icon={<MessageIcon fontSize={"small"} />}
        tooltipTitle={i18n.get("Turn info box on or off")}
        selected={mapPointInfoBoxEnabled}
        onSelect={(_e, selected) => void setMapPointInfoBoxEnabled(selected)}
      />

      <MapButton
        icon={<ClearAllIcon fontSize={"small"} />}
        tooltipTitle="Show or hide TopBar"
        selected={topBarEnabled}
        onSelect={(_e, selected) => void setTopbarEnabled(selected)}
      />
    </MapButtonGroup>
  );
}
