import { useMutation } from "@tanstack/react-query";
import Ajax, { GetToken } from "../../Util/ajax";

const MUTATION_KEY = "COVERAGES_MUTATION";

export const useCoverages = () => {
  return useMutation({
    mutationKey: [MUTATION_KEY],
    mutationFn: async ({ targetId, page = 0, size = 10000 }) => {
      if (!targetId) {
        return null;
      }

      const token = await GetToken();
      const url = new URL(`${window.appConfig.apiUrl}/internal/coverages`);

      url.searchParams.set("targetId", targetId);
      url.searchParams.set("page", page);
      url.searchParams.set("size", size);

      const response = await Ajax.getData(url.href, token);

      return response;
    },
  });
};
