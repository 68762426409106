export const MARKER = `
<svg width="26" height="37" viewBox="0 0 26 37" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_16087_80699)">
    <path d="M26 13C26 15.7861 24.0423 19.9488 21.6464 24C17.8681 30.3885 13 36.5 13 36.5C13 36.5 0 20.1797 0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13Z" fill="#D9503F" />
    <circle cx="13" cy="13" r="5" fill="#98221A" />
  </g>
  <defs>
    <clipPath id="clip0_16087_80699">
      <rect width="26" height="36.5" fill="white" />
    </clipPath>
  </defs>
</svg>
`;

export const CENTER_MARKER = `
<svg width="54" height="75" viewBox="0 0 54 75" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_16087_80684)">
    <g filter="url(#filter0_d_16087_80684)">
      <path d="M46 29.2329C46 33.3548 43.1388 39.5132 39.637 45.5068C34.115 54.9583 27 64 27 64C27 64 8 39.8549 8 29.2329C8 18.6109 16.5066 10 27 10C37.4934 10 46 18.6109 46 29.2329Z" fill="#FFE034" />
    </g>
    <ellipse cx="27" cy="29.5" rx="7" ry="7.5" fill="black" fill-opacity="0.54" />
  </g>
  <defs>
    <filter id="filter0_d_16087_80684" x="-0.4" y="1.6" width="54.8" height="70.8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset />
      <feGaussianBlur stdDeviation="4.2" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_16087_80684" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_16087_80684" result="shape" />
    </filter>
    <clipPath id="clip0_16087_80684">
      <rect width="54" height="75" fill="white" />
    </clipPath>
  </defs>
</svg>
`;
