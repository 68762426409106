import { useQuery } from "@tanstack/react-query";
import Ajax, { GetToken } from "../../Util/ajax";

const QUERY_KEY = "MONITORING_BOUNDING_BOX";

export const useMonitoringBoundingBox = (props) => {
  return useQuery({
    queryKey: [QUERY_KEY, props],
    queryFn: async () => {
      if (!props || !props.variableId) {
        return [];
      }

      const { topLeftLat, topLeftLon, bottomRightLat, bottomRightLon, variableId } = props;

      const token = await GetToken();
      const url = new URL(`${window.appConfig.apiUrl}/internal/monitoring/bounding-box`);

      url.searchParams.set("topLeftLat", topLeftLat);
      url.searchParams.set("topLeftLon", topLeftLon);
      url.searchParams.set("bottomRightLat", bottomRightLat);
      url.searchParams.set("bottomRightLon", bottomRightLon);
      if (variableId) {
        url.searchParams.set("variableId", variableId);
      }

      const response = await Ajax.getData(url.href, token);

      return response
        .map((item) => {
          try {
            return JSON.parse(item);
          } catch (error) {
            console.log(`Error when parse ${item}`);

            return null;
          }
        })
        .filter(Boolean);
    },
    refetchOnWindowFocus: false,
  });
};
