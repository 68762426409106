import { createSelector } from "reselect";
import memoize from "fast-memoize";
import { ImageTile as OlImageTile } from "ol";
import { default as OlGeoJSONFormat } from "ol/format/GeoJSON";
import { default as OlVectorSource } from "ol/source/Vector";
import { default as OlXYZSource } from "ol/source/XYZ";
import { default as OlTileWMSSource } from "ol/source/TileWMS";
import { default as OlCircle } from "ol/style/Circle";
import { default as OlFillStyle } from "ol/style/Fill";
import { default as OlStrokeStyle } from "ol/style/Stroke";
import { default as OlStyle } from "ol/style/Style";
import { default as OlTileGrid } from "ol/tilegrid/TileGrid";
import { transformExtent as olTransformExtent } from "ol/proj";

import { Config, getTileAccess, getUserPlaceFillOpacity } from "../config";
import { Layers } from "../components/ol/layer/Layers";
import { Tile } from "../components/ol/layer/Tile";
import { Vector } from "../components/ol/layer/Vector";

import {
  findDataset,
  findDatasetVariable,
  getDatasetTimeDimension,
  getDatasetTimeRange,
  getDatasetUserVariables,
} from "../model/dataset";
import {
  findPlaceInfo,
  forEachPlace,
  getPlaceInfo,
  isValidPlaceGroup,
} from "../model/place";
import { findIndexCloseTo } from "../util/find";
import {
  predefinedColorBarsSelector,
  datasetsSelector,
  statisticsRecordsSelector,
  timeSeriesGroupsSelector,
  userPlaceGroupsSelector,
  userServersSelector,
} from "./dataSelectors";
import { makeRequestUrl } from "../api/callApi";
import { MAP_OBJECTS } from "../states/controlState";
import { GEOGRAPHIC_CRS, WEB_MERCATOR_CRS } from "../model/proj";
import { parseColorBarName } from "../model/colorBar";
import {
  getUserColorBarHexRecords,
  USER_COLOR_BAR_GROUP_TITLE,
} from "../model/userColorBar";
import {
  defaultBaseMapLayers,
  defaultOverlayLayers,
  findLayer,
  getLayerTitle,
} from "../model/layerDefinition";
import { encodeDatasetId, encodeVariableName } from "../model/encode";

export const selectedDatasetIdSelector = (state) => state.controlState.selectedDatasetId;
export const selectedVariableNameSelector = (state) => state.controlState.selectedVariableName;
export const selectedDataset2IdSelector = (state) => state.controlState.selectedDataset2Id;
export const selectedVariable2NameSelector = (state) => state.controlState.selectedVariable2Name;
export const selectedPlaceGroupIdsSelector = (state) => state.controlState.selectedPlaceGroupIds;
export const selectedPlaceIdSelector = (state) => state.controlState.selectedPlaceId;
export const selectedTimeSelector = (state) => state.controlState.selectedTime;
export const selectedServerIdSelector = (state) => state.controlState.selectedServerId;
export const activitiesSelector = (state) => state.controlState.activities;
export const timeAnimationActiveSelector = (state) => state.controlState.timeAnimationActive;
export const imageSmoothingSelector = (state) => state.controlState.imageSmoothingEnabled;
export const userBaseMapsSelector = (state) => state.controlState.userBaseMaps;
export const userOverlaysSelector = (state) => state.controlState.userOverlays;
export const selectedBaseMapIdSelector = (state) => state.controlState.selectedBaseMapId;
export const selectedOverlayIdSelector = (state) => state.controlState.selectedOverlayId;
export const showBaseMapLayerSelector = (state) => !!state.controlState.layerVisibilities.baseMap;
export const showDatasetBoundaryLayerSelector = (state) =>
  !!state.controlState.layerVisibilities.datasetBoundary;
export const selectedVariableVisibilitySelector = (state) =>
  !!state.controlState.layerVisibilities.datasetVariable;
export const selectedVariable2VisibilitySelector = (state) =>
  !!state.controlState.layerVisibilities.datasetVariable2;
export const datasetRgbVisibilitySelector = (state) =>
  !!state.controlState.layerVisibilities.datasetRgb;
export const datasetRgb2VisibilitySelector = (state) =>
  !!state.controlState.layerVisibilities.datasetRgb2;
export const showDatasetPlacesLayerSelector = (state) =>
  !!state.controlState.layerVisibilities.datasetPlaces;
export const showUserPlacesLayerSelector = (state) =>
  !!state.controlState.layerVisibilities.userPlaces;
export const showOverlayLayerSelector = (state) => !!state.controlState.layerVisibilities.overlay;
export const layerVisibilitiesSelector = (state) => state.controlState.layerVisibilities;
export const infoCardElementStatesSelector = (state) => state.controlState.infoCardElementStates;
export const mapProjectionSelector = (state) => state.controlState.mapProjection;
export const timeChunkSizeSelector = (state) => state.controlState.timeChunkSize;
export const userPlacesFormatNameSelector = (state) => state.controlState.userPlacesFormatName;
export const userPlacesFormatOptionsCsvSelector = (state) =>
  state.controlState.userPlacesFormatOptions.csv;
export const userPlacesFormatOptionsGeoJsonSelector = (state) =>
  state.controlState.userPlacesFormatOptions.geojson;
export const userPlacesFormatOptionsWktSelector = (state) =>
  state.controlState.userPlacesFormatOptions.wkt;
export const userColorBarsSelector = (state) => state.controlState.userColorBars;
export const userVariablesAllowedSelector = (_state) => Config.instance.branding.allowUserVariables;

const variableLayerIdSelector = () => "variable";
const variable2LayerIdSelector = () => "variable2";
const datasetRgbLayerIdSelector = () => "rgb";
const datasetRgb2LayerIdSelector = () => "rgb2";

const variableZIndexSelector = () => 13;
const variable2ZIndexSelector = () => 12;
const datasetRgbZIndexSelector = () => 11;
const datasetRgb2ZIndexSelector = () => 10;

export const selectedDatasetSelector = createSelector(
  datasetsSelector,
  selectedDatasetIdSelector,
  findDataset,
);

export const selectedDataset2Selector = createSelector(
  datasetsSelector,
  selectedDataset2IdSelector,
  findDataset,
);

export const selectedVariablesSelector = createSelector(selectedDatasetSelector, (dataset) => {
  return (dataset && dataset.variables) || [];
});

export const selectedUserVariablesSelector = createSelector(selectedDatasetSelector, (dataset) => {
  return dataset ? getDatasetUserVariables(dataset)[1] : [];
});

const _findDatasetVariable = (dataset, varName) => {
  if (!dataset || !varName) {
    return null;
  }
  return findDatasetVariable(dataset, varName);
};

export const selectedVariableSelector = createSelector(
  selectedDatasetSelector,
  selectedVariableNameSelector,
  _findDatasetVariable,
);

export const selectedVariable2Selector = createSelector(
  selectedDataset2Selector,
  selectedVariable2NameSelector,
  _findDatasetVariable,
);

const getVariableTitle = (variable) => {
  return variable && (variable.title || variable.name);
};

export const selectedVariableTitleSelector = createSelector(
  selectedVariableSelector,
  getVariableTitle,
);

export const selectedVariable2TitleSelector = createSelector(
  selectedVariable2Selector,
  getVariableTitle,
);

const getVariableUnits = (variable) => {
  return (variable && variable.units) || "-";
};

export const selectedVariableUnitsSelector = createSelector(
  selectedVariableSelector,
  getVariableUnits,
);

export const selectedVariable2UnitsSelector = createSelector(
  selectedVariable2Selector,
  getVariableUnits,
);

const getVariableColorBarName = (variable) => {
  return (variable && variable.colorBarName) || "viridis";
};

export const selectedVariableColorBarNameSelector = createSelector(
  selectedVariableSelector,
  getVariableColorBarName,
);

export const selectedVariable2ColorBarNameSelector = createSelector(
  selectedVariable2Selector,
  getVariableColorBarName,
);

const getVariableColorBarMinMax = (variable) => {
  return variable ? [variable.colorBarMin, variable.colorBarMax] : [0, 1];
};

export const selectedVariableColorBarMinMaxSelector = createSelector(
  selectedVariableSelector,
  getVariableColorBarMinMax,
);

export const selectedVariable2ColorBarMinMaxSelector = createSelector(
  selectedVariable2Selector,
  getVariableColorBarMinMax,
);

const getVariableColorBarNorm = (variable) => {
  return (variable && variable.colorBarNorm) === "log" ? "log" : "lin";
};

export const selectedVariableColorBarNormSelector = createSelector(
  selectedVariableSelector,
  getVariableColorBarNorm,
);

export const selectedVariable2ColorBarNormSelector = createSelector(
  selectedVariable2Selector,
  getVariableColorBarNorm,
);

export const colorBarsSelector = createSelector(
  userColorBarsSelector,
  predefinedColorBarsSelector,
  (userColorBars, predefinedColorBars) => {
    const userGroup = {
      title: USER_COLOR_BAR_GROUP_TITLE,
      description: "User-defined color bars.",
      names: userColorBars.map((colorBar) => colorBar.id),
    };
    const userImages = {};
    userColorBars.forEach(({ id, imageData }) => {
      if (imageData) {
        userImages[id] = imageData;
      }
    });
    if (predefinedColorBars) {
      return {
        ...predefinedColorBars,
        groups: [userGroup, ...predefinedColorBars.groups],
        images: { ...predefinedColorBars.images, ...userImages },
      };
    } else {
      return { groups: [userGroup], images: userImages };
    }
  },
);

const getVariableColorBar = (colorBarName, colorBars, userColorBars) => {
  const colorBar = parseColorBarName(colorBarName);
  const imageData = colorBars.images[colorBar.baseName];
  const { baseName } = colorBar;
  const userColorBar = userColorBars.find((userColorBar) => userColorBar.id === baseName);
  if (userColorBar) {
    const type = userColorBar.type;
    const colorRecords = getUserColorBarHexRecords(userColorBar.code);
    return { ...colorBar, imageData, type, colorRecords };
  }
  return { ...colorBar, imageData };
};

export const selectedVariableColorBarSelector = createSelector(
  selectedVariableColorBarNameSelector,
  colorBarsSelector,
  userColorBarsSelector,
  getVariableColorBar,
);

export const selectedVariable2ColorBarSelector = createSelector(
  selectedVariable2ColorBarNameSelector,
  colorBarsSelector,
  userColorBarsSelector,
  getVariableColorBar,
);

const getVariableUserColorBarJson = (colorBar, colorBarName, userColorBars) => {
  const { baseName } = colorBar;
  const userColorBar = userColorBars.find((userColorBar) => userColorBar.id === baseName);
  if (userColorBar) {
    const colors = getUserColorBarHexRecords(userColorBar.code);
    if (colors) {
      return JSON.stringify({
        name: colorBarName,
        type: userColorBar.type,
        colors: colors.map((c) => [c.value, c.color]),
      });
    }
  }
  return null;
};

export const selectedVariableUserColorBarJsonSelector = createSelector(
  selectedVariableColorBarSelector,
  selectedVariableColorBarNameSelector,
  userColorBarsSelector,
  getVariableUserColorBarJson,
);

export const selectedVariable2UserColorBarJsonSelector = createSelector(
  selectedVariable2ColorBarSelector,
  selectedVariable2ColorBarNameSelector,
  userColorBarsSelector,
  getVariableUserColorBarJson,
);

const getVariableOpacity = (variable) => {
  if (!variable || typeof variable.opacity != "number") {
    return 1;
  }
  return variable.opacity;
};

export const selectedVariableOpacitySelector = createSelector(
  selectedVariableSelector,
  getVariableOpacity,
);

export const selectedVariable2OpacitySelector = createSelector(
  selectedVariable2Selector,
  getVariableOpacity,
);

export const selectedDatasetTimeRangeSelector = createSelector(
  selectedDatasetSelector,
  (dataset) => {
    return dataset !== null ? getDatasetTimeRange(dataset) : null;
  },
);

export const selectedDatasetRgbSchemaSelector = createSelector(
  selectedDatasetSelector,
  (dataset) => {
    return dataset !== null ? dataset.rgbSchema || null : null;
  },
);

export const selectedDataset2RgbSchemaSelector = createSelector(
  selectedDataset2Selector,
  (dataset) => {
    return dataset !== null ? dataset.rgbSchema || null : null;
  },
);

export const selectedDatasetPlaceGroupsSelector = createSelector(
  selectedDatasetSelector,
  (dataset) => {
    return (dataset && dataset.placeGroups) || [];
  },
);

export const selectedDatasetAndUserPlaceGroupsSelector = createSelector(
  selectedDatasetPlaceGroupsSelector,
  userPlaceGroupsSelector,
  (placeGroups, userPlaceGroups) => {
    return placeGroups.concat(userPlaceGroups);
  },
);

function selectPlaceGroups(placeGroups, placeGroupIds) {
  const selectedPlaceGroups = [];
  if (placeGroupIds !== null && placeGroupIds.length > 0) {
    placeGroups.forEach((placeGroup) => {
      if (placeGroupIds.indexOf(placeGroup.id) > -1) {
        selectedPlaceGroups.push(placeGroup);
      }
    });
  }
  return selectedPlaceGroups;
}

export const userPlaceGroupsVisibilitySelector = createSelector(
  userPlaceGroupsSelector,
  selectedPlaceGroupIdsSelector,
  showUserPlacesLayerSelector,
  (userPlaceGroups, selectedPlaceGroupIds) => {
    const visibility = {};
    const idSet = new Set(selectedPlaceGroupIds || []);
    userPlaceGroups.forEach((placeGroup) => {
      visibility[placeGroup.id] = idSet.has(placeGroup.id);
    });
    return visibility;
  },
);

export const selectedDatasetSelectedPlaceGroupsSelector = createSelector(
  selectedDatasetPlaceGroupsSelector,
  selectedPlaceGroupIdsSelector,
  selectPlaceGroups,
);

export const selectedPlaceGroupsSelector = createSelector(
  selectedDatasetAndUserPlaceGroupsSelector,
  selectedPlaceGroupIdsSelector,
  selectPlaceGroups,
);

export const selectedPlaceGroupsTitleSelector = createSelector(
  selectedPlaceGroupsSelector,
  (placeGroups) => {
    return placeGroups.map((placeGroup) => placeGroup.title || placeGroup.id).join(", ");
  },
);

export const selectedPlaceGroupPlacesSelector = createSelector(
  selectedPlaceGroupsSelector,
  (placeGroups) => {
    const args = placeGroups.map((placeGroup) =>
      isValidPlaceGroup(placeGroup) ? placeGroup.features : [],
    );
    return [].concat(...args);
  },
);

export const selectedPlaceSelector = createSelector(
  selectedPlaceGroupPlacesSelector,
  selectedPlaceIdSelector,
  (places, placeId) => {
    return places.find((place) => place.id === placeId) || null;
  },
);

export const selectedPlaceInfoSelector = createSelector(
  selectedPlaceGroupsSelector,
  selectedPlaceIdSelector,
  (placeGroups, placeId) => {
    if (placeGroups.length === 0 || placeId === null) {
      return null;
    }
    return findPlaceInfo(placeGroups, placeId);
  },
);

export const selectedVolumeIdSelector = createSelector(
  selectedDatasetIdSelector,
  selectedVariableNameSelector,
  selectedPlaceSelector,
  (datasetId, variableName, place) => {
    if (datasetId && variableName) {
      if (!place) {
        return `${datasetId}-${variableName}-all`;
      }
      if (place.geometry.type === "Polygon" || place.geometry.type === "MultiPolygon") {
        return `${datasetId}-${variableName}-${place.id}`;
      }
    }
    return null;
  },
);

export const canAddTimeSeriesSelector = createSelector(
  timeSeriesGroupsSelector,
  selectedDatasetIdSelector,
  selectedVariableNameSelector,
  selectedPlaceIdSelector,
  (timeSeriesGroups, datasetId, variableName, placeId) => {
    if (!datasetId || !variableName || !placeId) {
      return false;
    }
    for (const timeSeriesGroup of timeSeriesGroups) {
      for (const timeSeries of timeSeriesGroup.timeSeriesArray) {
        const source = timeSeries.source;
        if (
          source.datasetId === datasetId &&
          source.variableName === variableName &&
          source.placeId === placeId
        ) {
          return false;
        }
      }
    }
    return true;
  },
);

export const timeSeriesPlaceInfosSelector = createSelector(
  timeSeriesGroupsSelector,
  selectedDatasetAndUserPlaceGroupsSelector,
  (timeSeriesGroups, placeGroups) => {
    const placeInfos = {};
    forEachPlace(placeGroups, (placeGroup, place) => {
      for (const timeSeriesGroup of timeSeriesGroups) {
        if (timeSeriesGroup.timeSeriesArray.find((ts) => ts.source.placeId === place.id)) {
          placeInfos[place.id] = getPlaceInfo(placeGroup, place);
          break;
        }
      }
    });
    return placeInfos;
  },
);

export const canAddStatisticsSelector = createSelector(
  selectedDatasetIdSelector,
  selectedVariableNameSelector,
  selectedTimeSelector,
  selectedPlaceIdSelector,
  (selectedDatasetId, selectedVariableName, selectedTime, selectedPlaceId) => {
    return !!(selectedDatasetId && selectedVariableName && selectedTime && selectedPlaceId);
  },
);

export const resolvedStatisticsRecordsSelector = createSelector(
  statisticsRecordsSelector,
  selectedDatasetAndUserPlaceGroupsSelector,
  (statisticsRecords, placeGroups) => {
    const resolvedStatisticsRecords = [];
    statisticsRecords.forEach((statisticsRecord) => {
      const placeId = statisticsRecord.source.placeInfo.place.id;
      forEachPlace(placeGroups, (placeGroup, place) => {
        if (place.id === placeId) {
          const placeInfo = getPlaceInfo(placeGroup, place);
          resolvedStatisticsRecords.push({
            ...statisticsRecord,
            source: {
              ...statisticsRecord.source,
              placeInfo,
            },
          });
        }
      });
    });
    return resolvedStatisticsRecords;
  },
);

export const selectedPlaceGroupPlaceLabelsSelector = createSelector(
  selectedPlaceGroupsSelector,
  (placeGroups) => {
    const placeLabels = [];
    forEachPlace(placeGroups, (placeGroup, place) => {
      placeLabels.push(getPlaceInfo(placeGroup, place).label);
    });
    return placeLabels;
  },
);

export const selectedTimeChunkSizeSelector = createSelector(
  selectedVariableSelector,
  timeChunkSizeSelector,
  (variable, minTimeChunkSize) => {
    if (variable && variable.timeChunkSize) {
      const varTimeChunkSize = variable.timeChunkSize;
      return varTimeChunkSize * Math.ceil(minTimeChunkSize / varTimeChunkSize);
    }
    return minTimeChunkSize;
  },
);

const _getDatasetTimeDimension = (dataset) => {
  return (dataset && getDatasetTimeDimension(dataset)) || null;
};

export const selectedDatasetTimeDimensionSelector = createSelector(
  selectedDatasetSelector,
  _getDatasetTimeDimension,
);

export const selectedDataset2TimeDimensionSelector = createSelector(
  selectedDataset2Selector,
  _getDatasetTimeDimension,
);

const _getDatasetAttributions = (dataset) => {
  return (dataset && dataset.attributions) || null;
};

export const selectedDatasetAttributionsSelector = createSelector(
  selectedDatasetSelector,
  _getDatasetAttributions,
);

export const selectedDataset2AttributionsSelector = createSelector(
  selectedDataset2Selector,
  _getDatasetAttributions,
);

const _getTimeCoordinates = (timeDimension) => {
  if (timeDimension === null || timeDimension.coordinates.length === 0) {
    return null;
  }
  return timeDimension.coordinates;
};

export const selectedDatasetTimeCoordinatesSelector = createSelector(
  selectedDatasetTimeDimensionSelector,
  _getTimeCoordinates,
);

export const selectedDataset2TimeCoordinatesSelector = createSelector(
  selectedDatasetTimeDimensionSelector,
  _getTimeCoordinates,
);

const _getTimeIndex = (time, timeCoordinates) => {
  if (time === null || timeCoordinates === null) {
    return -1;
  }
  return findIndexCloseTo(timeCoordinates, time);
};

export const selectedDatasetTimeIndexSelector = createSelector(
  selectedTimeSelector,
  selectedDatasetTimeCoordinatesSelector,
  _getTimeIndex,
);

export const selectedDataset2TimeIndexSelector = createSelector(
  selectedTimeSelector,
  selectedDataset2TimeCoordinatesSelector,
  _getTimeIndex,
);

const _getTimeLabel = (time, timeIndex, timeDimension) => {
  if (time === null) {
    return null;
  }
  if (timeDimension && timeIndex > -1) {
    return timeDimension.labels[timeIndex];
  }
  return new Date(time).toISOString();
};

export const selectedDatasetTimeLabelSelector = createSelector(
  selectedTimeSelector,
  selectedDatasetTimeIndexSelector,
  selectedDatasetTimeDimensionSelector,
  _getTimeLabel,
);

export const selectedDataset2TimeLabelSelector = createSelector(
  selectedTimeSelector,
  selectedDataset2TimeIndexSelector,
  selectedDataset2TimeDimensionSelector,
  _getTimeLabel,
);

function getOlTileGrid(mapProjection, tileLevelMax) {
  if (mapProjection !== WEB_MERCATOR_CRS) {
    // If projection is not web mercator, it is geographical.
    // We need to define the geographical tile grid used by xcube:
    const numLevels = typeof tileLevelMax === "number" ? tileLevelMax + 1 : 20;
    return new OlTileGrid({
      tileSize: [256, 256],
      origin: [-180, 90],
      extent: [-180, -90, 180, 90],
      // Note, although correct, setting minZoom
      // will cause OpenLayers to crash:
      // minZoom: tileLevelMin,
      resolutions: Array.from({ length: numLevels }, (_, i) => 180 / 256 / Math.pow(2, i)),
    });
  }
}

function getOlXYZSource(
  url,
  mapProjection,
  tileGrid,
  attributions,
  timeAnimationActive,
  imageSmoothing,
  tileLoadFunction,
  _tileLevelMin,
  tileLevelMax,
) {
  return new OlXYZSource({
    url,
    projection: mapProjection,
    tileGrid,
    attributions: attributions || undefined,
    transition: timeAnimationActive ? 0 : 250,
    imageSmoothing: imageSmoothing,
    tileLoadFunction,
    // TODO (forman): if we provide minZoom, we also need to set
    //   tileGrid.extent, otherwise way to many tiles are loaded from
    //   level at minZoom when zooming out!
    // minZoom: tileLevelMin,
    maxZoom: tileLevelMax,
  });
}

function __getLoadTileOnlyAfterMove(map) {
  if (map) {
    // Define a special tileLoadFunction
    // that prevents tiles from being loaded while the user
    // pans or zooms, because this leads to high server loads.
    return (tile, src) => {
      if (tile instanceof OlImageTile) {
        if (map.getView().getInteracting()) {
          map.once("moveend", function () {
            tile.getImage().src = src;
          });
        } else {
          tile.getImage().src = src;
        }
      }
    };
  }
}

const _getLoadTileOnlyAfterMove = memoize(__getLoadTileOnlyAfterMove, {
  serializer: (args) => {
    const map = args[0];
    if (map) {
      const target = map.getTarget();
      if (typeof target === "string") {
        return target;
      } else if (target) {
        return target.id || "map";
      }
      return "map";
    }
    return "";
  },
});

function getLoadTileOnlyAfterMove() {
  const map = MAP_OBJECTS["map"];
  return _getLoadTileOnlyAfterMove(map);
}

function getTileLayer(
  layerId,
  tileUrl,
  extent,
  tileLevelMin,
  tileLevelMax,
  queryParams,
  opacity,
  timeLabel,
  timeAnimationActive,
  mapProjection,
  attributions,
  imageSmoothing,
  zIndex,
) {
  if (timeLabel !== null) {
    queryParams = [...queryParams, ["time", timeLabel]];
  }
  const url = makeRequestUrl(tileUrl, queryParams);
  if (typeof tileLevelMax === "number") {
    // It is ok to have some extra zoom levels, so we can magnify pixels.
    // Using more, artifacts will become visible.
    tileLevelMax += 3;
  }
  const tileGrid = getOlTileGrid(mapProjection, tileLevelMax);
  const source = getOlXYZSource(
    url,
    mapProjection,
    tileGrid,
    attributions,
    timeAnimationActive,
    imageSmoothing,
    getLoadTileOnlyAfterMove(),
    tileLevelMin,
    tileLevelMax,
  );
  const transformedExtent =
    mapProjection === GEOGRAPHIC_CRS
      ? extent
      : olTransformExtent(extent, "EPSG:4326", mapProjection);
  console.log("extent:", extent, transformedExtent);
  return (
    <Tile
      id={layerId}
      source={source}
      extent={transformedExtent}
      zIndex={zIndex}
      opacity={opacity}
    />
  );
}

export const selectedDatasetBoundaryLayerSelector = createSelector(
  selectedDatasetSelector,
  mapProjectionSelector,
  showDatasetBoundaryLayerSelector,
  (dataset, mapProjection, showDatasetBoundary) => {
    if (!dataset || !showDatasetBoundary) {
      return null;
    }

    let geometry = dataset.geometry;
    if (!geometry) {
      if (dataset.bbox) {
        const [x1, y1, x2, y2] = dataset.bbox;
        geometry = {
          type: "Polygon",
          coordinates: [
            [
              [x1, y1],
              [x2, y1],
              [x2, y2],
              [x1, y2],
              [x1, y1],
            ],
          ],
        };
      } else {
        console.warn(`Dataset ${dataset.id} has no bbox!`);
        return null;
      }
    }

    const source = new OlVectorSource({
      features: new OlGeoJSONFormat({
        dataProjection: GEOGRAPHIC_CRS,
        featureProjection: mapProjection,
      }).readFeatures({ type: "Feature", geometry }),
    });

    const style = new OlStyle({
      stroke: new OlStrokeStyle({
        color: "orange",
        width: 3,
        lineDash: [2, 4],
      }),
    });

    return (
      <Vector id={`${dataset.id}.bbox`} source={source} style={style} zIndex={16} opacity={0.5} />
    );
  },
);

export const selectedServerSelector = createSelector(
  userServersSelector,
  selectedServerIdSelector,
  (userServers, serverId) => {
    if (userServers.length === 0) {
      throw new Error(`internal error: no servers configured`);
    }
    const server = userServers.find((server) => server.id === serverId);
    if (!server) {
      throw new Error(`internal error: server with ID "${serverId}" not found`);
    }
    return server;
  },
);

const getVariableTileLayer = (
  server,
  dataset,
  timeLabel,
  attributions,
  variable,
  colorBarName,
  colorBarMinMax,
  colorBarNorm,
  colorBarJson,
  opacity,
  visibility,
  layerId,
  zIndex,
  timeAnimationActive,
  mapProjection,
  imageSmoothing,
) => {
  if (!dataset || !variable || !visibility) {
    return null;
  }
  const queryParams = [
    ["crs", mapProjection],
    ["vmin", `${colorBarMinMax[0]}`],
    ["vmax", `${colorBarMinMax[1]}`],
    ["cmap", colorBarJson ? colorBarJson : colorBarName],
    // ['retina', '1'],
  ];
  if (colorBarNorm === "log") {
    queryParams.push(["norm", colorBarNorm]);
  }
  return getTileLayer(
    layerId,
    getTileUrl(server.url, dataset, variable),
    dataset.bbox,
    variable.tileLevelMin,
    variable.tileLevelMax,
    queryParams,
    opacity,
    timeLabel,
    timeAnimationActive,
    mapProjection,
    attributions,
    imageSmoothing,
    zIndex,
  );
};

export const selectedDatasetVariableLayerSelector = createSelector(
  selectedServerSelector,
  selectedDatasetSelector,
  selectedDatasetTimeLabelSelector,
  selectedDatasetAttributionsSelector,
  selectedVariableSelector,
  selectedVariableColorBarNameSelector,
  selectedVariableColorBarMinMaxSelector,
  selectedVariableColorBarNormSelector,
  selectedVariableUserColorBarJsonSelector,
  selectedVariableOpacitySelector,
  selectedVariableVisibilitySelector,
  variableLayerIdSelector,
  variableZIndexSelector,
  timeAnimationActiveSelector,
  mapProjectionSelector,
  imageSmoothingSelector,
  getVariableTileLayer,
);

export const selectedDatasetVariable2LayerSelector = createSelector(
  selectedServerSelector,
  selectedDataset2Selector,
  selectedDataset2TimeLabelSelector,
  selectedDataset2AttributionsSelector,
  selectedVariable2Selector,
  selectedVariable2ColorBarNameSelector,
  selectedVariable2ColorBarMinMaxSelector,
  selectedVariable2ColorBarNormSelector,
  selectedVariable2UserColorBarJsonSelector,
  selectedVariable2OpacitySelector,
  selectedVariable2VisibilitySelector,
  variable2LayerIdSelector,
  variable2ZIndexSelector,
  timeAnimationActiveSelector,
  mapProjectionSelector,
  imageSmoothingSelector,
  getVariableTileLayer,
);

const getDatasetRgbTileLayer = (
  server,
  dataset,
  rgbSchema,
  visibility,
  layerId,
  zIndex,
  timeLabel,
  timeAnimationActive,
  mapProjection,
  attributions,
  imageSmoothing,
) => {
  if (!dataset || !rgbSchema || !visibility) {
    return null;
  }
  const queryParams = [["crs", mapProjection]];
  return getTileLayer(
    layerId,
    getTileUrl(server.url, dataset, "rgb"),
    dataset.bbox,
    rgbSchema.tileLevelMin,
    rgbSchema.tileLevelMax,
    queryParams,
    1.0,
    timeLabel,
    timeAnimationActive,
    mapProjection,
    attributions,
    imageSmoothing,
    zIndex,
  );
};

export const selectedDatasetRgbLayerSelector = createSelector(
  selectedServerSelector,
  selectedDatasetSelector,
  selectedDatasetRgbSchemaSelector,
  datasetRgbVisibilitySelector,
  datasetRgbLayerIdSelector,
  datasetRgbZIndexSelector,
  selectedDatasetTimeLabelSelector,
  timeAnimationActiveSelector,
  mapProjectionSelector,
  selectedDatasetAttributionsSelector,
  imageSmoothingSelector,
  getDatasetRgbTileLayer,
);

export const selectedDataset2RgbLayerSelector = createSelector(
  selectedServerSelector,
  selectedDataset2Selector,
  selectedDataset2RgbSchemaSelector,
  datasetRgb2VisibilitySelector,
  datasetRgb2LayerIdSelector,
  datasetRgb2ZIndexSelector,
  selectedDatasetTimeLabelSelector,
  timeAnimationActiveSelector,
  mapProjectionSelector,
  selectedDatasetAttributionsSelector,
  imageSmoothingSelector,
  getDatasetRgbTileLayer,
);

export function getTileUrl(serverUrl, dataset, variable) {
  return (
    `${serverUrl}/tiles/${encodeDatasetId(dataset)}/${encodeVariableName(variable)}/` +
    "{z}/{y}/{x}"
  );
}

export function getDefaultFillOpacity() {
  return getUserPlaceFillOpacity();
}

export function getDefaultStyleImage(color) {
  return new OlCircle({
    fill: getDefaultFillStyle(color),
    stroke: getDefaultStrokeStyle(color),
    radius: 6,
  });
}

export function getDefaultStrokeStyle(color) {
  return new OlStrokeStyle({
    color,
    width: 1.25,
  });
}

export function getDefaultFillStyle(color) {
  return new OlFillStyle({
    color,
  });
}

export function getDefaultPlaceGroupStyle(color) {
  return new OlStyle({
    image: getDefaultStyleImage(color),
    stroke: getDefaultStrokeStyle(color),
    fill: getDefaultFillStyle(color),
  });
}

export const selectedDatasetPlaceGroupLayersSelector = createSelector(
  selectedDatasetSelectedPlaceGroupsSelector,
  mapProjectionSelector,
  showDatasetPlacesLayerSelector,
  (placeGroups, mapProjection, showDatasetPlaces) => {
    if (!showDatasetPlaces || placeGroups.length === 0) {
      return null;
    }
    const layers = [];
    placeGroups.forEach((placeGroup, index) => {
      if (isValidPlaceGroup(placeGroup)) {
        placeGroup.features.forEach((feature) => {
          const placeFeature = {
            ...placeGroup,
            features: [feature],
          };
          layers.push(
            <Vector
              key={index}
              id={`placeGroup.${feature.id}`}
              style={getDefaultPlaceGroupStyle(feature.properties.color)}
              zIndex={100}
              source={
                new OlVectorSource({
                  features: new OlGeoJSONFormat({
                    dataProjection: GEOGRAPHIC_CRS,
                    featureProjection: mapProjection,
                  }).readFeatures(placeFeature),
                })
              }
            />,
          );
        });
      }
    });
    return <Layers>{layers}</Layers>;
  },
);

export const visibleInfoCardElementsSelector = createSelector(
  infoCardElementStatesSelector,
  (infoCardElementStates) => {
    const visibleInfoCardElements = [];
    Object.getOwnPropertyNames(infoCardElementStates).forEach((e) => {
      if (infoCardElementStates[e].visible) {
        visibleInfoCardElements.push(e);
      }
    });
    return visibleInfoCardElements;
  },
);

export const infoCardElementViewModesSelector = createSelector(
  infoCardElementStatesSelector,
  (infoCardElementStates) => {
    const infoCardElementCodeModes = {};
    Object.getOwnPropertyNames(infoCardElementStates).forEach((e) => {
      infoCardElementCodeModes[e] = infoCardElementStates[e].viewMode || "text";
    });
    return infoCardElementCodeModes;
  },
);

export const activityMessagesSelector = createSelector(activitiesSelector, (activities) => {
  return Object.keys(activities).map((k) => activities[k]);
});

export const baseMapsSelector = createSelector(userBaseMapsSelector, (userBaseMaps) => {
  return [...userBaseMaps, ...defaultBaseMapLayers];
});

export const overlaysSelector = createSelector(userOverlaysSelector, (userOverlays) => {
  return [...userOverlays, ...defaultOverlayLayers];
});

const getLayerFromLayerDefinition = (layerDefs, layerId, showLayer, zIndex) => {
  if (!showLayer || !layerId) {
    return null;
  }
  const layerDef = findLayer(layerDefs, layerId);
  if (!layerDef) {
    return null;
  }
  let attributions = layerDef.attribution;
  if (attributions && (attributions.startsWith("http://") || attributions.startsWith("https://"))) {
    attributions = `&copy; <a href=&quot;${layerDef.attribution}&quot;>${layerDef.group}</a>`;
  }
  let source;
  if (layerDef.wms) {
    const { layerName, styleName } = layerDef.wms;
    source = new OlTileWMSSource({
      url: layerDef.url,
      params: {
        ...(styleName ? { STYLES: styleName } : {}),
        LAYERS: layerName,
      },
      attributions,
      attributionsCollapsible: true,
    });
  } else {
    const access = getTileAccess(layerDef.group);
    source = new OlXYZSource({
      url: layerDef.url + (access ? `?${access.param}=${access.token}` : ""),
      attributions,
      attributionsCollapsible: true,
    });
  }
  return <Tile id={layerDef.id} source={source} zIndex={zIndex} />;
};

export const baseMapLayerSelector = createSelector(
  baseMapsSelector,
  selectedBaseMapIdSelector,
  showBaseMapLayerSelector,
  () => 0,
  getLayerFromLayerDefinition,
);

export const overlayLayerSelector = createSelector(
  overlaysSelector,
  selectedOverlayIdSelector,
  showOverlayLayerSelector,
  () => 20,
  getLayerFromLayerDefinition,
);

const _getLayerTitle = (layers, layerId) => {
  const layer = findLayer(layers, layerId);
  return layer ? getLayerTitle(layer) : null;
};

export const selectedBaseMapTitleSelector = createSelector(
  baseMapsSelector,
  selectedBaseMapIdSelector,
  _getLayerTitle,
);

export const selectedOverlayTitleSelector = createSelector(
  overlaysSelector,
  selectedOverlayIdSelector,
  _getLayerTitle,
);

export const layerStatesSelector = createSelector(
  selectedBaseMapTitleSelector,
  selectedOverlayTitleSelector,
  selectedBaseMapIdSelector,
  selectedOverlayIdSelector,
  selectedDatasetSelector,
  selectedDataset2Selector,
  selectedVariableSelector,
  selectedVariable2Selector,
  layerVisibilitiesSelector,
  (
    basemapTitle,
    overlayTitle,
    baseMapId,
    overlayId,
    dataset,
    dataset2,
    variable,
    variable2,
    visibilities,
  ) => ({
    baseMap: {
      title: "Base Map",
      subTitle: basemapTitle || undefined,
      visible: visibilities.baseMap,
      disabled: !baseMapId,
    },
    overlay: {
      title: "Overlay",
      subTitle: overlayTitle || undefined,
      visible: visibilities.overlay,
      disabled: !overlayId,
    },
    datasetRgb: {
      title: "Dataset RGB",
      subTitle: dataset ? dataset.title : undefined,
      visible: visibilities.datasetRgb,
      disabled: !dataset,
    },
    datasetRgb2: {
      title: "Dataset RGB",
      subTitle: dataset2 ? dataset2.title : undefined,
      visible: visibilities.datasetRgb2,
      disabled: !dataset2,
      pinned: true,
    },
    datasetVariable: {
      title: "Dataset Variable",
      subTitle:
        dataset && variable ? `${dataset.title} / ${variable.title || variable.name}` : undefined,
      visible: visibilities.datasetVariable,
      disabled: !(dataset && variable),
    },
    datasetVariable2: {
      title: "Dataset Variable",
      subTitle:
        dataset2 && variable2
          ? `${dataset2.title} / ${variable2.title || variable2.name}`
          : undefined,
      visible: visibilities.datasetVariable2,
      disabled: !(dataset2 && variable2),
      pinned: true,
    },
    datasetBoundary: {
      title: "Dataset Boundary",
      subTitle: dataset ? dataset.title : undefined,
      visible: visibilities.datasetBoundary,
      disabled: !dataset,
    },
    datasetPlaces: {
      title: "Dataset Places",
      visible: visibilities.datasetPlaces,
    },
    userPlaces: {
      title: "User Places",
      visible: visibilities.userPlaces,
    },
  }),
);
