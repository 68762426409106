

const reIdentifier = /^[a-zA-Z_$][a-zA-Z0-9_$]*$/;
const reVariables = /[a-zA-Z_$][a-zA-Z0-9_$]*/g;

const emptySet = new Set();

export function isIdentifier(value){
  return reIdentifier.test(value);
}

export function getIdentifiers(expression){
  const matches = expression.match(reVariables);
  return matches !== null ? new Set(matches) : emptySet;
}
