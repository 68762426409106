import { callJsonApi } from "./callApi";

export function getColorBars(apiServerUrl){
  return callJsonApi(`${apiServerUrl}/colorbars`, parseColorBars);
}

function parseColorBars(colorBarGroups){
  const groups= [];
  const images= {};
  colorBarGroups.forEach((colorBarGroup) => {
    const [title, description, colorBars] = colorBarGroup;
    const names = [];
    colorBars.forEach((colorBar) => {
      const [name, image] = colorBar;
      names.push(name);
      images[name] = image;
    });
    groups.push({ title, description, names });
  });
  return { groups, images };
}
