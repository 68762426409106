import Divider from "@mui/material/Divider";
import PushPinIcon from "@mui/icons-material/PushPin";

import i18n from "../../i18n";
import SelectableMenuItem from "../../components/SelectableMenuItem";

export default function LayerItem({
  layerId,
  layerStates,
  setLayerVisibility,
  last,
}) {
  const layerState = layerStates[layerId];
  if (layerState.disabled) {
    return null;
  }
  return (
    <>
      <SelectableMenuItem
        title={i18n.get(layerState.title)}
        subtitle={layerState.subTitle}
        selected={!!layerState.visible}
        secondaryIcon={
          layerState.pinned && <PushPinIcon fontSize="small" color="disabled" />
        }
        onClick={() => setLayerVisibility(layerId, !layerState.visible)}
      />
      {last ? (
        <Divider />
      ) : (
        <Divider
          variant="inset"
          component="li"
          style={{ margin: "0 0 0 52px" }}
        />
      )}
    </>
  );
}
