

import { isNumber } from "../../util/types";
import { utcTimeToIsoDateString } from "../../util/time";

export const formatTimeTick = (value) => {
  if (!isNumber(value) || !Number.isFinite(value)) {
    return "";
  }
  return utcTimeToIsoDateString(value);
};

export const formatValueTick = (value) => {
  return value.toPrecision(3);
};
