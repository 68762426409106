
import {
  callJsonApi,
  makeRequestInit,
  makeRequestUrl,
} from "./callApi";
import { encodeDatasetId, encodeVariableName } from "../model/encode";


export function getStatistics(
  apiServerUrl,
  dataset,
  variable,
  placeInfo,
  timeLabel,
  accessToken,
){
  const query= [["time", timeLabel]];
  const url = makeRequestUrl(
    `${apiServerUrl}/statistics/${encodeDatasetId(dataset)}/${encodeVariableName(variable)}`,
    query,
  );

  const init = {
    ...makeRequestInit(accessToken),
    method: "post",
    body: JSON.stringify(placeInfo.place.geometry),
  };

  const source= {
    dataset,
    variable,
    placeInfo,
    time: timeLabel,
  };

  return callJsonApi(url, init, (r) => ({
    source,
    statistics: r.result,
  }));
}
