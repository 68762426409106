import { useMutation } from "@tanstack/react-query";
import Ajax, { GetToken } from "../../Util/ajax";

const MUTATION_KEY = "COVERAGES_BY_LOCATION_MUTATION";

export const useCoveragesByLocation = () => {
  return useMutation({
    mutationKey: [MUTATION_KEY],
    mutationFn: async ({ lat, lon, page = 0, size = 10000 }) => {
      if (!lat || !lon) {
        return null;
      }

      const token = await GetToken();
      const url = new URL(`${window.appConfig.apiUrl}/internal/coverages/find-by-location`);

      url.searchParams.set("lat", lat);
      url.searchParams.set("lon", lon);
      url.searchParams.set("page", page);
      url.searchParams.set("size", size);
      const response = await Ajax.getData(url.href, token);

      return response;
    },
  });
};
