import { useEffect, useState } from "react";

export default function useFetchText(markdownUrl) {
  const [markdownText, setMarkdownText] = useState();

  useEffect(() => {
    if (!markdownUrl) {
      setMarkdownText(undefined);
    } else {
      fetch(markdownUrl)
        .then((response) => response.text())
        .then((text) => setMarkdownText(text))
        .catch((error) => {
          console.error(error);
        });
    }
  }, [markdownUrl]);

  return markdownText;
}
