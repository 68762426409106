import { parseISO } from "date-fns";

import { utcTimeToIsoDateTimeString } from "../util/time";
import { findPlaceInPlaceGroups } from "./place";


export const UNIT = {
  seconds: 1000,
  minutes: 1000 * 60,
  hours: 1000 * 60 * 60,
  days: 1000 * 60 * 60 * 24,
  weeks: 1000 * 60 * 60 * 24 * 7,
  years: 1000 * 60 * 60 * 24 * 365,
};


export function equalTimeRanges(t1, t2) {
  if (t1 === t2) {
    return true;
  } else if (t1 !== null && t2 != null) {
    return t1[0] === t2[0] && t1[1] === t2[1];
  }
  return false;
}

export function timeSeriesGroupsToTable(
  timeSeriesGroups,
  placeGroups,
) {
  const dataColNames = new Set();
  const placeIds = new Set();
  const timePlaceRows= {};
  for (const timeSeriesGroup of timeSeriesGroups) {
    for (const timeSeries of timeSeriesGroup.timeSeriesArray) {
      const { placeId, datasetId, variableName, valueDataKey, errorDataKey } =
        timeSeries.source;
      if (placeId !== null) {
        placeIds.add(placeId);
      }
      const valueColName = `${datasetId}.${variableName}.${valueDataKey}`;
      dataColNames.add(valueColName);
      let errorColName= null;
      if (errorDataKey) {
        errorColName = `${datasetId}.${variableName}.${errorDataKey}`;
        dataColNames.add(errorColName);
      }
      timeSeries.data.forEach((point) => {
        const time = utcTimeToIsoDateTimeString(point.time);
        // if placeId is null, then data is from import CSV / GeoJSON
        // and datasetId is the name of the place group.
        const timePlaceId = `${placeId !== null ? placeId : datasetId}-${time}`;
        const timePlaceRow = timePlaceRows[timePlaceId];
        if (!timePlaceRow) {
          timePlaceRows[timePlaceId] = {
            placeId,
            time,
            [valueColName]: point[valueDataKey],
          };
        } else {
          timePlaceRows[timePlaceId] = {
            ...timePlaceRow,
            [valueColName]: point[valueDataKey],
          };
        }
        if (errorColName !== null) {
          timePlaceRows[timePlaceId][errorColName] = point[errorDataKey];
        }
      });
    }
  }

  const colNames= ["placeId", "time"].concat(
    Array.from(dataColNames).sort(),
  );
  const dataRows= [];

  Object.keys(timePlaceRows).forEach((timePlaceId) => {
    const rowData = timePlaceRows[timePlaceId];
    const dataRow = new Array(colNames.length);
    colNames.forEach((colName, i) => {
      dataRow[i] = rowData[colName];
    });
    dataRows.push(dataRow);
  });

  dataRows.sort((row1, row2) => {
    const time1= row1[1] ;
    const time2 = row2[1];
    const timeDelta = time1.localeCompare(time2);
    if (timeDelta !== 0) {
      return timeDelta;
    }
    const placeId1 = row1[0] ;
    const placeId2 = row2[0] ;
    return placeId1.localeCompare(placeId2);
  });

  const referencedPlaces= {};
  placeIds.forEach((placeId) => {
    referencedPlaces[placeId] = findPlaceInPlaceGroups(placeGroups, placeId);
  });

  return { colNames, dataRows, referencedPlaces };
}

export function timeSeriesGroupsToGeoJSON(
  timeSeriesGroups,
) {
  const features = [];
  for (const timeSeriesGroup of timeSeriesGroups) {
    for (const timeSeries of timeSeriesGroup.timeSeriesArray) {
      features.push(timeSeriesToGeoJSON(timeSeries));
    }
  }
  return { type: "FeatureCollection", features };
}

export function timeSeriesToGeoJSON(
  timeSeries,
) {
  const timeSeriesSource = timeSeries.source;
  let id = `${timeSeriesSource.datasetId}-${timeSeriesSource.variableName}`;
  if (timeSeriesSource.placeId !== null) {
    id += `-${timeSeriesSource.placeId}`;
  }
  return {
    id,
    type: "Feature",
    geometry: timeSeriesSource.geometry,
    properties: {
      datasetId: timeSeriesSource.datasetId,
      datasetTitle: timeSeriesSource.datasetTitle,
      variableName: timeSeriesSource.variableName,
      variableUnits: timeSeriesSource.variableUnits,
      placeId: timeSeriesSource.placeId,
      valueDataKey: timeSeriesSource.valueDataKey,
      errorDataKey: timeSeriesSource.errorDataKey,
      data: timeSeries.data.map((p) => {
        return {
          ...p,
          time: utcTimeToIsoDateTimeString(p.time),
        };
      }),
    },
  };
}

export function placeGroupToTimeSeries(
  placeGroup,
){
  let timeSeriesMapping= null;
  const places = placeGroup.features || [];
  for (const place of places) {
    if (!place.properties) {
      continue;
    }
    const time = place.properties["time"];
    if (typeof time !== "string") {
      continue;
    }
    const timeValue = parseISO(time);
    const utcTime = timeValue.getTime();
    if (Number.isNaN(utcTime)) {
      continue;
    }
    for (const propertyName of Object.getOwnPropertyNames(place.properties)) {
      let propertyValue = place.properties[propertyName];
      const propertyType = typeof propertyValue;
      if (propertyType === "boolean") {
        propertyValue = propertyValue ? 1 : 0;
      } else if (propertyType !== "number") {
        propertyValue = Number.NaN;
      }
      if (Number.isNaN(propertyValue)) {
        continue;
      }
      const point= {
        time: utcTime,
        countTot: 1,
        mean: propertyValue,
      };
      if (timeSeriesMapping === null) {
        timeSeriesMapping = {};
      }
      const timeSeries = timeSeriesMapping[propertyName];
      if (!timeSeries) {
        timeSeriesMapping[propertyName] = {
          source: {
            // important: this is the imported CSV / GeoJSON place group
            datasetId: placeGroup.id,
            datasetTitle: placeGroup.title,
            variableName: propertyName,
            placeId: null, // we don't have an individual place for the entire time-series
            geometry: null, // could be computed later from data points (as GeometryCollection)
            valueDataKey: "mean",
            errorDataKey: null,
          },
          data: [point],
          dataProgress: 1.0,
        };
      } else {
        timeSeries.data.push(point);
      }
    }
  }
  if (timeSeriesMapping === null) {
    return null;
  }
  return { placeGroup, timeSeries: timeSeriesMapping };
}
