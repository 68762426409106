import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import { ButtonNav } from '../Buttons/Buttons';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link as RouterLink } from 'react-router-dom';

const ToolboxPartnersEdit = props => {

  const { title, onEdit } = props;

  const useStyles = makeStyles((theme) => ({
    root: {
      position: 'sticky',
      top: 56,
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      zIndex: theme.zIndex.appBar,
      [theme.breakpoints.up('sm')]: {
        top: 64,
      },
    },
    Toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.divider,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    cancel: {
      color: theme.palette.error.main
    },
    title: {
      marginBottom: 18,
      fontWeight: 700,
      marginLeft: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(2),
        marginBottom: 0,
      },
    },
    nav: {
      display: 'flex',
      alignItems: 'center',
    },
    btn: {
      borderRadius: 8,
    }
  }));

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Toolbar className={classes.Toolbar}>
        <Box className={classes.nav}>
          <ButtonNav
            className={classes.nav}
            component={RouterLink}
            to="/clients"
            size="medium"
          ><ArrowBackIcon /></ButtonNav>
          <Typography className={classes.title} variant="h6">{title}</Typography>
        </Box>
        <Box>
          <Button className={classes.btn} size="large" variant="contained" color="primary" onClick={onEdit}>
            Edit Details
          </Button>
        </Box>
      </Toolbar>
    </Box>
  );
};

export default ToolboxPartnersEdit;
