import React from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import InfoIcon from "@mui/icons-material/Info";
import FunctionsIcon from "@mui/icons-material/Functions";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import ThreeDRotationIcon from "@mui/icons-material/ThreeDRotation";

import i18n from "../i18n";
import { makeStyles } from "../util/styles";
import { setSidebarPanelId } from "../actions/controlActions";
import { sidebarPanelIds } from "../states/controlState";
import InfoPanel from "./InfoPanel";
import TimeSeriesPanel from "./TimeSeriesPanel";

const sidebarPanelIcons = {
  info: React.createElement(InfoIcon, { fontSize: "inherit" }),
  timeSeries: React.createElement(StackedLineChartIcon, { fontSize: "inherit" }),
  stats: React.createElement(FunctionsIcon, { fontSize: "inherit" }),
  volume: React.createElement(ThreeDRotationIcon, { fontSize: "inherit" }),
};

const sidebarPanelLabels = {
  timeSeries: "Time-Series",
  info: "Info",
};

const styles = makeStyles({
  tabs: { minHeight: "34px" },
  tab: {
    padding: "5px 10px",
    textTransform: "none",
    fontWeight: "regular",
    minHeight: "32px",
  },
  tabBoxHeader: {
    borderBottom: 1,
    borderColor: "divider",
    position: "sticky",
    top: 0,
    zIndex: 1100,
    backgroundColor: "background.paper",
  },
});

const mapStateToProps = (state) => {
  return {
    sidebarPanelId: state.controlState.sidebarPanelId,
  };
};

const mapDispatchToProps = {
  setSidebarPanelId,
};

function Sidebar({ sidebarPanelId, setSidebarPanelId }) {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={styles.tabBoxHeader}>
        <Tabs
          value={sidebarPanelId}
          onChange={(_, value) => {
            setSidebarPanelId(value);
          }}
          variant="scrollable"
          sx={styles.tabs}
        >
          {sidebarPanelIds.map((panelId) => (
            <Tab
              key={panelId}
              icon={sidebarPanelIcons[panelId]}
              iconPosition="start"
              sx={styles.tab}
              disableRipple
              value={panelId}
              label={i18n.get(sidebarPanelLabels[panelId])}
            />
          ))}
        </Tabs>
      </Box>
      {sidebarPanelId === "info" && <InfoPanel />}
      {sidebarPanelId === "timeSeries" && <TimeSeriesPanel />}
    </Box>
  );
}

const ConnectedSidebar = connect(mapStateToProps, mapDispatchToProps)(Sidebar);
export default ConnectedSidebar;
