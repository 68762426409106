import React, { useEffect, useRef, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import ToolboxPartnersNew from "../Toolbox/ToolboxPartnersNew";
import ToolboxPartnersEdit from "../Toolbox/ToolboxPartnersEdit";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from "@mui/material/Skeleton";
import { useBusinessPartnerRoles } from "../../hooks/businessPartner/useBusinessPartnerRoles";
import { getLabel } from "../../Util/getLabel";
import { FormHelperText } from "@mui/material";
import { useTaxStatuses } from "../../hooks/taxStatuses/useTaxStatues";
import { GeocodingAutocomplete } from "../GeocodingAutocomplete/GeocodingAutocomplete";
import { useQueryClient } from "@tanstack/react-query";
import Ajax, { GetToken } from "../../Util/ajax";
import { BUSINESS_PARTNER_BY_ID_QUERY_KEY } from "../../hooks/businessPartner";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(6),
  },
  formGroup: {
    "& + *": {
      marginTop: theme.spacing(5),
    },
  },
  main: {
    borderRadius: 12,
    boxShadow: "0px 3px 12px 0px #0000001F",
    backgroundColor: "white",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
  },
  panelItemLabel: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    marginBottom: theme.spacing(2),
  },
  panelItemValue: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
  },
  panelItemTextbox: {},
  addManually: {
    textTransform: "capitalize",
    color: theme.palette.success.main,
  },
}));

const Partner = (props) => {
  const { id, isLoading, edit, partner, onEdit, setIsEdit } = props;
  const { data: roles = [] } = useBusinessPartnerRoles({ enabled: edit });
  const { data: taxStatuses = [] } = useTaxStatuses();
  const classes = useStyles();
  const queryClient = useQueryClient();
  const form = useForm({
    resolver: yupResolver(
      yup.object({
        name: yup.string().required("Name is required"),
        role: yup.string().required("Role is required"),
        contactName: yup.string().required("Contact name is required"),
        email: yup.string().email("Invalid email address").required("Email is required"),
        phone: yup.string().required("Phone is required"),
        taxStatus: yup.string().required("Tax status is required"),
        houseNumber: yup.string().required("House number is required"),
        street: yup.string().required("Street is required"),
        postcode: yup.string().required("Postcode is required"),
        city: yup.string().required("City is required"),
        district: yup.string().required("District is required"),
        county: yup.string().required("County is required"),
        state: yup.string().required("State is required"),
        stateCode: yup.string().required("State code is required"),
        country: yup.string().required("Country is required"),
        countryCode: yup.string().required("Country code is required"),
      }),
    ),
  });
  const isInitializedRef = useRef(false);
  const [addressString, setAddressString] = useState("");
  const [
    name,
    contactName,
    email,
    houseNumber,
    street,
    postcode,
    city,
    district,
    county,
    state,
    stateCode,
    country,
    countryCode,
    phone,
    taxStatus,
    role,
  ] = form.watch([
    "name",
    "contactName",
    "email",
    "houseNumber",
    "street",
    "postcode",
    "city",
    "district",
    "county",
    "state",
    "stateCode",
    "country",
    "countryCode",
    "phone",
    "taxStatus",
    "role",
  ]);

  const handleUpdate = async (values) => {
    const token = await GetToken();
    await Ajax.putData(
      `${window.appConfig.apiUrl}/internal/business-partners/${id}`,
      {
        "@type": "NewBusinessPartner",
        name: values.name,
        role: values.role,
        contactName: values.contactName,
        contactEmailAddress: values.email,
        contactPhoneNumber: values.phone,
        address: {
          "@type": "NewAddress",
          houseNumber: houseNumber ?? "",
          street: street ?? "",
          postcode: postcode ?? "",
          city: city ?? "",
          district: district ?? "",
          county: county ?? "",
          state: state ?? "",
          stateCode: stateCode ?? "",
          country: country ?? "",
          countryCode: countryCode ?? "",
        },
        taxStatus: values.taxStatus,
      },
      token,
    );

    queryClient.invalidateQueries({ queryKey: [BUSINESS_PARTNER_BY_ID_QUERY_KEY, id] });
    setIsEdit(false);
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isInitializedRef.current) {
      return;
    }

    const { name, role, contactName, email, phone, address, taxStatus } = partner;
    const addressString = address
      ? `${address.houseNumber ? address.houseNumber : ""} ${address.street ? address.street : ""}${
          address.city
            ? `${!!address.houseNumber && !!address.street ? ", " : ""}${address.city}`
            : ""
        }${address.county ? `${address.city ? ", " : ""}${address.county}` : ""}${
          address.state ? `${address.county ? ", " : ""}${address.state}` : ""
        }`
      : "-";

    setAddressString(addressString);
    const selectedTaxStatus = taxStatuses.find((item) => item.name === taxStatus);

    form.reset({
      name,
      role,
      contactName,
      email,
      phone,
      houseNumber: address?.houseNumber ?? "",
      street: address?.street ?? "",
      postcode: address?.postcode ?? "",
      city: address?.city ?? "",
      district: address?.district ?? "",
      county: address?.county ?? "",
      state: address?.state ?? "",
      stateCode: address?.stateCode ?? "",
      country: address?.country ?? "",
      countryCode: address?.countryCode ?? "",
      taxStatus: selectedTaxStatus?.id,
    });

    isInitializedRef.current = true;
  }, [isLoading, form, partner, taxStatus, taxStatuses]);

  // Edit mode is default.
  const toolbox = edit ? (
    <ToolboxPartnersNew edit={edit} onSave={form.handleSubmit(handleUpdate)} />
  ) : (
    <ToolboxPartnersEdit title={name} onEdit={onEdit} />
  );

  const handleSelectPlace = (value) => {
    if (!value) {
      [
        "houseNumber",
        "street",
        "postcode",
        "city",
        "district",
        "county",
        "state",
        "stateCode",
        "country",
        "countryCode",
      ].forEach((key) => form.setValue(key, ""));
    }

    const properties = value?.properties;

    Object.entries({
      houseNumber: properties?.housenumber ?? "",
      street: properties?.street ?? "",
      postcode: properties?.postcode ?? "",
      city: properties?.city ?? "",
      district: properties?.district ?? "",
      county: properties?.county ?? "",
      state: properties?.state ?? "",
      stateCode: properties?.state_code ?? "",
      country: properties?.country ?? "",
      countryCode: properties?.country_code ?? "",
    }).forEach(([key, val]) => form.setValue(key, val));
  };

  const errors = form.formState.errors;

  // Data to be added/edited
  const editForm = (
    <FormProvider {...form}>
      <form autoComplete="off">
        <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={12} md={6}>
            <TextField {...form.register("name")} label="Name" fullWidth required />
            {errors?.name?.message && (
              <FormHelperText error>{errors?.name?.message}</FormHelperText>
            )}
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField {...form.register("contactName")} label="Contact name" fullWidth required />
            {errors?.contactName?.message && (
              <FormHelperText error>{errors?.contactName?.message}</FormHelperText>
            )}
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField {...form.register("email")} label="Email" type="email" fullWidth required />
            {errors?.email?.message && (
              <FormHelperText error>{errors?.email?.message}</FormHelperText>
            )}
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField {...form.register("phone")} label="Phone Number" fullWidth required />
            {errors?.phone?.message && (
              <FormHelperText error>{errors?.phone?.message}</FormHelperText>
            )}
          </Grid>
          <Grid item sm={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="taxStatus" required>
                Tax status
              </InputLabel>
              <Select
                {...form.register("taxStatus")}
                value={taxStatus ?? ""}
                labelId="taxStatus"
                label="Tax status"
                variant="outlined"
              >
                {taxStatuses.map((taxStatus) => (
                  <MenuItem key={taxStatus.id} value={taxStatus.id}>
                    {taxStatus.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errors?.taxStatus?.message && (
              <FormHelperText error>{errors?.taxStatus?.message}</FormHelperText>
            )}
          </Grid>
          <Grid item sm={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="roleLabel" required>
                Role
              </InputLabel>
              <Select
                {...form.register("role")}
                value={role ?? ""}
                labelId="roleLabel"
                label="Role"
                variant="outlined"
              >
                {roles.map((role) => (
                  <MenuItem key={role} value={role}>
                    {getLabel(role)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errors?.role?.message && (
              <FormHelperText error>{errors?.role?.message}</FormHelperText>
            )}
          </Grid>
          <Grid item sm={12}>
            <Typography>Address</Typography>
          </Grid>
          <Grid item sm={12} md={6}>
            <GeocodingAutocomplete
              label="Find address"
              placeholder="Find address"
              address={addressString ?? ""}
              onChange={handleSelectPlace}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              InputLabelProps={{ shrink: !!houseNumber }}
              {...form.register("houseNumber")}
              label="House number"
              fullWidth
            />
            {errors?.houseNumber?.message && (
              <FormHelperText error>{errors?.houseNumber?.message}</FormHelperText>
            )}
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              InputLabelProps={{ shrink: !!street }}
              {...form.register("street")}
              label="Street"
              fullWidth
            />
            {errors?.street?.message && (
              <FormHelperText error>{errors?.street?.message}</FormHelperText>
            )}
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              InputLabelProps={{ shrink: !!postcode }}
              {...form.register("postcode")}
              label="Post code"
              fullWidth
              required
            />
            {errors?.postcode?.message && (
              <FormHelperText error>{errors?.postcode?.message}</FormHelperText>
            )}
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              InputLabelProps={{ shrink: !!city }}
              {...form.register("city")}
              label="City"
              fullWidth
            />
            {errors?.city?.message && (
              <FormHelperText error>{errors?.city?.message}</FormHelperText>
            )}
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              InputLabelProps={{ shrink: !!district }}
              {...form.register("district")}
              label="District"
              fullWidth
            />
            {errors?.district?.message && (
              <FormHelperText error>{errors?.district?.message}</FormHelperText>
            )}
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              InputLabelProps={{ shrink: !!county }}
              {...form.register("county")}
              label="County"
              fullWidth
            />
            {errors?.county?.message && (
              <FormHelperText error>{errors?.county?.message}</FormHelperText>
            )}
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              InputLabelProps={{ shrink: !!state }}
              defaultValue=""
              {...form.register("state")}
              label="State"
              fullWidth
              required
            />
            {errors?.county?.message && (
              <FormHelperText error>{errors?.county?.message}</FormHelperText>
            )}
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              InputLabelProps={{ shrink: !!stateCode }}
              {...form.register("stateCode")}
              label="State code"
              fullWidth
              required
            />
            {errors?.county?.message && (
              <FormHelperText error>{errors?.county?.message}</FormHelperText>
            )}
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              InputLabelProps={{ shrink: !!country }}
              {...form.register("country")}
              label="Country"
              fullWidth
              required
            />
            {errors?.country?.message && (
              <FormHelperText error>{errors?.country?.message}</FormHelperText>
            )}
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              InputLabelProps={{ shrink: !!countryCode }}
              {...form.register("countryCode")}
              label="Country code"
              fullWidth
              required
            />
            {errors?.countryCode?.message && (
              <FormHelperText error>{errors?.countryCode?.message}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );

  // Read only data
  const panel = (
    <Grid container rowSpacing={{ xs: 1, sm: 2, md: 4 }} columnSpacing={2}>
      <Grid sm={12} md={6}>
        <Typography className={classes.panelItemLabel}>Role</Typography>
        {isLoading ? (
          <Skeleton />
        ) : (
          <Typography className={classes.panelItemValue}>{getLabel(role)}</Typography>
        )}
      </Grid>
      <Grid sm={12} md={6}>
        <Typography className={classes.panelItemLabel}>Name</Typography>
        {isLoading ? (
          <Skeleton />
        ) : (
          <Typography className={classes.panelItemValue}>{name}</Typography>
        )}
      </Grid>
      <Grid sm={12} md={6}>
        <Typography className={classes.panelItemLabel}>Contact Name</Typography>
        {isLoading ? (
          <Skeleton />
        ) : (
          <Typography className={classes.panelItemValue}>{contactName}</Typography>
        )}
      </Grid>
      <Grid sm={12} md={6}>
        <Typography className={classes.panelItemLabel}>Email</Typography>
        {isLoading ? (
          <Skeleton />
        ) : (
          <Typography className={classes.panelItemValue}>{email}</Typography>
        )}
      </Grid>
      <Grid sm={12} md={6}>
        <Typography className={classes.panelItemLabel}>Phone Number</Typography>
        {isLoading ? (
          <Skeleton />
        ) : (
          <Typography className={classes.panelItemValue}>{phone}</Typography>
        )}
      </Grid>
      <Grid sm={12} md={6}>
        <Typography className={classes.panelItemLabel}>Tax Status (for policy holder)</Typography>
        {isLoading ? (
          <Skeleton />
        ) : (
          <Typography className={classes.panelItemValue}>{taxStatus}</Typography>
        )}
      </Grid>
      <Grid item sm={12}>
        <Typography>Address</Typography>
      </Grid>
      <Grid item sm={12} md={6}>
        <Typography className={classes.panelItemLabel}>House number</Typography>
        {isLoading ? (
          <Skeleton />
        ) : (
          <Typography className={classes.panelItemValue}>{houseNumber}</Typography>
        )}
      </Grid>
      <Grid item sm={12} md={6}>
        <Typography className={classes.panelItemLabel}>Street</Typography>
        {isLoading ? (
          <Skeleton />
        ) : (
          <Typography className={classes.panelItemValue}>{street}</Typography>
        )}
      </Grid>
      <Grid item sm={12} md={6}>
        <Typography className={classes.panelItemLabel}>Post code</Typography>
        {isLoading ? (
          <Skeleton />
        ) : (
          <Typography className={classes.panelItemValue}>{postcode}</Typography>
        )}
      </Grid>
      <Grid item sm={12} md={6}>
        <Typography className={classes.panelItemLabel}>City</Typography>
        {isLoading ? (
          <Skeleton />
        ) : (
          <Typography className={classes.panelItemValue}>{city}</Typography>
        )}
      </Grid>
      <Grid item sm={12} md={6}>
        <Typography className={classes.panelItemLabel}>District</Typography>
        {isLoading ? (
          <Skeleton />
        ) : (
          <Typography className={classes.panelItemValue}>{district}</Typography>
        )}
      </Grid>
      <Grid item sm={12} md={6}>
        <Typography className={classes.panelItemLabel}>County</Typography>
        {isLoading ? (
          <Skeleton />
        ) : (
          <Typography className={classes.panelItemValue}>{county}</Typography>
        )}
      </Grid>
      <Grid item sm={12} md={6}>
        <Typography className={classes.panelItemLabel}>State</Typography>
        {isLoading ? (
          <Skeleton />
        ) : (
          <Typography className={classes.panelItemValue}>{state}</Typography>
        )}
      </Grid>
      <Grid item sm={12} md={6}>
        <Typography className={classes.panelItemLabel}>State code</Typography>
        {isLoading ? (
          <Skeleton />
        ) : (
          <Typography className={classes.panelItemValue}>{stateCode}</Typography>
        )}
      </Grid>
      <Grid item sm={12} md={6}>
        <Typography className={classes.panelItemLabel}>Country</Typography>
        {isLoading ? (
          <Skeleton />
        ) : (
          <Typography className={classes.panelItemValue}>{country}</Typography>
        )}
      </Grid>
      <Grid item sm={12} md={6}>
        <Typography className={classes.panelItemLabel}>Country code</Typography>
        {isLoading ? (
          <Skeleton />
        ) : (
          <Typography className={classes.panelItemValue}>{countryCode}</Typography>
        )}
      </Grid>
    </Grid>
  );

  return (
    <Box className={classes.root}>
      <Toolbar />
      {toolbox}
      <Grid container sx={{ py: 4, px: 2.5 }}>
        <main className={classes.main}>{edit ? editForm : panel}</main>
      </Grid>
    </Box>
  );
};

export default Partner;
