import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useMsal } from '@azure/msal-react';
import { requiredScopes } from '../../Vendor/Azure/authConfig';
import { useForm } from 'react-hook-form';
import microsoftIcon from '../../assets/images/microsoft-icon.svg';
import logo from '../../assets/images/logo-lg.svg';
import login from '../../assets/images/login.png';
import { Box, Divider, Link, Stack, TextField } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    textAlign: 'center',
    backgroundImage: `url(${login})`,
    backgroundSize: 'cover',
    backgroundPosition: '-25vw 60%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row-reverse',
    fontFamily: theme.typography.fontFamily,
  },
  logo: {
    maxWidth: theme.spacing(35),
    marginBottom: theme.spacing(5),
  },
  title: {
    marginBottom: '1rem',
    fontWeight: '600',
    textAlign: 'left',
  },
  section: {
    boxSizing: 'border-box',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderTopLeftRadius: theme.spacing(6),
    borderBottomLeftRadius: theme.spacing(6),
    backgroundColor: theme.palette.common.white,
    height: '100%',
    width: '50%',
    minWidth: '500px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      minWidth: '100%',
      borderRadius: 0,
    },
  },
  input: {
    height: '60px',

    '& .MuiInputBase-root.Mui-disabled': {
      background: theme.palette.whiteSmoke,
    },
  },
  button: {
    height: '60px',
    textTransform: 'none',
  },
}));

const Login = () => {
  const { instance } = useMsal();
  const { handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const classes = useStyles();

  const handleLogin = () => {
    instance.loginRedirect(requiredScopes).catch((e) => {
      console.log(e);
    });
  };

  const onSubmit = (values) => {
    console.log(values);
  };

  return (
    <main className={classes.root}>
      <section className={classes.section}>
        <Box maxWidth="576px" width="100%">
          <img className={classes.logo} src={logo} alt="Plaform logo" />
          <Typography variant="h5" className={classes.title}>
            Sign in
          </Typography>
          <Stack gap={4}>
            <Button
              variant="outlined"
              onClick={handleLogin}
              className={classes.button}
              startIcon={<img src={microsoftIcon} alt="Microsoft Icon" />}
            >
              Continue with Microsoft SSO
            </Button>
            <Divider component={Box} textAlign="center" fontWeight="bold">
              OR
            </Divider>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack gap={3}>
                <Stack gap={1} alignItems="flex-start">
                  <Box component="label" htmlFor="email">
                    Email
                  </Box>
                  <TextField
                    className={classes.input}
                    id="email"
                    variant="outlined"
                    fullWidth
                    disabled
                  />
                </Stack>
                <Stack gap={1} alignItems="flex-start">
                  <Box component="label" htmlFor="password">
                    Password
                  </Box>
                  <TextField
                    className={classes.input}
                    disabled
                    id="password"
                    variant="outlined"
                    fullWidth
                    type="password"
                  />
                </Stack>
                <Button variant="contained" color="primary" className={classes.button} disabled>
                  Sign in
                </Button>
              </Stack>
            </form>
            <Typography>
              Don’t have an account?{' '}
              <Link sx={{ cursor: 'pointer' }} fontWeight="bold">
                Signup Here
              </Link>
            </Typography>
          </Stack>
        </Box>
      </section>
    </main>
  );
};

export default Login;
