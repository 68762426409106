import { useQuery } from "@tanstack/react-query";
import Ajax, { GetToken } from "../../Util/ajax";

const QUERY_KEY = `GET_PROFILE`;

export const useProfile = () => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      const token = await GetToken();
      const URL = `${window.appConfig.apiUrl}/internal/me`;
      const response = await Ajax.getData(URL, token);

      return response;
    },
    refetchOnWindowFocus: false,
  });
};
