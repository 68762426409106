import { isObject } from "../util/types";

export function getLocalStorage(brandingName){
  return _getStorage("localStorage", brandingName);
}

function _getStorage(
  type,
  brandingName,
) {
  try {
    const storage = window[type];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return new Storage(storage , brandingName);
  } catch (e) {
    return null;
  }
}

export class Storage {
  nativeStorage;
  brandingName;

  constructor(nativeStorage, brandingName) {
    this.nativeStorage = nativeStorage;
    this.brandingName = brandingName;
  }

  getItem(
    propertyName,
    defaultValue,
    parser,
    converter,
  ){
    const value = this.nativeStorage.getItem(this.makeKey(propertyName));
    if (value !== null) {
      try {
        const result = parser ? parser(value) : value;
        return converter ? converter(result) : result;
      } catch (e) {
        console.error(`Failed parsing user setting "${propertyName}": ${e}`);
      }
    }
    return typeof defaultValue === "undefined" ? null : defaultValue;
  }

  getObjectItem(propertyName, defaultValue){
    return this.getItem(propertyName, defaultValue, (value) =>
      JSON.parse(value),
    );
  }

  getBooleanProperty(
    propertyName,
    target,
    defaultObj,
  ) {
    this.getProperty(
      propertyName,
      target,
      defaultObj,
      (value) => value === "true",
    );
  }

  getIntProperty(
    propertyName,
    target,
    defaultObj,
  ) {
    this.getProperty(propertyName, target, defaultObj, parseInt);
  }

  getStringProperty(
    propertyName,
    target,
    defaultObj,
  ) {
    this.getProperty(propertyName, target, defaultObj, (value) => value);
  }

  getArrayProperty(
    propertyName,
    target,
    defaultObj,
    converter,
  ) {
    this.getProperty(
      propertyName,
      target,
      defaultObj,
      (value) => {
        const parsedArray = JSON.parse(value);
        if (Array.isArray(parsedArray)) {
          return parsedArray;
        }
        const defaultArray = defaultObj[propertyName];
        if (Array.isArray(defaultArray)) {
          return defaultArray;
        }
        return [];
      },
      converter,
    );
  }

  getObjectProperty(
    propertyName,
    target,
    defaultObj,
  ) {
    this.getProperty(propertyName, target, defaultObj, (value) => {
      const parsedObj = JSON.parse(value);
      const defaultObjValue = defaultObj[propertyName];
      const resultObj = {
        ...defaultObjValue,
        ...parsedObj,
      };
      Object.getOwnPropertyNames(parsedObj).forEach((key) => {
        const defaultValue = (defaultObjValue)[key];
        const parsedValue = parsedObj[key];
        if (isObject(defaultValue) && isObject(parsedValue)) {
          resultObj[key] = { ...defaultValue, ...parsedValue };
        }
      });
      return resultObj;
    });
  }

 getProperty(
    propertyName,
    target,
    defaultObj,
    parser,
    converter,
  ) {
    (target )[propertyName] = this.getItem(
      propertyName,
      (defaultObj)[propertyName],
      parser,
      converter,
    );
  }

  setItem(
    propertyName,
    value,
    formatter,
  ) {
    if (typeof value === "undefined" || value === null) {
      this.nativeStorage.removeItem(this.makeKey(propertyName));
    } else {
      const formattedValue = formatter ? formatter(value) : value + "";
      this.nativeStorage.setItem(this.makeKey(propertyName), formattedValue);
    }
  }

  setObjectItem(propertyName, value) {
    this.setItem(propertyName, value, (value) => JSON.stringify(value));
  }

  setPrimitiveProperty(propertyName, source) {
    this.setItem(propertyName , source[propertyName]);
  }

  setArrayProperty(propertyName, source) {
    this.setObjectItem(propertyName , source[propertyName]);
  }

  setObjectProperty(propertyName, source) {
    this.setObjectItem(propertyName , source[propertyName]);
  }

  makeKey(propertyName) {
    return `xcube.${this.brandingName}.${propertyName}`;
  }
}
