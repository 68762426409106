

import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";

import i18n from "../i18n";

const StyledProgress = styled(CircularProgress)(
  ({ theme }) => ({
    margin: theme.spacing(2),
  }),
);
const StyledMessage = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const StyledContainerDiv = styled("div")(({ theme }) => ({
  margin: theme.spacing(1),
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}));

export default function LoadingDialog({ messages }) {
  if (messages.length === 0) {
    return null;
  }
  return (
    <Dialog open={true} aria-labelledby="loading">
      <DialogTitle id="loading">{i18n.get("Please wait...")}</DialogTitle>
      <StyledContainerDiv>
        <StyledProgress />
        {messages.map((message, i) => (
          <StyledMessage key={i}>{message}</StyledMessage>
        ))}
      </StyledContainerDiv>
    </Dialog>
  );
}
