import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { transform as olTransform } from "ol/proj";

import { getPointValue } from "../../api";
import { MAP_OBJECTS } from "../../states/controlState";

export default function useMapPointInfo(
  enabled,
  serverUrl,
  dataset,
  variable,
  dataset2,
  variable2,
  time,
){
  const lastFetchTime = useRef(0);
  const [location, setLocation] = useState();
  const [payload, setPayload] = useState();
  const [payload2, setPayload2] = useState();

  const fetchPointValue = useCallback(
    async (
      dataset,
      variable,
      lon,
      lat,
      setPayload,
    ) => {
      setPayload({ dataset, variable, result: { fetching: true } });
      try {
        const result = await getPointValue(
          serverUrl,
          dataset,
          variable,
          lon,
          lat,
          time,
          null,
        );
        console.info(variable.name, "=", result);
        setPayload({ dataset, variable, result: { value: result.value } });
      } catch (error) {
        setPayload({ dataset, variable, result: { error } });
      }
    },
    [serverUrl, time],
  );

  const handlePointerMove = useCallback(
    (event) => {
      const map = event.map;
      if (!enabled || !dataset || !variable || !map) {
        setPayload(undefined);
        setPayload2(undefined);
        return;
      }
      const pixelX = event.pixel[0];
      const pixelY = event.pixel[1];
      const geoCoordinate = olTransform(
        event.coordinate,
        map.getView().getProjection().getCode(),
        "EPSG:4326",
      );
      const lon = geoCoordinate[0];
      const lat = geoCoordinate[1];
      setLocation({ pixelX, pixelY, lon, lat });

      // Throttle
      const currentTime = new Date().getTime();
      if (currentTime - lastFetchTime.current >= 500) {
        lastFetchTime.current = currentTime;
        fetchPointValue(dataset, variable, lon, lat, setPayload).finally(() => {
          if (dataset2 && variable2) {
            void fetchPointValue(dataset2, variable2, lon, lat, setPayload2);
          }
        });
      }
    },
    [fetchPointValue, enabled, dataset, variable, dataset2, variable2],
  );

  // TODO: using MAP_OBJECTS here is really a bad idea,
  //   but it seems we have no choice.
  const map = MAP_OBJECTS["map"];

  useEffect(() => {
    if (enabled && map) {
      const listener = (event) => {
        if (!event.dragging) {
          handlePointerMove(event);
        } else {
          setLocation(undefined);
        }
      };

      map.on("pointermove", listener);
      return () => {
        map.un("pointermove", listener);
      };
    } else {
      setLocation(undefined);
    }
  }, [enabled, map, handlePointerMove]);

  return useMemo(
    () =>
      location && payload
        ? {
            location,
            payload,
            payload2,
          }
        : null,
    [location, payload, payload2],
  );
}
