import React from "react";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import { ListItemButton } from "@mui/material";


const SettingsSubPanel= ({
  label,
  value,
  onClick,
  children,
}) => {
  let listItemStyle;
  if (!value) {
    listItemStyle = { marginBottom: 10 };
  }

  const listItemText = <ListItemText primary={label} secondary={value} />;

  let listItemSecondaryAction;
  if (children) {
    listItemSecondaryAction = (
      <ListItemSecondaryAction>{children}</ListItemSecondaryAction>
    );
  }

  if (onClick) {
    return (
      <ListItemButton style={listItemStyle} onClick={onClick}>
        {listItemText}
        {listItemSecondaryAction}
      </ListItemButton>
    );
  }

  return (
    <ListItem style={listItemStyle}>
      {listItemText}
      {listItemSecondaryAction}
    </ListItem>
  );
};

export default SettingsSubPanel;
