import { parseColor, rgbToHex } from "../util/color";

export const USER_COLOR_BAR_GROUP_TITLE = "User";
export const USER_COLOR_BAR_CODE_EXAMPLE =
  "0.0: #23FF52\n" + // tie point 1
  "0.5: red\n" + // tie point 2
  "1.0: 120,30,255"; // tie point 3

export function getUserColorBarRgbaArray(
  records,
  type,
  size,
) {
  const rgbaArray = new Uint8ClampedArray(4 * size);
  const n = records.length;
  if (type === "categorical" || type === "stepwise") {
    const m = type === "categorical" ? n : n - 1;
    for (let i = 0, j = 0; i < size; i++, j += 4) {
      const recordIndex = Math.floor((m * i) / size);
      const [r, g, b, a] = records[recordIndex].color;
      rgbaArray[j] = r;
      rgbaArray[j + 1] = g;
      rgbaArray[j + 2] = b;
      rgbaArray[j + 3] = a;
    }
  } else {
    const min = records[0].value;
    const max = records[n - 1].value;
    const values = records.map((record) => (record.value - min) / (max - min));
    let recordIndex = 0;
    let v1 = values[0];
    let v2 = values[1];
    for (let i = 0, j = 0; i < size; i++, j += 4) {
      const v = i / (size - 1);
      if (v > v2) {
        recordIndex++;
        v1 = values[recordIndex];
        v2 = values[recordIndex + 1];
      }
      const w = (v - v1) / (v2 - v1);
      const [r1, g1, b1, a1] = records[recordIndex].color;
      const [r2, g2, b2, a2] = records[recordIndex + 1].color;
      rgbaArray[j] = r1 + w * (r2 - r1);
      rgbaArray[j + 1] = g1 + w * (g2 - g1);
      rgbaArray[j + 2] = b1 + w * (b2 - b1);
      rgbaArray[j + 3] = a1 + w * (a2 - a1);
    }
  }
  return rgbaArray;
}

export function renderUserColorBar(
  records,
  type,
  canvas,
){
  const data = getUserColorBarRgbaArray(records, type, canvas.width);
  const imageData = new ImageData(data, data.length / 4, 1);
  return createImageBitmap(imageData).then((bitMap) => {
    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.drawImage(bitMap, 0, 0, canvas.width, canvas.height);
    }
  });
}

export function renderUserColorBarAsBase64(
  userColorBar,
) {
  const { colorRecords, errorMessage } = getUserColorBarColorRecords(
    userColorBar.code,
  );
  if (!colorRecords) {
    return Promise.resolve({ errorMessage });
  }
  const canvas = document.createElement("canvas");
  canvas.width = 256;
  canvas.height = 1;
  return renderUserColorBar(colorRecords, userColorBar.type, canvas).then(
    () => {
      const dataURL = canvas.toDataURL("image/png");
      return { imageData: dataURL.split(",")[1] };
    },
  );
}

export function getUserColorBarHexRecords(
  code,
){
  const { colorRecords } = getUserColorBarColorRecords(code);
  if (colorRecords) {
    return colorRecords.map((r) => ({ ...r, color: rgbToHex(r.color) }));
  }
}

export function getUserColorBarColorRecords(code) {
  try {
    const colorRecords = parseUserColorBarCode(code);
    return { colorRecords };
  } catch (error) {
    if (error instanceof SyntaxError) {
      return { errorMessage: `${error.message}` };
    }
    throw error;
  }
}

/**
 * Parses the given color records in text form and returns
 * an array of color records.
 *
 * @param code The color records as text
 * @returns The parse color records
 * @throws SyntaxError
 */
export function parseUserColorBarCode(code){
  const points = [];
  code
    .split("\n")
    .map((line) =>
      line
        .trim()
        .split(":")
        .map((comp) => comp.trim()),
    )
    .forEach((recordParts, index) => {
      if (recordParts.length === 2 || recordParts.length === 3) {
        const [valueText, rgbText] = recordParts;
        const value = parseFloat(valueText);
        const color = parseColor(rgbText);
        if (!Number.isFinite(value)) {
          throw new SyntaxError(
            `Line ${index + 1}: invalid value: ${valueText}`,
          );
        }
        if (!color) {
          throw new SyntaxError(`Line ${index + 1}: invalid color: ${rgbText}`);
        }
        if (recordParts.length === 3) {
          points.push({ value, color, label: recordParts[2] });
        } else {
          points.push({ value, color });
        }
      } else if (recordParts.length === 1) {
        if (recordParts[0] !== "") {
          throw new SyntaxError(
            `Line ${index + 1}: invalid color record: ${recordParts[0]}`,
          );
        }
      }
    });
  const n = points.length;
  if (n < 2) {
    throw new SyntaxError(`At least two color records must be given`);
  }
  points.sort((r1, r2) => r1.value - r2.value);
  const v1 = points[0].value;
  const v2 = points[n - 1].value;
  if (v1 === v2) {
    throw new SyntaxError(`Values must form a range`);
  }
  return points;
}
