import Box from "@mui/material/Box";

const CONTAINER_STYLE = {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  zIndex: 1000,
};

export default function MapButtonGroup({
  style,
  sx,
  children,
}) {
  return (
    <Box
      className="ol-unselectable ol-control"
      sx={sx}
      style={style ? { ...CONTAINER_STYLE, ...style } : CONTAINER_STYLE}
    >
      {children}
    </Box>
  );
}
