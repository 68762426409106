import { connect } from "react-redux";
import _UserControl from "../components/UserControl";
import { updateAccessToken } from "../actions/userAuthActions";

// noinspection JSUnusedLocalSymbols
const mapStateToProps = (_state) => {
  return {};
};

const mapDispatchToProps = {
  updateAccessToken,
};

const UserControl = connect(mapStateToProps, mapDispatchToProps)(_UserControl);
export default UserControl;
