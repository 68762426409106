import bgImageData from "./bg.png";

const BG_IMAGE = new Image();
BG_IMAGE.src = bgImageData;


export const CB_ALPHA_SUFFIX = "_alpha";
export const CB_REVERSE_SUFFIX = "_r";


export function parseColorBarName(name) {
  let baseName = name;

  const isAlpha = baseName.endsWith(CB_ALPHA_SUFFIX);
  if (isAlpha) {
    baseName = baseName.slice(0, baseName.length - CB_ALPHA_SUFFIX.length);
  }

  const isReversed = baseName.endsWith(CB_REVERSE_SUFFIX);
  if (isReversed) {
    baseName = baseName.slice(0, baseName.length - CB_REVERSE_SUFFIX.length);
  }

  return { baseName, isAlpha, isReversed };
}

export function formatColorBarName(colorBar){
  let name = colorBar.baseName;
  if (colorBar.isReversed) {
    name += CB_REVERSE_SUFFIX;
  }
  if (colorBar.isAlpha) {
    name += CB_ALPHA_SUFFIX;
  }
  return name;
}

export function renderColorBar(
  colorBar,
  opacity,
  canvas,
) {
  loadColorBarImageData(colorBar, opacity).then((imageData) => {
    // We must resolve in case ImageBitmap is already resolved
    Promise.resolve(createImageBitmap(imageData)).then((imageBitmap) => {
      const ctx = canvas.getContext("2d");
      if (ctx !== null) {
        const pattern = ctx.createPattern(BG_IMAGE, "repeat");
        if (pattern !== null) {
          ctx.fillStyle = pattern;
        } else {
          ctx.fillStyle = "#ffffff";
        }
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(imageBitmap, 0, 0, canvas.width, canvas.height);
      }
    });
  });
}

function loadColorBarImage(colorBar, image) {
  return new Promise((resolve, reject) => {
    const im = image || new Image();
    const imageData = colorBar.imageData;
    
    if (!imageData) {
      resolve(im);
      return;
    }
    
    im.onload = () => {
      resolve(im);
    };
    
    im.onerror = (error) => {
      reject(error);
    };
    
    im.src = `data:image/png;base64,${imageData}`;
  });
}

export function loadColorBarImageData(
  colorBar,
  opacity,
){
  return loadColorBarImage(colorBar).then((image) => {
    const imageData = getColorBarImageData(colorBar, opacity, image);
    if (imageData !== null) {
      return imageData;
    } else {
      throw new Error("failed to retrieve 2d context");
    }
  });
}

function getColorBarImageData(
  colorBar,
  opacity,
  image,
) {
  const offscreenCanvas = document.createElement("canvas");
  offscreenCanvas.width = image.width || 1;
  offscreenCanvas.height = image.height || 1;
  const ctx = offscreenCanvas.getContext("2d");
  if (ctx === null) {
    return null;
  }
  ctx.drawImage(image, 0, 0);
  const imageData = ctx.getImageData(
    0,
    0,
    offscreenCanvas.width,
    offscreenCanvas.height,
  );

  let rgbaArray = imageData.data;

  if (colorBar.isReversed) {
    const reversedRgbaArray = new Uint8ClampedArray(rgbaArray.length);
    for (let i = 0; i < rgbaArray.length; i += 4) {
      const j = rgbaArray.length - i - 4;
      reversedRgbaArray[j] = rgbaArray[i];
      reversedRgbaArray[j + 1] = rgbaArray[i + 1];
      reversedRgbaArray[j + 2] = rgbaArray[i + 2];
      reversedRgbaArray[j + 3] = rgbaArray[i + 3];
    }
    rgbaArray = reversedRgbaArray;
  }

  if (colorBar.isAlpha) {
    const factor = (256 * 2) / rgbaArray.length;
    for (let i = 0; i < rgbaArray.length / 2; i += 4) {
      rgbaArray[i + 3] = factor * i;
    }
  }

  if (opacity < 1.0) {
    for (let i = 0; i < rgbaArray.length; i += 4) {
      rgbaArray[i + 3] *= opacity;
    }
  }

  return new ImageData(rgbaArray, rgbaArray.length / 4, 1);
}
