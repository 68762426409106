

import * as React from "react";
import { styled } from "@mui/system";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";

const StyledForm = styled(FormControl)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));


export default function ControlBarItem({
  label,
  control,
  actions,
}) {
  return (
    <StyledForm variant="standard">
      <Box>
        {label}
        {control}
        {actions}
      </Box>
    </StyledForm>
  );
}
