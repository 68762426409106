import { maps } from "../util/maps";

export const USER_GROUP_NAME = "User";

export function getLayerTitle(layerDef){
  return layerDef ? `${layerDef.group}: ${layerDef.title}` : "-";
}

export function findLayer(
  layerDefs,
  layerId,
){
  return layerDefs.find((layer) => layer.id === layerId) || null;
}

function getDefaultLayers(key= "datasets") {
  const layerDefs= [];
  maps.forEach((mapGroup) => {
    mapGroup[key].forEach((mapSource) => {
      layerDefs.push({
        id: `${mapGroup.name}-${mapSource.name}`,
        group: mapGroup.name,
        attribution: mapGroup.link,
        title: mapSource.name,
        url: mapSource.endpoint,
      });
    });
  });
  return layerDefs;
}

export const defaultBaseMapLayers=
  getDefaultLayers("datasets");
export const defaultOverlayLayers =
  getDefaultLayers("overlays");

export const defaultBaseMapId = defaultBaseMapLayers[0].id;
