import {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from "@mui/material/colors";
import baseUrl from "./baseurl";
import { buildPath } from "./path";

const COLOR_NAMES = {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
};

function setBrandingColor(
  brandingConfig,
  key,
) {
  const rawColor = brandingConfig[key];
  let color = null;
  if (typeof rawColor === "string") {
    color = COLOR_NAMES[rawColor] || null;
    if (
      color === null &&
      rawColor.startsWith("#") &&
      (rawColor.length === 7 || rawColor.length === 9)
    ) {
      color = { main: rawColor };
    }
  } else if (typeof rawColor === "object" && rawColor !== null) {
    if ("main" in rawColor) {
      color = rawColor ;
    }
  }
  if (color !== null) {
    brandingConfig[key] = color;
  } else {
    throw new Error(`Value of branding.${key} is invalid: ${rawColor}`);
  }
}

function setBrandingImage(
  brandingConfig,
  key,
  configPath,
) {
  const imagePath = brandingConfig[key];
  if (typeof imagePath === "string") {
    brandingConfig[key] = buildPath(baseUrl.href, configPath, imagePath);
  }
}

export function parseBranding(
  brandingConfig,
  configPath,
){
  brandingConfig = { ...brandingConfig };
  setBrandingColor(brandingConfig, "primaryColor");
  setBrandingColor(brandingConfig, "secondaryColor");
  setBrandingImage(brandingConfig, "logoImage", configPath);
  return brandingConfig ;
}
