import * as React from "react";

import {  MapContextType } from "./Map";

export default class MapComponent extends React.PureComponent {
  static contextType = MapContextType;

  constructor(props) {
    super(props);
    this.context = {};
    this.object = null;
  }

  addMapObject(map) {
    throw new Error('Method not implemented.');
  }

  updateMapObject(map, object, prevProps) {
    throw new Error('Method not implemented.');
  }

  removeMapObject(map, object) {
    throw new Error('Method not implemented.');
  }

  getMapObject(id) {
    return (this.context.mapObjects && this.context.mapObjects[id]) || null;
  }

  getOptions() {
    const options = { ...this.props };
    delete options.id;
    return options;
  }

  componentDidMount() {
    this._updateMapObject(this.addMapObject(this.context.map));
  }

  componentDidUpdate(prevProps) {
    this._updateMapObject(this.updateMapObject(this.context.map, this.object, prevProps));
  }

  componentWillUnmount() {
    const map = this.context.map;
    this.removeMapObject(map, this.object);
    if (this.props.id) {
      delete this.context.mapObjects[this.props.id];
    }
    this.object = null;
  }

  _updateMapObject(object) {
    if (object != null && this.props.id) {
      object.set("objectId", this.props.id);
      this.context.mapObjects[this.props.id] = object;
    }
    this.object = object;
  }

  render() {
    return null;
  }
}