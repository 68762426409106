import { useRef } from "react";

export default function useMouseDrag(onMouseDrag) {
  const lastPosition = useRef(null);

  const handleMouseMove = useRef((event) => {
    if (event.buttons === 1 && lastPosition.current !== null) {
      event.preventDefault();
      const { screenX, screenY } = event;
      const [lastScreenX, lastScreenY] = lastPosition.current;
      const delta= [screenX - lastScreenX, screenY - lastScreenY];
      lastPosition.current = [screenX, screenY];
      onMouseDrag(delta);
    }
  });

  // Return value
  const handleMouseDown = useRef((event) => {
    if (event.buttons === 1) {
      event.preventDefault();
      document.body.addEventListener("mousemove", handleMouseMove.current);
      document.body.addEventListener("mouseup", handleEndDrag.current);
      document.body.addEventListener("onmouseleave", handleEndDrag.current);
      lastPosition.current = [event.screenX, event.screenY];
    }
  });

  const handleEndDrag = useRef((event) => {
    if (lastPosition.current !== null) {
      event.preventDefault();
      lastPosition.current = null;
      document.body.removeEventListener("mousemove", handleMouseMove.current);
      document.body.removeEventListener("mouseup", handleEndDrag.current);
      document.body.removeEventListener("onmouseleave", handleEndDrag.current);
    }
  });

  return handleMouseDown.current;
}
