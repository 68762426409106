

import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ToggleButton from "@mui/material/ToggleButton";
import Tooltip from "@mui/material/Tooltip";
import CalculateIcon from "@mui/icons-material/Calculate";
import PushPinIcon from "@mui/icons-material/PushPin";
import TimelineIcon from "@mui/icons-material/Timeline";

import i18n from "../i18n";
import { commonStyles } from "./common-styles";
import ToolButton from "../components/ToolButton";
import ControlBarItem from "./ControlBarItem";
import { isUserVariable } from "../model/userVariable";

export default function VariableSelect({
  selectedDatasetId,
  selectedVariableName,
  selectedDataset2Id,
  selectedVariable2Name,
  variables,
  userVariablesAllowed,
  canAddTimeSeries,
  addTimeSeries,
  canAddStatistics,
  addStatistics,
  selectVariable,
  selectVariable2,
  openDialog,
}) {
  const handleVariableChange = (event) => {
    selectVariable(event.target.value || null);
  };

  const handleAddTimeSeriesButtonClick = () => {
    addTimeSeries();
  };

  const isSelectedVariable2 =
    selectedDatasetId === selectedDataset2Id &&
    selectedVariableName === selectedVariable2Name;

  const variableSelectLabel = (
    <InputLabel shrink htmlFor="variable-select">
      {i18n.get("Variable")}
    </InputLabel>
  );

  const variableSelect = (
    <Select
      variant="standard"
      value={selectedVariableName || ""}
      onChange={handleVariableChange}
      input={<Input name="variable" id="variable-select" />}
      displayEmpty
      name="variable"
      renderValue={() =>
        getVariableLabel(variables.find((v) => v.name === selectedVariableName))
      }
    >
      {(variables || []).map((variable) => (
        <MenuItem
          key={variable.name}
          value={variable.name}
          selected={variable.name === selectedVariableName}
        >
          {isUserVariable(variable) && (
            <ListItemIcon>
              <CalculateIcon fontSize="small" />
            </ListItemIcon>
          )}
          <ListItemText>{getVariableLabel(variable)}</ListItemText>
          {selectedDatasetId === selectedDataset2Id &&
            variable.name === selectedVariable2Name && (
              <PushPinIcon fontSize="small" color="secondary" />
            )}
        </MenuItem>
      ))}
    </Select>
  );
  const addTimeSeriesButton = (
    <ToolButton
      key={"timeSeries"}
      disabled={!canAddTimeSeries}
      onClick={handleAddTimeSeriesButtonClick}
      tooltipText={i18n.get("Show time-series diagram")}
      icon={<TimelineIcon />}
    />
  );
  const variable2Button = (
    <ToggleButton
      key={"variable2"}
      selected={isSelectedVariable2}
      value={"comparison"}
      size="small"
      sx={{ ...commonStyles.toggleButton, marginLeft: 0.4 }}
      onClick={() => selectVariable2(selectedDatasetId, selectedVariableName)}
    >
      <Tooltip arrow title={i18n.get("Make it 2nd variable for comparison")}>
        {<PushPinIcon fontSize="small" />}
      </Tooltip>
    </ToggleButton>
  );

  return (
    <ControlBarItem
      label={variableSelectLabel}
      control={variableSelect}
      actions={[
        variable2Button,
        addTimeSeriesButton,
      ]}
    />
  );
}

function getVariableLabel(variable) {
  if (!variable) {
    return "?";
  }
  return variable.title || variable.name;
}
