import React, { Component } from 'react';
import Quotes from './Quotes';
import queryStr from '../../Util/queryString';

import Ajax, { GetToken } from '../../Util/ajax';

class QuotesContainer extends Component {
  constructor () {
    super();

    this.API = {
      getUri: `${window.appConfig.apiUrl}/internal/quotes`,
    };

    this.state = {
      status: "ALL",
      quotes: [],
      loaded: false,
      refreshLoading: false,
      page: 0,
      rowsPerPage: 15,
      totalPages: 0,
      totalElements: 0,
    };

    this.command = queryStr('command');

    // Method binding.
    this.getQuotes = this.getQuotes.bind(this);
    this.handleReload = this.handleReload.bind(this);
    this.handleSelectStatus = this.handleSelectStatus.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  getQuotes () {
    this.setState({
      loaded: false,
    }, () => {
      let API_URL = `${this.API.getUri}?page=${this.state.page}&size=${this.state.rowsPerPage}`;
      if (this.state.status !== 'ALL') {
        API_URL = `${API_URL}&status=${this.state.status}`
      }
      GetToken().then(token => {
        Ajax.getData(API_URL, token)
          .then(data => {
            this.setState({
              quotes: data?.content ?? [],
              loaded: true,
              refreshLoading: false,
              totalPages: data?.totalPages ?? 0,
              totalElements: data?.totalElements ?? 0,
            });
          })
          .catch((error) => {
            this.setState({
              loaded: true,
            });
            console.log('error', error)
          });
      });
    });
  }

  handlePageChange(event, value) {
    this.setState({
      page: value,
    }, () => {
      this.getQuotes()
    })
  }

  handleReload () {
    this.setState({
      loaded: false,
      refreshLoading: true,
    }, () => {
      this.getQuotes();
    })
  }

  componentDidMount () {
    this.getQuotes();
  }

  handleSelectStatus(status){
    this.setState({ status }, () => {
      this.getQuotes();
    });
  }


  render () {
    const { loaded, refreshLoading, status } = this.state;

    return (
      <Quotes
        selectedStatus={status}
        onSelectStatus={this.handleSelectStatus}
        onReload={this.handleReload}
        onTabChange={this.handleTabChange}
        refreshLoading={refreshLoading}
        quotes={this.state.quotes}
        loaded={loaded}
        page={this.state.page}
        rowsPerPage={this.state.rowsPerPage}
        totalElements={this.state.totalElements}
        totalPages={this.state.totalPages}
        onChangePage={this.handlePageChange}
      />
    );
  }
}

export default QuotesContainer;
