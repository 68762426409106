
////////////////////////////////////////////////////////////////////////////////

export const POST_MESSAGE = "POST_MESSAGE";

export function postMessage(
  messageType,
  messageText,
){
  return {
    type: POST_MESSAGE,
    messageType,
    messageText:
      typeof messageText === "string" ? messageText : messageText.message,
  };
}

////////////////////////////////////////////////////////////////////////////////

export const HIDE_MESSAGE = "HIDE_MESSAGE";

export function hideMessage(messageId){
  return { type: HIDE_MESSAGE, messageId };
}

////////////////////////////////////////////////////////////////////////////////
