export const formatIndianNumber = (num) => {
  const units = ["", "L", "Cr", "Ar", "Kh", "Kharab"];
  const thresholds = [1, 1e5, 1e7, 1e9, 1e11, 1e13];

  if (num < 1e5) {
    return num.toFixed(1).toLocaleString("en-IN");
  }

  let unitIndex = 0;
  for (let i = thresholds.length - 1; i >= 0; i--) {
    if (num >= thresholds[i]) {
      unitIndex = i;
      break;
    }
  }

  const formattedNum = (num / thresholds[unitIndex]).toFixed(2);

  return formattedNum + " " + units[unitIndex];
};
