import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Skeleton from "@mui/material/Skeleton";
import Container from "@mui/material/Container";
import Pill from "../Pills/Pill";
import clsx from "clsx";
import String from "../../Util/string";
import { Typography } from "@mui/material";

const QuoteBar = (props) => {
  const status = props.variant ? props.variant.toLowerCase() : "";

  const useStyles = makeStyles((theme) => ({
    root: {
      minHeight: theme.spacing(9.25),
      display: "flex",
      alignItems: "center",
      backgroundColor: theme.palette.common.white,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    container: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    status: {
      display: "flex",
      alignItems: "flex-start",
    },
    title: {
      marginRight: theme.spacing(2),
      fontSize: 18,
      fontWeight: 700,
    },
    row: {
      flexGrow: 1,
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "space-between",
      margin: theme.spacing(-2),
      "& > *": {
        padding: theme.spacing(2),
      },
    },
    green: {
      backgroundColor: theme.palette.indicatorGreenLight,
    },
    simulated: {
      backgroundColor: theme.palette.runningLight,
    },
    running: {
      backgroundColor: theme.palette.simulatedLight,
    },
    fail: {
      backgroundColor: theme.palette.indicatorRedLight,
    },
  }));

  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.simulated]: status === "simulated",
        [classes.green]: status === "active",
        [classes.running]: status === "created",
        [classes.fail]: status === "fail" || status === "error",
      })}
    >
      <Container className={classes.container} maxWidth={false}>
        <div className={classes.row}>
          <div className={classes.status}>
            {props.loaded ? (
              <>
                <Typography variant="h6" className={classes.title}>
                  Status
                </Typography>
                {status ? <Pill variant={status}>{String.capitalize(status)}</Pill> : null}
              </>
            ) : (
              <Skeleton variant="rectangular" height={18} width={250} />
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

QuoteBar.defaultProps = {
  variant: "",
};

export default QuoteBar;
