
const ident = (x) => x;
const pow10 = (x) => Math.pow(10, x);
const log10 = Math.log10;

const applyFn = (x, fn) =>
  typeof x === "number" ? fn(x) : x.map(fn);

// noinspection JSUnusedGlobalSymbols
/**
 * A class representing a scaling operation.
 * @class
 */
export default class Scaling {
  _fn;
  _invFn;

  constructor(isLog) {
    if (isLog) {
      this._fn = log10;
      this._invFn = pow10;
    } else {
      this._fn = ident;
      this._invFn = ident;
    }
  }

  scale(x) {
    return applyFn(x, this._fn);
  }

  scaleInv(x) {
    return applyFn(x, this._invFn);
  }
}
