export const getCentroid = function (points) {
  if (points.length <= 1) {
    return points[0] || [];
  }

  const x = points.map((xy) => xy[0]);
  const y = points.map((xy) => xy[1]);
  const cx = (Math.min(...x) + Math.max(...x)) / 2;
  const cy = (Math.min(...y) + Math.max(...y)) / 2;
  return [cx, cy];
};
