import i18n from "../i18n";
import { HTTPError } from "./errors";


export function makeRequestInit(accessToken){
  if (accessToken) {
    return {
      headers: [["Authorization", `Bearer ${accessToken}`]],
    };
  }
  return {};
}

export function makeRequestUrl(url, query) {
  if (query.length > 0) {
    const queryString = query
      .map((kv) => kv.map(encodeURIComponent).join("="))
      .join("&");
    if (!url.includes("?")) {
      return url + "?" + queryString;
    } else if (!url.endsWith("&")) {
      return url + "&" + queryString;
    } else {
      return url + queryString;
    }
  }
  return url;
}

export async function callApi(
  url,
  init,
){


  let response;
  try {
    response = await fetch(url, init);
    if (response.ok) {
      return response;
    }
  } catch (error) {
    if (error instanceof TypeError) {
      console.error(
        `Server did not respond for ${url}. ` +
          "May be caused by timeout, refused connection, network error, etc.",
        error,
      );
      throw new Error(i18n.get("Cannot reach server"));
    } else {
      console.error(error);
      throw error;
    }
  }

  let message = response.statusText;
  try {
    const jsonResponse = await response.json();
    if (jsonResponse && jsonResponse.error) {
      const responseError = jsonResponse.error;
      console.error(responseError);
      if (responseError.message) {
        message += `: ${responseError.message}`;
      }
    }
  } catch (_e) {
    // ok, no JSON response
  }

  console.error(response);
  throw new HTTPError(response.status, message);
}

export async function callJsonApi(url, initOrTransform, transform) {
  let init = undefined;
  if (typeof initOrTransform === 'function') {
    transform = initOrTransform;
  } else {
    init = initOrTransform;
  }
  const response = await callApi(url, init);
  const jsonResponse = await response.json();
  return transform ? transform(jsonResponse) : jsonResponse;
}

