
import * as React from "react";
import Button from "@mui/material/Button";

export const FileUpload= ({
  title,
  accept,
  multiple,
  disabled,
  onSelect,
  className,
}) => {
  const fileInput = React.useRef(null);

  const handleFileChange = (e) => {
    if (e.target.files !== null && e.target.files.length) {
      const files= [];
      for (let i = 0; i < e.target.files.length; i++) {
        files.push(e.target.files[i]);
      }
      onSelect(files);
    }
  };

  const handleButtonClick = () => {
    if (fileInput.current !== null) {
      fileInput.current.click();
    }
  };

  return (
    <>
      <input
        type="file"
        accept={accept}
        multiple={multiple}
        ref={fileInput}
        hidden
        onChange={handleFileChange}
        disabled={disabled}
      />
      <Button
        onClick={handleButtonClick}
        disabled={disabled}
        className={className}
        variant="outlined"
        size="small"
      >
        {title}
      </Button>
    </>
  );
};
