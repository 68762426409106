import { callJsonApi, makeRequestInit, makeRequestUrl } from "./callApi";

export function updateResources(
  apiServerUrl,
  accessToken,
){
  const url = makeRequestUrl(`${apiServerUrl}/maintenance/update`, []);
  const init = makeRequestInit(accessToken);
  try {
    return callJsonApi(url, init)
      .then(() => {
        return true;
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  } catch (error) {
    console.error(error);
    return Promise.resolve(false);
  }
}
