import { useEffect, useRef } from "react";

export default function useUndo() {
  const undoRef = useRef();
  const undo = useRef(() => {
    if (undoRef.current) {
      undoRef.current();
      undoRef.current = undefined;
    }
  });
  const setUndo = useRef((undo) => {
    undoRef.current = undo;
  });
  useEffect(() => undo.current, []);
  return [undo.current, setUndo.current];
}
