import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { Box } from '@mui/material';

import Toolbar from '@mui/material/Toolbar';
import ToolboxQuotes from '../Toolbox/ToolboxQuotes';
import QuotesSearch from './QuotesSearch';
import QuotesTable from './QuotesTable';

const Quotes = props => {
  const {
    selectedStatus,
    onSelectStatus,
    quotes,
    loaded,
    onReload,
    refreshLoading,
    page,
    rowsPerPage,
    totalPages,
    totalElements,
    onChangePage,
  } = props;

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      paddingBottom: theme.spacing(6)
    },
  }));

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Toolbar />
      <ToolboxQuotes />
      <QuotesSearch onReload={onReload} refreshLoading={refreshLoading} />
      <QuotesTable
        {...{
          quotes,
          loaded,
          page,
          rowsPerPage,
          totalPages,
          totalElements,
          onChangePage,
          selectedStatus,
          onSelectStatus,
        }}
      />
    </Box>
  );
}

export default Quotes;
