import { connect } from "react-redux";

import _CheckBoxAllSellectPlaces from "../components/CheckBoxAllSellectPlaces";
import { setIsSelectAll } from "../actions/controlActions";

const mapStateToProps = (state) => {
  return {
    isSelectAll: state.controlState.isSelectAll,
  };
};

const mapDispatchToProps = {
  setIsSelectAll,
};

const CheckBoxAllSellectPlaces = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_CheckBoxAllSellectPlaces);
export default CheckBoxAllSellectPlaces;
