
import { controlReducer } from "./controlReducer";
import { dataReducer } from "./dataReducer";
import { messageLogReducer } from "./messageLogReducer";
import { userAuthReducer } from "./userAuthReducer";

export function appReducer(
  state,
  action,
){
  return {
    dataState: dataReducer(state && state.dataState, action),
    controlState: controlReducer(state && state.controlState, action, state),
    messageLogState: messageLogReducer(state && state.messageLogState, action),
    userAuthState: userAuthReducer(state && state.userAuthState, action),
  };
}
