
import * as React from "react";
import FormControl from "@mui/material/FormControl";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import CategoryIcon from "@mui/icons-material/Category";
import { styled } from "@mui/system";

import i18n from "../i18n";
import { commonStyles } from "./common-styles";

const StyledFromControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(2),
}));

export default function MapInteractionsBar({
  mapInteraction,
  setMapInteraction,
}) {
  function handleChange(
    _event,
    value,
  ) {
    if (value !== null) {
      setMapInteraction(value);
    } else {
      setMapInteraction("Select");
    }
  }

  return (
    <StyledFromControl variant="standard">
      <ToggleButtonGroup
        size="small"
        value={mapInteraction}
        exclusive
        onChange={handleChange}
      >
        <ToggleButton
          key={0}
          value="Select"
          size="small"
          sx={commonStyles.toggleButton}
        >
          <Tooltip arrow title={i18n.get("Select a place in map")}>
            <AdsClickIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton
          key={1}
          value="Point"
          size="small"
          sx={commonStyles.toggleButton}
        >
          <Tooltip arrow title={i18n.get("Add a point location in map")}>
            <AddLocationIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton
          key={2}
          value="Polygon"
          size="small"
          sx={commonStyles.toggleButton}
        >
          <Tooltip arrow title={i18n.get("Draw a polygon area in map")}>
            <CategoryIcon />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    </StyledFromControl>
  );
}
