import * as React from "react";
import IconButton from "@mui/material/IconButton";
import ToggleButton from "@mui/material/ToggleButton";
import Tooltip from "@mui/material/Tooltip";

const ToolButton= ({
  sx,
  className,
  disabled,
  onClick,
  icon,
  tooltipText,
  toggle,
  value,
  selected,
}) => {
  const handleClick = (event) => {
    if (toggle) {
      onClick(event, value);
    } else {
      onClick(event);
    }
  };

  const iconComp = tooltipText ? (
    <Tooltip arrow title={tooltipText}>
      {icon}
    </Tooltip>
  ) : (
    icon
  );

  if (toggle) {
    return (
      <ToggleButton
        sx={{ padding: 0.3, ...sx }}
        className={className}
        disabled={disabled}
        size="small"
        onClick={handleClick}
        value={value || ""}
        selected={selected}
      >
        {iconComp}
      </ToggleButton>
    );
  } else {
    return (
      <IconButton
        sx={sx}
        className={className}
        disabled={disabled}
        size="small"
        onClick={handleClick}
      >
        {iconComp}
      </IconButton>
    );
  }
};

export default ToolButton;
