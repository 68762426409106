import { useErrorBoundary } from "react-error-boundary";

export default function ErrorPage({ error }) {
  const { resetBoundary } = useErrorBoundary();

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
    }}>
      <div role="alert" style={{ textAlign: 'center', maxWidth: '400px' }}>
        <p>Something went wrong:</p>
        <pre style={{ color: 'red', whiteSpace: 'pre-wrap' }}>{error.message}</pre>
        <button onClick={resetBoundary}>Try again</button>
      </div>
    </div>
  );
}
