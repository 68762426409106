

import Box from "@mui/material/Box";

import { makeStyles } from "../../util/styles";
import TimeRangeSlider from "./TimeRangeSlider";
import TimeSeriesChart from "./TimeSeriesChart";
import NoTimeSeriesChart from "../../components/TimeSeriesPanel/NoTimeSeriesChart";

// noinspection JSUnusedLocalSymbols
const styles = makeStyles({
  chartContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    flexFlow: "flex-start",
    alignItems: "center",
  },
});


export default function TimeSeriesPanel(props) {
  const {
    timeSeriesGroups,
    dataTimeRange,
    selectedTimeRange,
    selectTimeRange,
    canAddTimeSeries,
    addTimeSeries,
    ...chartProps
  } = props;

  if (timeSeriesGroups.length === 0) {
    return (
      <NoTimeSeriesChart
        canAddTimeSeries={canAddTimeSeries}
        addTimeSeries={addTimeSeries}
      />
    );
  }

  return (
    <Box sx={styles.chartContainer}>
      <TimeRangeSlider
        dataTimeRange={dataTimeRange}
        selectedTimeRange={selectedTimeRange}
        selectTimeRange={selectTimeRange}
      />
      {timeSeriesGroups.map((timeSeriesGroup) => {
        return (
          <TimeSeriesChart
            key={timeSeriesGroup.id}
            timeSeriesGroup={timeSeriesGroup}
            dataTimeRange={dataTimeRange}
            selectedTimeRange={selectedTimeRange}
            selectTimeRange={selectTimeRange}
            {...chartProps}
          />
        );
      })}
    </Box>
  );
}
