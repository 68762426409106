import { default as OlScaleLineControl } from "ol/control/ScaleLine";

import MapComponent from "../MapComponent";

export class ScaleLine extends MapComponent{
  addMapObject(map){
    const control = new OlScaleLineControl(this.getOptions());
    map.addControl(control);
    return control;
  }

  updateMapObject(
    _map,
    control,
    _prevProps,
  ){
    control.setProperties(this.getOptions());
    return control;
  }

  removeMapObject(map, control){
    map.removeControl(control);
  }
}
