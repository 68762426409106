import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Box } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';

const PartnersSearch = props => {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
      textAlign: 'right',
      '& .MuiFormControl-root': {
        margin: theme.spacing(1),
      },
      '& .MuiButtonBase-root': {
        margin: theme.spacing(1),
      }
    },
  }));

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Button
        color="primary"
        variant="outlined"
        onClick={props.handleReload}
        disabled={props.refreshLoading}
        startIcon={<SyncIcon />}
      >
        Refresh
      </Button>
    </Box>
  );
};

export default PartnersSearch;
