import { connect } from "react-redux";
import _ServerDialog from "../components/ServerDialog.js";
import { closeDialog } from "../actions/controlActions";
import { configureServers } from "../actions/dataActions";
import { selectedServerSelector } from "../selectors/controlSelectors.js";
import { userServersSelector } from "../selectors/dataSelectors";

const mapStateToProps = (state) => {
  return {
    open: !!state.controlState.dialogOpen["server"],
    servers: userServersSelector(state),
    selectedServer: selectedServerSelector(state),
  };
};

const mapDispatchToProps = {
  closeDialog,
  configureServers,
};

const ServerDialog = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_ServerDialog);
export default ServerDialog;
