import React, { useEffect, useState } from 'react';
import { Button, Box, FormControl, OutlinedInput, InputAdornment } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SyncIcon from '@mui/icons-material/Sync';
import SearchIcon from '@mui/icons-material/Search';
import { useDebounce } from '../../Util/useDebounce';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
    textAlign: 'right',
    '& .MuiFormControl-root': {
      margin: theme.spacing(1),
    },
    '& .MuiButtonBase-root': {
      margin: theme.spacing(1),
    }
  },
  icon: {
    color: theme.palette.divider,
  }
}));

const CoveragesSearch = (props) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 1000);

  useEffect(() => {
    props.onSearchChange(debouncedSearch);
  }, [debouncedSearch])

  return (
    <Box component="form" className={classes.root} noValidate autoComplete="off">
      <Button
        color="primary"
        variant="outlined"
        onClick={props.handleReload}
        disabled={props.refreshLoading}
        startIcon={<SyncIcon />}
      >
        Refresh
      </Button>
      <FormControl size="small">
        <OutlinedInput
          id="outlined-adornment-password"
          placeholder="Search for coverages"
          notched={false}
          color="primary"
          value={search}
          onChange={e => setSearch(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon className={classes.icon}/>
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  );
};

export default CoveragesSearch;
