
import { callJsonApi, makeRequestInit, makeRequestUrl } from "./callApi";

export function getDatasets(
  apiServerUrl,
  accessToken,
){
  const url = makeRequestUrl(`${apiServerUrl}/datasets`, [["details", "1"]]);
  const init = makeRequestInit(accessToken);
  return callJsonApi(url, init, adjustTimeDimensionsForDatasets);
}

function adjustTimeDimensionsForDatasets(datasets){
  return (datasets.datasets || []).map(adjustTimeDimensionsForDataset);
}

function adjustTimeDimensionsForDataset(dataset){
  if (dataset.dimensions && dataset.dimensions.length) {
    let dimensions = dataset.dimensions;
    const index = dimensions.findIndex(
      (dimension) => dimension.name === "time",
    );
    if (index > -1) {
      const timeDimension = dimensions[index];
      const timeCoordinates = timeDimension.coordinates;
      if (
        timeCoordinates &&
        timeCoordinates.length &&
        typeof timeCoordinates[0] === "string"
      ) {
        const labels = timeCoordinates;
        const coordinates = labels.map((label) =>
          new Date(label).getTime(),
        );
        dimensions = [...dimensions];
        dimensions[index] = {
          ...timeDimension,
          coordinates,
          labels,
        };
        return { ...dataset, dimensions };
      }
    }
  }
  return dataset;
}
