
import { formatColorBarName } from "../../model/colorBar";
import { USER_COLOR_BAR_GROUP_TITLE } from "../../model/userColorBar";
import ColorBarGroupComponent from "./ColorBarGroupComponent";
import UserColorBarGroup from "./UserColorBarGroup";

export default function ColorBarSelect({
  variableColorBarName,
  variableColorBarMinMax,
  variableColorBarNorm,
  variableColorBar,
  variableOpacity,
  updateVariableColorBar,
  colorBars,
  userColorBars,
  addUserColorBar,
  removeUserColorBar,
  updateUserColorBar,
  updateUserColorBars,
  storeSettings,
}) {
  const handleSelectColorBar = (baseName) => {
    variableColorBarName = formatColorBarName({
      ...variableColorBar,
      baseName,
    });
    updateVariableColorBar(
      variableColorBarName,
      variableColorBarMinMax,
      variableColorBarNorm,
      variableOpacity,
    );
  };

  return (
    <>
      {colorBars.groups.map((colorBarGroup) =>
        colorBarGroup.title === USER_COLOR_BAR_GROUP_TITLE ? (
          <UserColorBarGroup
            key={colorBarGroup.title}
            colorBarGroup={colorBarGroup}
            selectedColorBarName={variableColorBar.baseName}
            onSelectColorBar={handleSelectColorBar}
            userColorBars={userColorBars}
            addUserColorBar={addUserColorBar}
            removeUserColorBar={removeUserColorBar}
            updateUserColorBar={updateUserColorBar}
            updateUserColorBars={updateUserColorBars}
            storeSettings={storeSettings}
          />
        ) : (
          <ColorBarGroupComponent
            key={colorBarGroup.title}
            colorBarGroup={colorBarGroup}
            selectedColorBarName={variableColorBar.baseName}
            onSelectColorBar={handleSelectColorBar}
            images={colorBars.images}
          />
        ),
      )}
    </>
  );
}
