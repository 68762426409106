import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const QUERY_KEY = "GEOAPIFY_GEOCODING_QUERY";

const apiKey = window.appConfig.geoapify.api.key;

export const useGeocoding = (address, countryCode) => {
  return useQuery({
    queryKey: [QUERY_KEY, address, countryCode],
    queryFn: async () => {
      if (!address) {
        return {
          results: [],
          status: "ZERO_RESULTS",
        };
      }

      const url = new URL(`https://api.geoapify.com/v1/geocode/search`);

      url.searchParams.set("text", address);
      url.searchParams.set("apiKey", apiKey);
      if (countryCode) {
        url.searchParams.set("filter", `countrycode:${countryCode.toLowerCase()}`);
      }
      const response = await axios.get(url.href);

      return response.data;
    },
    enabled: false,
  });
};
