export function findMapLayer(map, layerId){
  const layerGroup = map.getLayers();
  for (let i = 0; i < layerGroup.getLength(); i++) {
    const layer = layerGroup.item(i);
    if (layer.get("id") === layerId) {
      return layer ;
    }
  }
  return null;
}
