import * as React from "react";
import { default as OlMap } from "ol/Map";
import { default as OlView } from "ol/View";

import { DEFAULT_MAP_CRS } from "../../model/proj";

import "ol/ol.css";
import "./Map.css";

export const MapContextType = React.createContext({
  mapObjects: {},
});


const DEFAULT_CONTAINER_STYLE= {
  position: "relative",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
};

export class Map extends React.Component {
  contextValue;
  clickEventsKey= null;

  constructor(props) {
    super(props);
    // console.log("Map.constructor: id =", this.props.id);

    const { id, mapObjects } = props;
    if (mapObjects) {
      this.contextValue = {
        map: (mapObjects[id]) || undefined,
        mapObjects: mapObjects,
      };
    } else {
      this.contextValue = {
        mapObjects: {},
      };
    }
  }

  componentDidMount(){
    // console.log('Map.componentDidMount: id =', this.props.id);

    const { id } = this.props;
    const mapDiv = this.contextValue.mapDiv;

    let map= null;
    if (this.props.isStale) {
      const mapObject = this.contextValue.mapObjects[id];
      if (mapObject instanceof OlMap) {
        map = mapObject;
        map.setTarget(mapDiv);
        if (this.clickEventsKey) {
          map.un("click", this.clickEventsKey.listener);
        }
      }
    }

    if (!map) {
      const initialZoom = this.getMinZoom(mapDiv);
      const view = new OlView({
        projection: DEFAULT_MAP_CRS,
        center: [0, 0],
        minZoom: initialZoom,
        zoom: initialZoom,
      });
      map = new OlMap({
        view,
        ...this.getMapOptions(),
        target: mapDiv,
      });
    }

    this.contextValue.map = map;
    this.contextValue.mapObjects[id] = map;

    this.clickEventsKey = map.on("click", this.handleClick);

    //map.set('objectId', this.props.id);
    map.updateSize();

    // Force update so we can pass this.map as context to all children in next render()
    this.forceUpdate();

    // Add resize listener, so we can adjust the view's minZoom.
    // See https://openlayers.org/en/latest/examples/min-zoom.html
    window.addEventListener("resize", this.handleResize);

    const onMapRef = this.props.onMapRef;
    if (onMapRef) {
      onMapRef(map);
    }
  }

  componentDidUpdate(_prevProps){
    // console.log('Map.componentDidUpdate: id =', this.props.id);

    const map = this.contextValue.map;
    const mapDiv = this.contextValue.mapDiv;
    const mapOptions = this.getMapOptions();
    map.setProperties({ ...mapOptions });
    map.setTarget(mapDiv);
    // if (this.clickEventsKey) {
    //     unByKey(this.clickEventsKey);
    // }
    // this.clickEventsKey = map.on('click', this.handleClick);
    // console.log('Map: ', this.handleClick, this.clickEventsKey);
    map.updateSize();
  }

  componentWillUnmount(){
    // console.log('Map.componentWillUnmount: id =', this.props.id);

    // Remove resize listeners
    window.removeEventListener("resize", this.handleResize);

    // if (this.clickEventsKey) {
    //     unByKey(this.clickEventsKey);
    // }

    const onMapRef = this.props.onMapRef;
    if (onMapRef) {
      onMapRef(null);
    }
  }

  render() {
    let childrenWithContext;
    if (this.contextValue.map) {
      childrenWithContext = (
        <MapContextType.Provider value={this.contextValue}>
          {this.props.children}
        </MapContextType.Provider>
      );
    }
    return (
      <div
        ref={this.handleRef}
        style={DEFAULT_CONTAINER_STYLE}
        onDragOver={this.handleDragOver}
        onDrop={this.handleDrop}
      >
        {childrenWithContext}
      </div>
    );
  }

  getMapOptions(){
    const mapOptions = { ...this.props };
    delete mapOptions["children"];
    delete mapOptions["onClick"];
    delete mapOptions["onDropFiles"];
    return mapOptions;
  }

  handleClick = (event) => {
    const onClick = this.props.onClick;
    if (onClick) {
      onClick(event );
    }
  };

  handleDrop = (event) => {
    if (this.props.onDropFiles) {
      event.preventDefault();

      const files= [];
      if (event.dataTransfer.items) {
        for (let i = 0; i < event.dataTransfer.items.length; i++) {
          const item = event.dataTransfer.items[i];
          // If dropped items aren't files, reject them
          if (item.kind === "file") {
            const file = item.getAsFile();
            if (file !== null) {
              files.push(file);
            }
          }
        }
      } else if (event.dataTransfer.files) {
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
          const file = event.dataTransfer.files[i];
          if (file !== null) {
            files.push(file);
          }
        }
      }
      if (files.length) {
        this.props.onDropFiles(files);
      }
    }
  };

  handleDragOver = (event) => {
    if (this.props.onDropFiles) {
      event.preventDefault();
    }
  };

 handleRef = (mapDiv) => {
    this.contextValue.mapDiv = mapDiv;
  };

 handleResize = () => {
    const mapDiv = this.contextValue.mapDiv;
    const map = this.contextValue.map;
    if (mapDiv && map) {
      map.updateSize();
      const view = map.getView();
      const minZoom = this.getMinZoom(mapDiv);
      if (minZoom !== view.getMinZoom()) {
        view.setMinZoom(minZoom);
      }
    }
  };

 getMinZoom = (target) => {
    // Adjust the view's minZoom so there is only one world,
    // see https://openlayers.org/en/latest/examples/min-zoom.html
    const size = target.clientWidth;
    const minZoom = Math.LOG2E * Math.log(size / 256);
    if (minZoom >= 0.0) {
      return minZoom;
    }
    return 0;
  };
}
